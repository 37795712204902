import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const deliveryOptions = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const ProductsNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  selectedProduct,
  fetchProducts,
  merchantOptions,
}) => {
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState("");

  const [tagOptions, setTagOptions] = useState("");
  const [selectedTags, setSelectedTags] = useState("");

  useEffect(() => {
    {
      fetchTags();
    }
  }, []);

  const fetchTags = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/tag/list`, body)
      .then((response) => {
        const tagsBeforeFilter = response?.data.data.rows;
        const filteredTagsToValueAndLabel = tagsBeforeFilter?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setTagOptions(filteredTagsToValueAndLabel);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMerchantChange = (selectedOption) => {
    setSelectedMerchant(selectedOption.value);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (selectedProduct && selectedProduct.name) || "",
      nameAr: (selectedProduct && selectedProduct.nameAr) || "",
      description: (selectedProduct && selectedProduct.description) || "",
      descriptionAr: (selectedProduct && selectedProduct.descriptionAr) || "",
      points: (selectedProduct && selectedProduct.points) || "",
      stockAmount: (selectedProduct && selectedProduct.stockAmount) || "",
      price: (selectedProduct && selectedProduct.price) || "",
      deliveryAvailability:
        (selectedProduct && selectedProduct.deliveryAvailability) || false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter product name"),
      nameAr: Yup.string().required("Please Enter product arabic name"),
      description: Yup.string()
        .required("description is required")
        .matches(/^.{30,300}$/, "description must be more than 30 charachters"),
      descriptionAr: Yup.string()
        .required("description is required")
        .matches(
          /^.{30,300}$/,
          "description Arabic must be more than 30 charachters"
        ),
      price: Yup.string()
        .required("Please enter product points")
        .matches(/^[0-9]+$/, "price must be a number"),
      stockAmount: Yup.string()
        .required("Please enter product stock amount")
        .matches(/^[0-9]+$/, "stock amount must be a number"),
      deliveryAvailability: Yup.bool().required(
        "Please check if available for delivery"
      ),
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        const body = {
          token: localStorage.getItem("authUser"),
          productId: selectedProduct.id,
          name: values.name,
          nameAr: values.nameAr,
          description: values.description,
          descriptionAr: values.descriptionAr,
          price: values.price,
          points: parseInt(values.price) * 5,
          stockAmount: values.stockAmount,
          price: values.price,
          tagsIds: JSON.stringify(selectedTags),
          deliveryAvailability: selectedDeliveryOption,
        };

        axios
          .post(`${API_URL}admin/product/update`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchProducts(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
        validation.resetForm();
        setShowModal(false);
      } else {
        const body = {
          token: localStorage.getItem("authUser"),
          name: values["name"],
          merchantId: selectedMerchant,
          nameAr: values["nameAr"],
          description: values["description"],
          descriptionAr: values["descriptionAr"],
          points: parseInt(values["price"]) * 5,
          stockAmount: values["stockAmount"],
          price: values["price"],
          tagsIds: JSON.stringify(selectedTags),
          deliveryAvailability: selectedDeliveryOption,
        };

        axios
          .post(`${API_URL}admin/product/create`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchProducts(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setShowModal(false);
    },
  });

  const handleDeliveryChange = (selectedOption) => {
    setSelectedDeliveryOption(selectedOption.value);
  };

  const handleTagChange = (selectedOption) => {
    const ids = selectedOption.map((item) => {
      return item.value;
    });
    setSelectedTags(ids);
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit product" : "Add product"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              {modalValue === "new" ? (
                <div className="mb-3">
                  <Label className="form-label">Merchant</Label>
                  <Select
                    options={merchantOptions}
                    onChange={handleMerchantChange}
                    placeholder="Select Merchant"
                  />
                </div>
              ) : (
                ``
              )}

              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Name Arabic</Label>
                <Input
                  name="nameAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameAr || ""}
                  invalid={
                    validation.touched.nameAr && validation.errors.nameAr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameAr && validation.errors.nameAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                  name="description"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Description Arabic</Label>
                <Input
                  name="descriptionAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.descriptionAr || ""}
                  invalid={
                    validation.touched.descriptionAr &&
                    validation.errors.descriptionAr
                      ? true
                      : false
                  }
                />
                {validation.touched.descriptionAr &&
                validation.errors.descriptionAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.descriptionAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Stock Amount</Label>
                <Input
                  name="stockAmount"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.stockAmount || ""}
                  invalid={
                    validation.touched.stockAmount &&
                    validation.errors.stockAmount
                      ? true
                      : false
                  }
                />
                {validation.touched.stockAmount &&
                validation.errors.stockAmount ? (
                  <FormFeedback type="invalid">
                    {validation.errors.stockAmount}
                  </FormFeedback>
                ) : null}
              </div>

              {/* <div className="mb-3">
                <Label className="form-label">Points</Label>
                <Input
                  name="points"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.points || ""}
                  invalid={
                    validation.touched.points && validation.errors.points
                      ? true
                      : false
                  }
                />
                {validation.touched.points && validation.errors.points ? (
                  <FormFeedback type="invalid">
                    {validation.errors.points}
                  </FormFeedback>
                ) : null}
              </div> */}

              <div className="mb-3">
                <Label className="form-label">Price</Label>
                <Input
                  name="price"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.price || ""}
                  invalid={
                    validation.touched.price && validation.errors.price
                      ? true
                      : false
                  }
                />
                {validation.touched.price && validation.errors.price ? (
                  <FormFeedback type="invalid">
                    {validation.errors.price}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Tags</Label>
                <Select
                  options={tagOptions}
                  onChange={handleTagChange}
                  placeholder="Select Tags"
                  // defaultValue={existedTags}
                  isMulti
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">
                  Check if available for Delivery
                </Label>
                <Select
                  options={deliveryOptions}
                  onChange={handleDeliveryChange}
                  placeholder="Is available for delivery?"
                  defaultValue={
                    selectedProduct.deliveryAvailability === false
                      ? { value: false, label: "No" }
                      : selectedProduct.deliveryAvailability === undefined
                      ? { value: false, label: "Is available for delivery?" }
                      : { value: true, label: "Yes" }
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

ProductsNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  selectedProduct: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchProducts: PropTypes.func,
  merchantOptions: PropTypes.any,
};

export default ProductsNewAndEditModal;
