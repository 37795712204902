import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

const StackedColumnChartForMerchantDetails = ({ ordersPerBranch }) => {
  const branchArray = ordersPerBranch.map((item) => item.branchName);
  const offerRequestsCount = ordersPerBranch.map((item) => item.countRequests);

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: branchArray,
      labels: {
        show: true,
      },
    },
    colors: ["#556ee6"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };

  const dd = [
    {
      name: "Offer Requests",
      data: offerRequestsCount,
    },
  ];
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={dd}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

StackedColumnChartForMerchantDetails.propTypes = {
  ordersPerBranch: PropTypes.any,
};

export default StackedColumnChartForMerchantDetails;
