import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import axios from "axios";
import Grid from "components/Common/Grid";

const API_URL = process.env.REACT_APP_API_URL;

const PlanCompanies = ({ planId }) => {
  const [companies, setCompanies] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    fetchCompanies();
  }, [searchKey]);

  const fetchCompanies = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      planId: planId,
    };

    axios
      .post(`${API_URL}admin/plan/company/list`, body)
      .then((response) => {
        setCompanies(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchCompanies(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchCompanies(page, newPerPage);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.Company.nameEn}</h5>
      ),
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.Company.addressEn}</h5>
      ),
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.Company.phone}</h5>
      ),
      sortable: true,
    },
    {
      name: "Company Details",
      cell: (row) => (
        <Link
          className="text-success"
          to={`/company/details/${row.Company.id}`}
        >
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="">
        <MetaTags>
          <title>Companies | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Companies" breadcrumbItem="Companies" />

          {/* <Col sm="4">
                <div className="search-box ms-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <DebounceInput
                      type="text"
                      style={{
                        borderRadius: `30px`,
                        border: `1px solid gray`,
                        width: `400px`,
                        padding: `10px`,
                      }}
                      debounceTimeout={1000}
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                      }}
                      placeholder="type true for active and false for inactive"
                    />
                  </div>
                </div>
              </Col> */}

          {companies?.length !== 0 ? (
            <>
              <Grid
                data={companies}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Companies</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
PlanCompanies.propTypes = {
  t: PropTypes.any,
  planId: PropTypes.string,
};

export default withTranslation()(PlanCompanies);
