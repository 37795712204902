import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";
import PackagesNewAndEditModal from "./PackagesNewAndEditModal";
import CardContent from "../../components/Card";
import StackedColumnChartForPackageList from "./StackedColumnChartForPackageList";
import { DebounceInput } from "react-debounce-input";

const API_URL = process.env.REACT_APP_API_URL;

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [merchantsCount, setMerchantsCount] = useState(0);
  const [revenue, setRevenue] = useState([]);
  const [topPackageRequests, setTopPackageRequests] = useState([]);
  const [packageRequestsCount, setPackageRequestsCount] = useState(0);

  useEffect(() => {
    fetchPackages();
  }, [searchKey]);

  const fetchPackages = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      name: searchKey,
    };

    axios
      .post(`${API_URL}admin/package/list`, body)
      .then((response) => {
        setPackages(response?.data.data.packageData.rows);
        setTotalRows(response?.data.data.packageData.count);
        setTopPackageRequests(response?.data.data.topPackageRequests);
        setMerchantsCount(response?.data.data.numberOfLiveMerchants);
        setRevenue(response?.data.data.totalRevenue[0].total_amount);
        setPackageRequestsCount(response?.data.data.numberOfPackageOrders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchPackages(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchPackages(page, newPerPage);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    // {
    //   name: "Name Arabic",
    //   selector: (row) => (
    //     <h5 className="font-size-12 text-dark">{row.nameAr}</h5>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Amount",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.amount}</h5>
      ),
      sortable: true,
    },
    {
      name: "Points",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.points}</h5>
      ),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.type}</h5>,
      sortable: true,
    },
    {
      name: "Valid To",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.validto)}
        </h5>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row.isAproved === true ? `Approved` : `Not Approved`}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <ul className="list-inline font-size-20 contact-links mb-0 text-center cursor-pointer">
          <Link to="#">
            <i
              className="mdi mdi-delete font-size-18 text-danger"
              id="deletetooltip"
              onClick={() => onClickPackageDelete(row)}
            ></i>
          </Link>
          <li className="list-inline-item px-2">
            <Link to="#">
              <i
                className="mdi mdi-pencil font-size-18 text-success"
                id="edittooltip"
                onClick={() => handleEditPackage(row)}
              ></i>
            </Link>
          </li>
        </ul>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Package Details",
      cell: (row) => (
        <Link className="text-success" to={`/package/details/${row.id}`}>
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditPackage = (item) => {
    setModal(true);
    setModalValue("edit");
    setSelectedPackage(item);
  };

  const onClickPackageDelete = (item) => {
    setSelectedPackage(item);
    setDeleteModal(true);
  };

  const handleDeletePackage = (item) => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: item.id,
    };
    axios
      .post(`${API_URL}admin/package/delete`, body)
      .then(() => {
        fetchPackages(1, limit);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };

  const reports = [
    {
      title: "Approved Orders",
      iconClass: "bx-archive-in",
      description: packageRequestsCount || 0,
    },
    {
      title: "Live Merchants",
      iconClass: "bx-archive-in",
      description: merchantsCount || 0,
    },
    {
      title: "Total Revenue",
      iconClass: "bx-archive-in",
      description: revenue || 0,
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePackage}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={selectedPackage}
      />

      <PackagesNewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        selectedPackage={modalValue === "new" ? {} : selectedPackage}
        fetchPackages={fetchPackages}
      />

      <div className="page-content">
        <MetaTags>
          <title>Packages | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Categories")}
            // breadcrumbItem={props.t("Categories")}
            title="Packages"
            breadcrumbItem="Packages"
          />

          <Row>
            <Col lg="6">
              <Card>
                <CardBody className="analytics-info">
                  <CardTitle className="mb-4">Top Purchased Packages</CardTitle>
                  <StackedColumnChartForPackageList
                    packageRequests={topPackageRequests}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <CardContent reports={reports} numbers={12} />
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="text-sm-end mb-2">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={addNew}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New Package
                </Button>
              </div>
            </Col>
          </Row>

          <Col sm="4">
            <div className="search-box ms-2 mb-2 d-inline-block">
              <div className="position-relative">
                <DebounceInput
                  type="text"
                  style={{
                    borderRadius: `30px`,
                    border: `1px solid gray`,
                    width: `400px`,
                    padding: `10px`,
                  }}
                  debounceTimeout={1000}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search English Name.."
                />
              </div>
            </div>
          </Col>

          {packages?.length !== 0 ? (
            <>
              <Grid
                data={packages}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Packages</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Packages.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Packages);
