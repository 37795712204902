import PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const AgentNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  selectedAgent,
  fetchAgents,
  companyId,
}) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fName: (selectedAgent && selectedAgent.fName) || "",
      mName: (selectedAgent && selectedAgent.mName) || "",
      lName: (selectedAgent && selectedAgent.lName) || "",
      mobile: (selectedAgent && selectedAgent.mobile) || "",
    },
    validationSchema: Yup.object({
      fName: Yup.string().required("Please enter agent frist name"),
      mName: Yup.string().required("Please enter agent middle name"),
      lName: Yup.string().required("Please enter agent last name"),
      mobile: Yup.string()
        .required("phone is required")
        .matches(/^[0-9]+$/, "phone must be a valid number"),
      password:
        modalValue === "new"
          ? Yup.string().required("Please Enter admin password")
          : ``,
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        // const body = {
        //   token: token,
        //   agentId: selectedAgent.id,
        //   name: values.name,
        //   mobile: values.mobile,
        // };
        // axios
        //   .post(`${API_URL}admin/agent/update`, body, {
        //     headers: { "Access-Control-Allow-Origin": "*" },
        //   })
        //   .then(() => {
        //     fetchAgents(1, 10);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
        // validation.resetForm();
        // setShowModal(false);
      } else {
        const body = {
          token: localStorage.getItem("authUser"),
          companyId,
          fName: values["fName"],
          mName: values["mName"],
          lName: values["lName"],
          mobile: values["mobile"],
          password: values["password"],
        };

        axios
          .post(`${API_URL}admin/agentAdmin/create`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchAgents(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setShowModal(false);
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit Agent" : "Add Agent"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">First Name</Label>
                <Input
                  name="fName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.fName || ""}
                  invalid={
                    validation.touched.fName && validation.errors.fName
                      ? true
                      : false
                  }
                />
                {validation.touched.fName && validation.errors.fName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.fName}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Middle Name</Label>
                <Input
                  name="mName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mName || ""}
                  invalid={
                    validation.touched.mName && validation.errors.mName
                      ? true
                      : false
                  }
                />
                {validation.touched.mName && validation.errors.mName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.mName}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Last Name</Label>
                <Input
                  name="lName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lName || ""}
                  invalid={
                    validation.touched.lName && validation.errors.lName
                      ? true
                      : false
                  }
                />
                {validation.touched.lName && validation.errors.lName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lName}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Mobile</Label>
                <Input
                  name="mobile"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mobile || ""}
                  invalid={
                    validation.touched.mobile && validation.errors.mobile
                      ? true
                      : false
                  }
                />
                {validation.touched.mobile && validation.errors.mobile ? (
                  <FormFeedback type="invalid">
                    {validation.errors.mobile}
                  </FormFeedback>
                ) : null}
              </div>

              {modalValue === "new" ? (
                <div className="mb-3">
                  <Label className="form-label">Password</Label>
                  <Input
                    name="password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
              ) : (
                ``
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

AgentNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  selectedAgent: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchAgents: PropTypes.func,
  companyId: PropTypes.string,
};

export default AgentNewAndEditModal;
