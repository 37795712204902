import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

const StackedColumnChartForPackageList = ({ packageRequests }) => {
  const packageNameArray = packageRequests.map((item) => item.Package?.name);
  const packageRequestsCount = packageRequests.map(
    (item) => item.packages_count
  );

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: packageNameArray,
      labels: {
        show: true,
      },
    },
    colors: ["#556ee6"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };

  const dd = [
    {
      name: "Number of Purchases",
      data: packageRequestsCount,
    },
  ];
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={dd}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

StackedColumnChartForPackageList.propTypes = {
  packageRequests: PropTypes.any,
};

export default StackedColumnChartForPackageList;
