import React, { useState  } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Form,
  Input,
  Button,
  CardTitle,
} from "reactstrap";

const TicketMessageForm = () => {

  const [message, setMessage] = useState("");
  const [messageButtonDisabled, setMessageButtonDisableed] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const params = useParams();
  const id = params.id;
  const body = {
  token: localStorage.getItem("authUser"),
  ticketId: id,
  messageBody: message
};

  const handleSubmit = (event) => {
    event.preventDefault();
    setMessageButtonDisableed(true);
        axios
      .post(`${API_URL}admin/userTicket/message`, body)
      .then(() => {
        setMessage('');
        setMessageButtonDisableed(false);
      })

      .catch((err) => {
        console.log(err);
      });
  
    
  }



  return (
    <React.Fragment>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Reply</CardTitle>
                  <Form className="outer-repeater" onSubmit={handleSubmit}>
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">

                        <div className="mb-3">
                          {/* <Label htmlFor="formmessage">Message :</Label> */}
                          <Input
                            type="textarea"
                            id="formmessage"
                            className="form-control"
                            rows="3"
                            placeholder="Enter your Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </div>
                        <Button 
                        type="submit" 
                        color="primary"
                        disabled= {messageButtonDisabled}
                        >
                          Send
                        </Button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
           
         
    </React.Fragment>
  );
};

export default TicketMessageForm;
