import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import { Container, Button, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import DeleteModal from "../../components/Common/DeleteModal";
import NewAndEditModal from "./NewAndEditModal/index";
import Grid from "components/Common/Grid";
import { DebounceInput } from "react-debounce-input";

const Tags = () => {
  const [tags, setTags] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [tag, setTag] = useState({});

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);

  const [searchKey, setSearchKey] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchTags();
  }, [searchKey]);

  const fetchTags = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      name: searchKey,
    };

    axios
      .post(`${API_URL}admin/tag/list`, body)
      .then((response) => {
        setTags(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchTags(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchTags(page, newPerPage);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    {
      name: "Name Arabic",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.nameAr}</h5>
      ),
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.createdAt)}
        </h5>
      ),
    },
    {
      name: "Updated At",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.updatedAt)}
        </h5>
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <Link className="text-danger" to="#">
          <i
            className="mdi mdi-delete font-size-18"
            id="deletetooltip"
            onClick={() => onClickTagDelete(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "edit",
      cell: (row) => (
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => handleEditTag(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditTag = (item) => {
    setModal(true);
    setModalValue("edit");
    setTag(item);
  };

  const onClickTagDelete = (item) => {
    setTag(item);
    setDeleteModal(true);
  };

  const handleDeleteTag = (item) => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: item.id,
    };
    axios
      .post(`${API_URL}admin/tag/delete`, body)
      .then(() => {
        fetchTags(1, 10);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTag}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={tag}
      />

      <NewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        tag={modalValue === "new" ? {} : tag}
        fetchTags={fetchTags}
      />

      <div className="page-content">
        <MetaTags>
          <title>Tags | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Tags")}
            // breadcrumbItem={props.t("Tags")}
            title="Tags"
            breadcrumbItem="Tags"
          />

          <Col>
            <div className="text-sm-end mb-2">
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={addNew}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Tag
              </Button>
            </div>
          </Col>

          <div className="search-box ms-2 mb-2 d-inline-block">
            <div className="position-relative">
              <DebounceInput
                type="text"
                style={{
                  borderRadius: `30px`,
                  border: `1px solid gray`,
                  width: `400px`,
                  padding: `10px`,
                }}
                debounceTimeout={1000}
                onChange={(e) => {
                  setSearchKey(e.target.value);
                }}
                placeholder="Search English Name.."
              />
            </div>
          </div>

          {tags?.length !== 0 ? (
            <>
              <Col sm="4"></Col>
              <Grid
                data={tags}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            ``
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Tags.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Tags);
