import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";

const TopOffers = ({ offers }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Top Selling Offers</CardTitle>
          {offers &&
            offers?.map((item, index) => (
              <div key={item.offerId}>
                {index === 0 ? (
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="bx bx-map-pin text-primary display-4" />
                    </div>
                    <h3>{item?.merchant?.name}</h3>
                    <h3>{item?.offers_count}</h3>
                    <h6 className="mb-4">{item?.offer?.title}</h6>
                  </div>
                ) : (
                  <div className="table-responsive mt-2">
                    <table className="table align-middle table-nowrap">
                      <tbody>
                        <tr>
                          <td>
                            <h5 className="mb-0">{item?.merchant?.name}</h5>
                          </td>
                          <td style={{ width: "30%" }}>
                            <p className="mb-0">{item?.offer?.title}</p>
                          </td>
                          <td style={{ width: "25%" }}>
                            <h5 className="mb-0">{item?.offers_count}</h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ))}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

TopOffers.propTypes = {
  offers: PropTypes.any,
};

export default TopOffers;
