import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardTitle,
  CardBody,
  Col,
  Row,
  Button,
  Container,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { CSVLink } from "react-csv";
import NewComersChart from "components/Common/NewComersChart";
import RevenueChart from "components/Common/RevenueChart";
import TopTagsChart from "components/Common/TopTagsChart";

const API_URL = process.env.REACT_APP_API_URL;

const Reports = () => {
  // const [packages, setPackages] = useState([]);
  // const [merchants, setMerchants] = useState([]);
  // const [pendingMerchants, setPendingMerchants] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [users, setUsers] = useState([]);
  // const [requests, setRequests] = useState([]);
  // const [packageRequests, setPackageRequests] = useState([]);
  // const [offers, setOffers] = useState([]);
  const [topTags, setTopTags] = useState([]);
  const [revenuePerDay, setRevenuePerDay] = useState([]);

  const packageHeaders = [
    { label: "Name", key: "name" },
    { label: "Arabic Name", key: "nameAr" },
    { label: "Description", key: "description" },
    { label: "Arabic Description", key: "descriptionAr" },
    { label: "Points", key: "points" },
    { label: "Type", key: "type" },
    { label: "Amount", key: "amount" },
    { label: "Valid To", key: "validto" },
  ];

  const merchantHeaders = [
    { label: "Name", key: "name" },
    { label: "Mobile", key: "mobile" },
    { label: "Balance", key: "balance" },
    { label: "User Currency", key: "userCurrncy" },
    { label: "Is Live", key: "isLive" },
    { label: "is Approved", key: "isAproved" },
    {
      label: "Tax Registration Number",
      key: `MerchantProfile.taxRegistrationNumber`,
    },
    {
      label: "Comission Percentage",
      key: `MerchantProfile.commissionPercentage`,
    },
    {
      label: "Tax Registration Number",
      key: `MerchantProfile.taxRegistrationNumber`,
    },
    {
      label: "Hotline",
      key: `MerchantProfile.hotline`,
    },
  ];

  const categoriesHeaders = [
    { label: "Name", key: "name" },
    { label: "Arabic Name", key: "nameAr" },
    { label: "Created At", key: "createdAt" },
    { label: "Updated At", key: "updatedAt" },
  ];

  const usersHeaders = [
    { label: "Name", key: "name" },
    { label: "Mobile", key: "mobile" },
    { label: "Balance", key: "balance" },
    { label: "Balance get", key: "balanceGet" },
    { label: "User Currency", key: "userCurrncy" },
    { label: "Role", key: "rule" },
    { label: "is Approved", key: "isAproved" },
  ];

  useEffect(() => {
    fetchCharts();
    // fetchPackages();
    // fetchMerchants();
    // fetchPendingMerchants();
    // fetchCategories();
    // fetchUsers();
    // fetchRequests();
    // fetchPackageRequests();
    // fetchOffers();
  }, []);

  const fetchCharts = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/reports/chart/list`, body)
      .then((response) => {
        setTopTags(response?.data.data.topTags);
        setRevenuePerDay(response?.data.data.requestsPerDay);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPackages = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/package/list`, body)
      .then((response) => {
        setPackages(response?.data.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMerchants = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/merchant/list`, body)
      .then((response) => {
        setMerchants(response?.data.data.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPendingMerchants = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/pendingmerchant/list`, body)
      .then((response) => {
        const pendingApprovalMerchants = response?.data.data.rows;
        setPendingMerchants(pendingApprovalMerchants);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCategories = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/catigory/list`, body)
      .then((response) => {
        setCategories(response?.data.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUsers = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/users/list`, body)
      .then((response) => {
        setUsers(response?.data.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchRequests = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };
    axios
      .post(`${API_URL}admin/req/list`, body)
      .then((response) => {
        const data = response?.data.data.rows;
        const newData = data.map((request) => {
          const validto = handleValidDate(request.validTo);
          const newRequest = {
            branchName: request.Branch.branchName,
            merchant: request.merchant.name,
            merchantMobile: request.merchant.mobile,
            user: request.user.name,
            userMobile: request.user.mobile,
            code: request.code,
            status: request.status,
            validTo: validto,
            offerTitle: request.offerDetail.title,
            offerTitleAr: request.offerDetail.titleAr,
            offerDescription: request.offerDetail.description,
            offerDescriptionAr: request.offerDetail.descriptionAr,
            offerPoints: request.offerDetail.points,
            offerPrice: request.offerDetail.price,
          };
          return newRequest;
        });
        setRequests(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPackageRequests = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };
    axios
      .post(`${API_URL}admin/package/req/list`, body)
      .then((response) => {
        const data = response?.data.data.rows;

        const newData = data.map((packageRequest) => {
          const validto = handleValidDate(packageRequest.Package.validto);
          const newPackageRequest = {
            merchant: packageRequest.User.name,
            merchantMobile: packageRequest.User.mobile,
            merchantBalance: packageRequest.User.balance,
            package: packageRequest.Package.name,
            packageAmount: packageRequest.Package.amount,
            packagePoints: packageRequest.Package.points,
            packageDescription: packageRequest.Package.description,
            packageDescriptionAr: packageRequest.Package.descriptionAr,
            validTo: validto,
          };
          return newPackageRequest;
        });
        setPackageRequests(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchOffers = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };
    axios
      .post(`${API_URL}admin/merchant/offer/list`, body)
      .then((response) => {
        const data = response?.data.data.rows;

        const newData = data.map((offer) => {
          const start = handleValidDate(offer.startDate);
          const end = handleValidDate(offer.endDate);

          const newOffer = {
            merchant: offer.merchant.name,
            merchantMobile: offer.merchant.mobile,
            title: offer.title,
            titleAr: offer.titleAr,
            description: offer.description,
            descriptionAr: offer.descriptionAr,
            points: offer.points,
            price: offer.price,
            merchant: offer.merchantId, //should be name, waiting for route update
            startDate: start,
            endDate: end,
          };
          return newOffer;
        });
        setOffers(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Reports | Swaplater</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          // title={props.t("Categories")}
          // breadcrumbItem={props.t("Categories")}
          title="Reports"
          breadcrumbItem="Reports"
        />

        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4"> Revenue Chart </CardTitle>
                <RevenueChart revenuePerDay={revenuePerDay} />
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Most Common Tags</CardTitle>
                <TopTagsChart tags={topTags} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* <Row>
              <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4"> New Comers</CardTitle>
                <NewComersChart />
              </CardBody>
            </Card>
          </Col> 

          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4"></CardTitle>
                <NewComersChart />
              </CardBody>
            </Card>
          </Col> 
        </Row> */}

        {/* <Row>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Packages</p>
                    <h4 className="mb-0">
                      {packages?.length !== 0 ? packages?.length : ``}
                    </h4>
                  </div>

                  <CSVLink
                    data={packages}
                    headers={packageHeaders}
                    filename={"packages"}
                  >
                    <Button className="btn btn-primary bg-primary">
                      Export Packages
                    </Button>
                  </CSVLink>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Merchants</p>
                    <h4 className="mb-0">
                      {merchants?.length !== 0 ? merchants?.length : ``}
                    </h4>
                  </div>

                  <CSVLink
                    data={merchants}
                    headers={merchantHeaders}
                    filename={"merchants"}
                  >
                    <Button className="btn btn-primary bg-primary">
                      Export Merchants
                    </Button>
                  </CSVLink>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Pending Merchants</p>
                    <h4 className="mb-0">
                      {pendingMerchants?.length !== 0
                        ? pendingMerchants?.length
                        : `0`}
                    </h4>
                  </div>

                  <CSVLink
                    data={pendingMerchants}
                    headers={merchantHeaders}
                    filename={"pending Merchants"}
                  >
                    <Button className="btn btn-primary bg-primary">
                      Export Pending Merchants
                    </Button>
                  </CSVLink>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Categories</p>
                    <h4 className="mb-0">
                      {categories?.length !== 0 ? categories?.length : ``}
                    </h4>
                  </div>

                  <CSVLink
                    data={categories}
                    headers={categoriesHeaders}
                    filename={"categories"}
                  >
                    <Button className="btn btn-primary bg-primary">
                      Export Categories
                    </Button>
                  </CSVLink>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Users</p>
                    <h4 className="mb-0">
                      {users?.length !== 0 ? users?.length : ``}
                    </h4>
                  </div>

                  <CSVLink
                    data={users}
                    headers={usersHeaders}
                    filename={"users"}
                  >
                    <Button className="btn btn-primary bg-primary">
                      Export Users
                    </Button>
                  </CSVLink>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Requests</p>
                    <h4 className="mb-0">
                      {requests?.length !== 0 ? requests?.length : ``}
                    </h4>
                  </div>

                  <CSVLink data={requests} filename={"requests"}>
                    <Button className="btn btn-primary bg-primary">
                      Export Requests
                    </Button>
                  </CSVLink>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Package Requests</p>
                    <h4 className="mb-0">
                      {packageRequests?.length !== 0
                        ? packageRequests?.length
                        : ``}
                    </h4>
                  </div>

                  <CSVLink data={packageRequests} filename={"package_requests"}>
                    <Button className="btn btn-primary bg-primary">
                      Export Package Requests
                    </Button>
                  </CSVLink>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Offers</p>
                    <h4 className="mb-0">
                      {offers?.length !== 0 ? offers?.length : ``}
                    </h4>
                  </div>

                  <CSVLink data={offers} filename={"offers"}>
                    <Button className="btn btn-primary bg-primary">
                      Export Offers
                    </Button>
                  </CSVLink>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

Reports.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Reports);
