import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

const RevenueChart = ({ revenuePerDay }) => {
  const requestCountArray = revenuePerDay.map((item) => item.count);
  const requestsDayArray = revenuePerDay.map((item) => item.createdAt);

  const series = [
    {
      name: "Revenue",
      data: requestCountArray,
    },
    // {
    //   name: "series2",
    //   data: [32, 60, 34, 46, 34, 52, 41],
    // },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#556ee6", "#34c38f"],
    xaxis: {
      type: "datetime",
      categories: requestsDayArray,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
    />
  );
};

RevenueChart.propTypes = {
  revenuePerDay: PropTypes.any,
};

export default RevenueChart;
