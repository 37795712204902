import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, } from "reactstrap";

const TransactionsDetails = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  details,
}) => {

  useEffect(() => {
  }, [details]);

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        Transactions Details
      </ModalHeader>
      <ModalBody>
        <h6>{details.cardNumber}</h6>
        <h6>{details.paymentType}</h6>
        <h6>{details.username}</h6>
      </ModalBody>
    </Modal>
  );
};

TransactionsDetails.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  details: PropTypes.any,
};

export default TransactionsDetails;
