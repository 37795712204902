import PropTypes from "prop-types";
import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import Select from "react-select";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const MerchantEditModal = ({
  show,
  setShowModal,
  onCloseClick,
  merchant,
  fetchSingleMerchant,
  categories,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (merchant && merchant.name) || "",
      mobile: (merchant && merchant.mobile) || "",
      hotLine: (merchant && merchant.MerchantProfile?.hotLine) || "",
      refundPeriod: (merchant && merchant.MerchantProfile?.refundPeriod) || "",
      refundRules: (merchant && merchant.MerchantProfile?.refundRules) || "",
      pointPrice: (merchant && merchant.MerchantProfile?.pointPrice) || "",
      taxRegistrationNumber:
        (merchant && merchant.MerchantProfile?.taxRegistrationNumber) || "",
      commissionPercentage:
        (merchant && merchant.MerchantProfile?.commissionPercentage) || "",

      refundPeriod:        
        (merchant && merchant.MerchantProfile?.refundPeriod) || "",

      refundRules:        
        (merchant && merchant.MerchantProfile?.refundRules) || "",

      categoryId: (merchant && merchant.MerchantProfile?.categoryId) || "",
    },
    validationSchema: Yup.object({
      hotLine: Yup.string().matches(
        /^[0-9]+$/,
        "mobile must be a valid number"
      ),
      refundRules: Yup.string().required("Please Enter merchant refund rules"),
      refundPeriod: Yup.string().required(
        "Please Enter merchant refund period"
      ),
      pointPrice: Yup.string()
        .required("Please Enter merchant point price")
        .matches(/^[0-9]+$/, "mobile must be a valid number"),
      taxRegistrationNumber: Yup.string().required(
        "Please Enter tax registration number"
      ),
      refundPeriod: Yup.string().required("Please Enter valid Period").matches(
        /^[100]{0}%$|^[1-9]{1,2}$/,
        "Please Enter valid Number min 1 and max 15,format Example: 4 or 14"
      ),
      refundRules: Yup.string().required("Please Enter valid refund rules.").matches(
        /^\s*([^\s]\s*){0,2500}$/,
        "Please Enter valid refund ruls min 1, and max 2500 letters."
      ),
      commissionPercentage: Yup.string()
        .required("Please Enter valid perecentage")
        .matches(
          /^[100]{0}%$|^[1-9]{1,2}[.][0-9]{0,2}$/,
          "Please Enter valid perecentage,format Example: 4.5 or 25.0"
        ),
    }),
    onSubmit: (values) => {
      const body = {
        token: token,
        merchantId: merchant.id,
        name: values["name"],
        mobile: values["mobile"],
        hotLine: values["hotLine"],
        pointPrice: values["pointPrice"],
        taxRegistrationNumber: values["taxRegistrationNumber"],
        commissionPercentage: `${values["commissionPercentage"]}%`,
        refundPeriod: values["refundPeriod"],
        refundRules: values["refundRules"],
        categoryId: selectedCategory,
        refundRules: values["refundRules"],
        refundPeriod: values["refundPeriod"],
      };

      axios
        .post(`${API_URL}admin/merchant/update/merchantProfile`, body, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then(() => {
          fetchSingleMerchant();
        })
        .catch((err) => {
          console.log(err);
        });
      validation.resetForm();
      setShowModal(false);
    },
  });

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption.value);
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">Edit Merchant</ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">mobile</Label>
                <Input
                  name="mobile"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mobile || ""}
                  invalid={
                    validation.touched.mobile && validation.errors.mobile
                      ? true
                      : false
                  }
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">hotLine</Label>
                <Input
                  name="hotLine"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.hotLine || ""}
                  invalid={
                    validation.touched.hotLine && validation.errors.hotLine
                      ? true
                      : false
                  }
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Refund Period</Label>
                <Input
                  name="refundPeriod"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.refundPeriod || ""}
                  invalid={
                    validation.touched.refundPeriod &&
                    validation.errors.refundPeriod
                      ? true
                      : false
                  }
                />
                {validation.touched.refundPeriod &&
                validation.errors.refundPeriod ? (
                  <FormFeedback type="invalid">
                    {validation.errors.refundPeriod}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Refund Rules</Label>
                <textarea
                  className="d-block w-100"
                  rows={5}
                  name="refundRules"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.refundRules || ""}
                  invalid={
                    validation.touched.refundRules &&
                    validation.errors.refundRules
                      ? true
                      : false
                  }
                />
                {validation.touched.refundRules &&
                validation.errors.refundRules ? (
                  <FormFeedback type="invalid">
                    {validation.errors.refundRules}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Point Price</Label>
                <Input
                  name="pointPrice"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.pointPrice || ""}
                  invalid={
                    validation.touched.pointPrice &&
                    validation.errors.pointPrice
                      ? true
                      : false
                  }
                />
                {validation.touched.pointPrice &&
                validation.errors.pointPrice ? (
                  <FormFeedback type="invalid">
                    {validation.errors.pointPrice}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Tax registration number</Label>
                <Input
                  name="taxRegistrationNumber"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.taxRegistrationNumber || ""}
                  invalid={
                    validation.touched.taxRegistrationNumber &&
                    validation.errors.taxRegistrationNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.taxRegistrationNumber &&
                validation.errors.taxRegistrationNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.taxRegistrationNumber}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Commission percentage</Label>
                <Input
                  name="commissionPercentage"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.commissionPercentage || ""}
                  invalid={
                    validation.touched.commissionPercentage &&
                    validation.errors.commissionPercentage
                      ? true
                      : false
                  }
                />
                {validation.touched.commissionPercentage &&
                validation.errors.commissionPercentage ? (
                  <FormFeedback type="invalid">
                    {validation.errors.commissionPercentage}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Refund Period</Label>
                <Input
                  name="refundPeriod"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.refundPeriod || ""}
                  invalid={
                    validation.touched.refundPeriod &&
                    validation.errors.refundPeriod
                      ? true
                      : false
                  }
                />
                {validation.touched.refundPeriod &&
                validation.errors.refundPeriod ? (
                  <FormFeedback type="invalid">
                    {validation.errors.refundPeriod}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Refund Rules</Label>
                <Input
                  name="refundRules"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.refundRules || ""}
                  invalid={
                    validation.touched.refundRules &&
                    validation.errors.refundRules
                      ? true
                      : false
                  }
                />
                {validation.touched.refundRules &&
                validation.errors.refundRules ? (
                  <FormFeedback type="invalid">
                    {validation.errors.refundRules}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Category</Label>
                <Select
                  options={categories}
                  onChange={handleCategoryChange}
                  placeholder="Select"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

MerchantEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  merchant: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchSingleMerchant: PropTypes.func,
  categories: PropTypes.array,
};

export default MerchantEditModal;
