import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";

const TopPackages = ({ packages }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Top Selling Packages</CardTitle>

          {packages &&
            packages?.map((item, index) => (
              <div key={item.packageId}>
                {index === 0 ? (
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="bx bx-map-pin text-primary display-4" />
                    </div>
                    <h3>{item?.packages_count}</h3>
                    <p>{item?.Package?.name}</p>
                  </div>
                ) : (
                  <div className="table-responsive mt-2">
                    <table className="table align-middle table-nowrap">
                      <tbody>
                        <tr>
                          <td style={{ width: "30%" }}>
                            <p className="mb-0">{item?.Package?.name}</p>
                          </td>
                          <td style={{ width: "25%" }}>
                            <h5 className="mb-0">{item?.packages_count}</h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ))}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

TopPackages.propTypes = {
  packages: PropTypes.any,
};

export default TopPackages;
