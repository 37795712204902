import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

const StackedColumnChart = ({ orders }) => {
  const merchantArray = orders.map((item) => item.name);
  const productRequestsCount = orders.map((item) => item.product_requests);
  const offerRequestsCount = orders.map((item) => item.offer_requests);

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: merchantArray,
      labels: {
        show: true,
      },
    },
    colors: ["#556ee6", "#f1b44c"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };

  const dd = [
    {
      name: "Product Requests",
      data: productRequestsCount,
    },
    {
      name: "Offer Requests",
      data: offerRequestsCount,
    },
  ];
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={dd}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

StackedColumnChart.propTypes = {
  orders: PropTypes.any,
};

export default StackedColumnChart;
