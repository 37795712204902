import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Table,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Button,
  Collapse,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";

import moment from "moment";
import axios from "axios";
import EmptyTable from "../../components/Common/EmptyTable";
import Grid from "components/Common/Grid";
import DataTable, { defaultThemes } from 'react-data-table-component';

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const UserDetails = () => {
  const [singleUserData, setSingleUserData] = useState({});
  const [dueList, setDueList] = useState([]);
  const [dueListTotalRows, setDueListTotalRows] = useState(0);
  // const [dueDetailsdata, setDueDetailsData] = useState({});
  // const [offers, setOffers] = useState([]);

  // const [installmentDetails, setInstallmentDetails] = useState([]);

  // const [offersTotalRows, setOffersTotalRows] = useState(0);

  const [requests, setRequests] = useState([]);
  const [requestsTotalRows, setRequestsTotalRows] = useState(0);

  // Collapse Open state
  const [isOpen, setIsOpen] = React.useState(false);

  const params = useParams();
  const id = params?.id;

  useEffect(() => {
    fetchUserDetails();
    // fetchOffers();
    fetchRequests();
    fetchUserDueList();
  }, []);


  // useEffect(() => {
  //   MyExpander;
  // }, [installmentDetails]);

  const fetchUserDetails = async () => {
    const body = {
      token: token,
      userId: id,
    };

    axios
      .post(`${API_URL}admin/user/details`, body)
      .then((response) => {
        setSingleUserData(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const fetchUserDueList = async () => {
  //   const body = {
  //     token: token,
  //     userId: id,
  //   };

  //   axios
  //     .post(`${API_URL}admin/admin/installment/due/list`, body)
  //     .then((response) => {
  //       setDueList(response?.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const fetchUserDueList = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      userId: id,
    };
    axios
      .post(`${API_URL}admin/installment/due/list`, body)
      .then((response) => {
        setDueList(response?.data.data);
        setDueListTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const fetchOffers = async (pagenumber, limit) => {
  //   const body = {
  //     token: localStorage.getItem("authUser"),
  //     userId: id,
  //     pageNum: pagenumber,
  //     limit: limit,
  //   };

  //   axios
  //     .post(`${API_URL}admin/merchant/offer/list`, body)
  //     .then((response) => {
  //       setOffers(response?.data.data.rows);
  //       setOffersTotalRows(response?.data.data.count);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const fetchRequests = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      userId: id,
    };
    axios
      .post(`${API_URL}admin/req/list`, body)
      .then((response) => {
        setRequests(response?.data.data.rows);
        setRequestsTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleBlockUser = () => {
    axios
      .post(`${API_URL}/admin/user/block`, {
        token: localStorage.getItem("authUser"),
        userId: id,
      })
      .then(() => {
        fetchUserDetails();
      });
  };
  // const handleOfferPageChange = (page) => {
  //   fetchOffers(page, limit);
  // };

  // const handleOfferPerRowsChange = async (newPerPage, page) => {
  //   setLimit(newPerPage);
  //   fetchOffers({
  //     userId: id,
  //     pagenumber: page,
  //     limit: newPerPage,
  //   });
  // };

  // const offerColumns = [
  //   {
  //     name: "Merchant Name",
  //     selector: (row) => (
  //       <h5 className="font-size-12 text-dark text-truncate">
  //         {/* should be merchant name */}
  //         {row?.merchantId}
  //       </h5>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     name: "Points",
  //     selector: (row) => (
  //       <h5 className="font-size-12 text-dark">{row?.points}</h5>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     name: "Price",
  //     selector: (row) => (
  //       <h5 className="font-size-12 text-dark">{row?.price}</h5>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     name: "Start Date",
  //     cell: (row) => (
  //       <h5 className="font-size-12 text-dark">
  //         {handleValidDate(row?.startDate)}
  //       </h5>
  //     ),
  //   },
  //   {
  //     name: "End Date",
  //     cell: (row) => (
  //       <h5 className="font-size-12 text-dark">
  //         {handleValidDate(row?.endDate)}
  //       </h5>
  //     ),
  //   },
  // ];

  const handleRequestPageChange = (page) => {
    fetchRequests(page, limit);
  };

  const handleRequestPerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchRequests(page, newPerPage);
  };

  const handleUserDueListPageChange = (page) => {
    fetchUserDueList(page, limit);
  };

  const handleUserDueListPerRowsChange = async (newPerPage, page) => {

    setLimit(newPerPage);
    fetchUserDueList(page, newPerPage);
  };

  const requestColumns = [
    {
      name: "Merchant Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark text-truncate">
          {row.merchant.name}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Branch Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.Branch.branchName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.code}</h5>,
      sortable: false,
    },
    {
      name: "Offer Points",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.offerDetail.points}</h5>
      ),
      sortable: true,
    },
    {
      name: "Offer Price",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.offerDetail.price}</h5>
      ),
      sortable: true,
    },
    {
      name: "Valid to",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.validTo)}
        </h5>
      ),
    },
    {
      name: "Status",
      cell: (row) => <h5 className="font-size-12 text-dark">{row.status}</h5>,
    },
    {
      name: "Request Details",
      cell: (row) => (
        <Link className="text-success" to={`/request/details/${row.id}`}>
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];


  const dueListColumns = [
    // >>> column  1
    {
      name: "Due Date",
      selector: (row) => (
        <h4 className="font-size-12 text-dark text-truncate" style={{
          fontWeight: '800',
        }}>
          {row.dueDate}
        </h4>
      ),
      sortable: false,


    },
    // >>> column  2
    {
      name: "Installment Total Amount",
      selector: (row) => <h4 className="font-size-12 text-dark" style={{
        fontWeight: '800',
      }}>{row.installmentAmount}</h4>,
      sortable: false,
    },
    // >>> column  3
    {
      name: "Paid Case",
      selector: (row) => (
        <h4 className="font-size-12 text-dark" style={{
          fontWeight: '800',
        }}>{row.isPayed ? "Paid" : "Unpaid"}</h4>
      ),
      sortable: false,
    },
    // >>> column  4
    // {
    //   name: "installments Details",
    //   cell: (row) => (
    //     <div>

    //       <Button
    //         style={{ width: "100%" }}
    //         color="primary"
    //       // onClick={() => {
    //       //   setIsOpen(!isOpen)
    //       // }}
    //       >
    //         Details
    //       </Button>

    //     </div>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,

    // },

  ];

  const customStylesDueList = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontWeight: '800',
        color: 'blue',
        fontSize: '15px',

      },
    },
    headRow: {
      style: {
        marginTop: "30px",
        borderTopStyle: 'solid',
        borderTopWidth: '3px',
        borderTopColor: defaultThemes.default.divider.default,
        borderBottomStyle: 'solid',
        borderBottomWidth: '3px',
        borderBottomColor: defaultThemes.default.divider.default,
      },
    },

    // cells: {
    //     style: {
    //         // paddingLeft: '8px', // override the cell padding for data cells
    //         // paddingRight: '8px',

    //     },
    // },
  };


  const MyExpander = (props) => {
    
    const [dueDetailsdata, setDueDetailsData] = useState({});    
    const body = {
      token: localStorage.getItem("authUser"),
      userId: id,
      dueDate: props.data.dueDate
    };

    useEffect(() => {
      let mounted = true;
      const getInstallmentsFromApiAsync = async () => {
        setDueDetailsData({});
        await axios
          .post(`${API_URL}admin/installment/details`, body)
          .then((response) => {
            const resopnseJson = response.data;
            console.log("json", resopnseJson);
            if (mounted) {
              setDueDetailsData(resopnseJson);
              
            }      
          })
      };
 
      getInstallmentsFromApiAsync();
      return () => {
        mounted = false;
      } // cleanup function
    }, []);

    return (

      <div>

        <Table
          className="table table-bordered"
          style={{ backgroundColor: 'rgba(254, 251, 220, 0.78)', textAlign: 'center', marginBottom: "30px", borderBottom: "3px", borderStyle: "solid" }}>
          <tbody>
            <tr>
              <th>Product Name</th>
              <th>Installment Amount</th>
              <th>Type</th>
              <th>Paid Case</th>
              <th>Due Date</th>
            </tr>
            {dueDetailsdata?.data?.map((item, index) => {
              return <tr key={index} style={{ textAlign: 'center' }}>
                <td>{item.name} </td>
                <td>{item.installmentAmount} </td>
                <td>{item.type} </td>
                <td>{item.isPayed ? "Paid" : "Un-Paid"} </td>
                <td>{item.dueDate} </td>
              </tr>
            })}
          </tbody>
        </Table>
      </div>

    )
    

  }

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };


  // >>>>  Return  <<<< 

  return (
    <div className="page-content">
      <MetaTags>
        <title>User Details | Swaplater</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          // title={props.t("Categories")}
          // breadcrumbItem={props.t("Categories")}
          title="Users"
          breadcrumbItem="User Details"
        />
        <Row>
          <Col sm="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">User Profile Information</CardTitle>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Name :</th>
                        <td>{singleUserData?.name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Mobile :</th>
                        <td>{singleUserData?.mobile}</td>
                      </tr>
                      <tr>
                        <th scope="row">Balance :</th>
                        <td>{singleUserData?.balance}</td>
                      </tr>
                      <tr>
                        <th scope="row">Balance Used:</th>
                        <td>{singleUserData?.balanceGet}</td>
                      </tr>
                      <tr>
                        <th scope="row">Currency :</th>
                        <td>{singleUserData?.userCurrncy}</td>
                      </tr>
                      <tr>
                        <th scope="row">Created At :</th>
                        <td>{handleValidDate(singleUserData?.createdAt)}</td>
                      </tr>
                      <tr>
                        <th scope="row">Updated At :</th>
                        <td>{handleValidDate(singleUserData?.updatedAt)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {singleUserData?.isBlocked ? null : (
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={handleBlockUser}
                  >
                    Block{" "}
                  </Button>
                )}
              </CardBody>
            </Card>
          </Col>

          <Col sm="6">
            <Card>
              <CardBody>
                <Col sm="6" xs="6">
                  <div className="mt-4">
                    <h5 className="font-size-14">
                      <i className="bx bx-comment-minus me-1 text-primary" />
                      Is Approved :{" "}
                      {singleUserData.isApproved == false ? "false" : "true"}
                    </h5>
                  </div>
                </Col>

                <Col sm="6" xs="6">
                  <div className="mt-4">
                    <h5 className="font-size-14">
                      <i className="bx bx-comment-minus me-1 text-primary" /> Is
                      Life : {singleUserData.isLife == false ? "false" : "true"}
                    </h5>
                  </div>
                </Col>

                <Col sm="6" xs="6">
                  <div className="mt-4">
                    <h5 className="font-size-14">
                      <i className="bx bx-comment-minus me-1 text-primary" /> Is
                      Deleted :{" "}
                      {singleUserData.isDeleted == false ? "false" : "true"}
                    </h5>
                  </div>
                </Col>
                <Col sm="6" xs="6">
                  <div className="mt-4">
                    <h5 className="font-size-14">
                      <i className="bx bx-comment-minus me-1 text-primary" /> Is
                      Blocked :{" "}
                      {singleUserData.isBlocked == false ? "false" : "true"}
                    </h5>
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* <Card>
          <CardBody>
            <h5 className="text-truncate font-size-20">
              <span style={{ color: "black", textTransform: "capitalize" }}>
                Offers used by {singleUserData.name}
              </span>
            </h5>
            {offers?.length !== 0 ? (
              <Grid
                data={offers}
                columns={offerColumns}
                totalRows={offersTotalRows}
                limit={10}
                handlePageChange={handleOfferPageChange}
                handlePerRowsChange={handleOfferPerRowsChange}
              />
            ) : (
              <Card>
                <CardBody>
                  <EmptyTable />
                </CardBody>
              </Card>
            )}
          </CardBody>
        </Card> */}

        <Card>
          <CardBody>
            <h5 className="text-truncate font-size-20">
              <span style={{ color: "black", textTransform: "capitalize" }}>
                requests by {singleUserData.name}
              </span>
            </h5>

            {requests?.length !== 0 ? (
              <Grid
                data={requests}
                columns={requestColumns}
                totalRows={requestsTotalRows}
                limit={10}
                handlePageChange={handleRequestPageChange}
                handlePerRowsChange={handleRequestPerRowsChange}

              />
            ) : (
              <Card>
                <CardBody>
                  <EmptyTable />
                </CardBody>
              </Card>
            )}
          </CardBody>
        </Card>


        {/* >>>>>>>>>>>>>>>  Due List <<<<<<<<<<<<<<<<<< */}
        {dueList?.length !== 0 ? (
          <Card>
            <CardBody>
              <h5 className="text-truncate font-size-20">
                <span style={{ color: "black", textTransform: "capitalize" }}>
                  {singleUserData.name} Installments Due Dates List
                </span>
              </h5>


              <DataTable
                data={dueList}
                columns={dueListColumns}

                totalRows={dueListTotalRows}
                limit={10}
                handlePageChange={handleUserDueListPageChange}
                handlePerRowsChange={handleUserDueListPerRowsChange}

                pagination={true}
                expandableRows={true}
                expandableRowsComponent={MyExpander}
                expandOnRowClicked={true}

                striped={true}
                customStyles={customStylesDueList}




              />
            </CardBody>
          </Card>
        ) : (
          <></>
          // <Card>
          //   <CardBody>
          //     <EmptyTable />
          //   </CardBody>
          // </Card>
        )}


      </Container>
    </div>
  );
};
UserDetails.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
};



export default withTranslation()(UserDetails);
