import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import Select from "react-select";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import MySelect from "common/MySelect";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const OffersNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  selectedOffer,
  fetchOffers,
  merchantOptions,
  existedTags,
}) => {
  const [selectedMerchant, setSelectedMerchant] = useState("");

  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState("");

  const [tagOptions, setTagOptions] = useState("");
  const [selectedTags, setSelectedTags] = useState("");

  useEffect(() => {
    if (selectedOffer || selectedMerchant) {
      fetchBranches();
      fetchTags();
    }
  }, [selectedMerchant, selectedOffer]);

  const fetchBranches = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      merchantId:
        modalValue === "edit" ? selectedOffer.merchantId : selectedMerchant,
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/merchant/branch/list`, body)
      .then((response) => {
        const branchesBeforeFilter = response?.data.data.rows;
        const filteredBranchesToValueAndLabel = branchesBeforeFilter?.map(
          (item) => ({
            value: item.id,
            label: item.branchName,
          })
        );
        setBranchOptions(filteredBranchesToValueAndLabel);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTags = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/tag/list`, body)
      .then((response) => {
        const tagsBeforeFilter = response?.data.data.rows;
        const filteredTagsToValueAndLabel = tagsBeforeFilter?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setTagOptions(filteredTagsToValueAndLabel);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (selectedOffer && selectedOffer.title) || "",
      titleAr: (selectedOffer && selectedOffer.titleAr) || "",
      description: (selectedOffer && selectedOffer.description) || "",
      descriptionAr: (selectedOffer && selectedOffer.descriptionAr) || "",
      points: (selectedOffer && selectedOffer.points) || "",
      price: (selectedOffer && selectedOffer.price) || "",
      startDate:
        (selectedOffer &&
          modalValue === "edit" &&
          moment(selectedOffer.startDate).format("YYYY-MM-DD")) ||
        "",
      endDate:
        (selectedOffer &&
          modalValue === "edit" &&
          moment(selectedOffer.endDate).format("YYYY-MM-DD")) ||
        "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Offer title"),
      titleAr: Yup.string().required("Please Enter Offer arabic title"),
      description: Yup.string()
        .required("description is required")
        .matches(/^.{30,300}$/, "description must be more than 30 charachters"),
      descriptionAr: Yup.string()
        .required("description is required")
        .matches(
          /^.{30,300}$/,
          "description Arabic must be more than 30 charachters"
        ),
      points: Yup.string().required("Points should be a valid Number"),
      availableNumberOfOffers: Yup.string().required(
        "number of offers should be a valid Number"
      ),
      price: Yup.string().required("price should be a valid Number"),
      startDate: Yup.date().required("startDate should be a valid Date"),
      endDate: Yup.date().required("startDate should be a valid Date"),
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        const body = {
          token: token,
          offerId: selectedOffer?.id,
          title: values.title,
          titleAr: values.titleAr,
          branchsIds: JSON.stringify(selectedBranches),
          tagsIds: JSON.stringify(selectedTags),
          description: values.description,
          descriptionAr: values.descriptionAr,
          points: values.points,
          availableNumberOfOffers: values.availableNumberOfOffers,
          price: values.price,
          startDate: moment(values.startDate).format("MM/DD/YYYY"),
          endDate: moment(values.endDate).format("MM/DD/YYYY"),
        };

        axios
          .post(`${API_URL}admin/merchant/offer/update`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchOffers({
              merchantId: selectedOffer.merchant.id,
              pageNumber: 1,
              limit: 10,
            });
          })
          .catch((err) => {
            console.log(err);
          });
        validation.resetForm();
        setShowModal(false);
      } else {
        const body = {
          token: token,
          merchantId: selectedMerchant,
          title: values["title"],
          titleAr: values["titleAr"],
          branchsIds: JSON.stringify(selectedBranches),
          tagsIds: JSON.stringify(selectedTags),
          description: values["description"],
          descriptionAr: values["descriptionAr"],
          points: values["points"],
          price: values["price"],
          availableNumberOfOffers: values["availableNumberOfOffers"],
          startDate: moment(values.startDate).format("MM/DD/YYYY"),
          endDate: moment(values.endDate).format("MM/DD/YYYY"),
        };
        axios
          .post(`${API_URL}admin/merchant/offer/add`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchOffers({
              merchantId: selectedMerchant,
              pageNumber: 1,
              limit: 10,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setShowModal(false);
    },
  });

  const handleMerchantChange = (selectedOption) => {
    setSelectedMerchant(selectedOption.value);
  };

  const handleBranchChange = (selectedOption) => {
    const ids = selectedOption.map((item) => {
      return item.value;
    });
    setSelectedBranches(ids);
  };

  const handleTagChange = (selectedOption) => {
    const ids = selectedOption.map((item) => {
      return item.value;
    });
    setSelectedTags(ids);
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit Offer" : "Add Offer"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              {modalValue === "new" ? (
                <div className="mb-3">
                  <Label className="form-label">Merchant</Label>
                  <Select
                    options={merchantOptions}
                    onChange={handleMerchantChange}
                    placeholder="Select Merchant"
                  />
                </div>
              ) : (
                ``
              )}

              <div className="mb-3">
                <Label className="form-label">Title</Label>
                <Input
                  name="title"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.title || ""}
                  invalid={
                    validation.touched.title && validation.errors.title
                      ? true
                      : false
                  }
                />
                {validation.touched.title && validation.errors.title ? (
                  <FormFeedback type="invalid">
                    {validation.errors.title}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Title Arabic</Label>
                <Input
                  name="titleAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.titleAr || ""}
                  invalid={
                    validation.touched.titleAr && validation.errors.titleAr
                      ? true
                      : false
                  }
                />
                {validation.touched.titleAr && validation.errors.titleAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.titleAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Price</Label>
                <Input
                  name="price"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.price || ""}
                  invalid={
                    validation.touched.price && validation.errors.price
                      ? true
                      : false
                  }
                />
                {validation.touched.price && validation.errors.price ? (
                  <FormFeedback type="invalid">
                    {validation.errors.price}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Points</Label>
                <Input
                  name="points"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.points || ""}
                  invalid={
                    validation.touched.points && validation.errors.points
                      ? true
                      : false
                  }
                />
                {validation.touched.points && validation.errors.points ? (
                  <FormFeedback type="invalid">
                    {validation.errors.points}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                  name="description"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Description Arabic</Label>
                <Input
                  name="descriptionAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.descriptionAr || ""}
                  invalid={
                    validation.touched.descriptionAr &&
                    validation.errors.descriptionAr
                      ? true
                      : false
                  }
                />
                {validation.touched.descriptionAr &&
                validation.errors.descriptionAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.descriptionAr}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Available Number Of Offers</Label>
                <Input
                  name="availableNumberOfOffers"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.availableNumberOfOffers || ""}
                  invalid={
                    validation.touched.availableNumberOfOffers &&
                    validation.errors.availableNumberOfOffers
                      ? true
                      : false
                  }
                />
                {validation.touched.availableNumberOfOffers &&
                validation.errors.availableNumberOfOffers ? (
                  <FormFeedback type="invalid">
                    {validation.errors.availableNumberOfOffers}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Branches</Label>
                <MySelect
                  options={branchOptions}
                  onChange={handleBranchChange}
                  placeholder="Select Branches"
                  allowSelectAll={true}
                  isMulti
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Tags</Label>
                <Select
                  options={tagOptions}
                  onChange={handleTagChange}
                  placeholder="Select Tags"
                  defaultValue={existedTags}
                  isMulti
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Start Date</Label>
                <Input
                  name="startDate"
                  type="date"
                  format="MM/DD/YYYY"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.startDate && validation.errors.startDate
                      ? true
                      : false
                  }
                  value={validation.values.startDate || ""}
                ></Input>
                {validation.touched.startDate && validation.errors.startDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.startDate}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">End Date</Label>
                <Input
                  name="endDate"
                  type="date"
                  format="MM/DD/YYYY"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.endDate && validation.errors.endDate
                      ? true
                      : false
                  }
                  value={validation.values.endDate || ""}
                ></Input>
                {validation.touched.endDate && validation.errors.endDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.endDate}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

OffersNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  selectedOffer: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchOffers: PropTypes.func,
  merchantOptions: PropTypes.any,
  existedTags: PropTypes.any,
};

export default OffersNewAndEditModal;
