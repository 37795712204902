import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Container, Col, Button, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import moment from "moment";
import axios from "axios";
import { DebounceInput } from "react-debounce-input";
import Grid from "components/Common/Grid";
import RequestEditModal from "./RequestEditModal";

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [request, setRequest] = useState({});
  const [limit, setLimit] = useState(10);
  const [searchCode, setSearchCode] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [modal, setModal] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchRequests();
  }, [searchCode, searchStatus]);

  const fetchRequests = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      code: searchCode,
      status: searchStatus,
    };
    axios
      .post(`${API_URL}admin/req/list`, body)
      .then((response) => {
        setRequests(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchRequests(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchRequests(page, newPerPage);
  };

  const columns = [
    {
      name: "Merchant Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark text-truncate">
          {row.merchant.name}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Branch Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.Branch.branchName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.code}</h5>,
      sortable: false,
    },
    {
      name: "Offer Points",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.offerDetail.points}</h5>
      ),
      sortable: true,
    },
    {
      name: "Offer Price",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.offerDetail.price}</h5>
      ),
      sortable: true,
    },
    {
      name: "Valid to",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.validTo)}
        </h5>
      ),
    },
    {
      name: "Status",
      cell: (row) => <h5 className="font-size-12 text-dark">{row.status}</h5>,
    },
    {
      name: "edit",
      cell: (row) => (
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => handleEditRequest(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Request Details",
      cell: (row) => (
        <Link className="text-success" to={`/request/details/${row.id}`}>
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleEditRequest = (item) => {
    setModal(true);
    setRequest(item);
  };

  return (
    <React.Fragment>
      <RequestEditModal
        show={modal}
        setShowModal={setModal}
        onCloseClick={() => setModal(false)}
        request={request}
        fetchRequests={fetchRequests}
      />
      <div className="page-content">
        <MetaTags>
          <title>Requests | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Requests")}
            // breadcrumbItem={props.t("Requests")}
            title="Requests"
            breadcrumbItem="Requests list"
          />

          <Row>
            <Col sm="4">
              <div className="search-box ms-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <DebounceInput
                    type="text"
                    style={{
                      borderRadius: `30px`,
                      border: `1px solid gray`,
                      width: `400px`,
                      padding: `10px`,
                    }}
                    debounceTimeout={1000}
                    onChange={(e) => {
                      setSearchCode(e.target.value);
                    }}
                    placeholder="Search by Code.."
                  />
                </div>
              </div>
            </Col>
            {/* <Col sm="4">
              <div className="search-box ms-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <DebounceInput
                    type="text"
                    style={{
                      borderRadius: `30px`,
                      border: `1px solid gray`,
                      width: `400px`,
                      padding: `10px`,
                    }}
                    debounceTimeout={1000}
                    onChange={(e) => {
                      setSearchStatus(e.target.value);
                    }}
                    placeholder="Search by Status.."
                  />
                </div>
              </div>
            </Col> */}
          </Row>

          {requests?.length !== 0 ? (
            <>
              <Grid
                data={requests}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            ``
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

Requests.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Requests);
