import PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const NewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  category,
  fetchCategories,
}) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (category && category.name) || "",
      nameAr: (category && category.nameAr) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      nameAr: Yup.string().required("Please Enter Your nameAr"),
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        const body = {
          token: localStorage.getItem("authUser"),
          categoryId: category.id,
          name: values.name.toLowerCase(),
          nameAr: values.nameAr,
        };

        axios
          .post(`${API_URL}admin/catigory/update`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchCategories(1, 10);
          });
        validation.resetForm();
        setShowModal(false);
      } else {
        axios
          .post(
            `${API_URL}admin/catigory/create`,
            {
              token: localStorage.getItem("authUser"),
              name: values["name"].toLowerCase(),
              nameAr: values["nameAr"],
            },
            { headers: { "Access-Control-Allow-Origin": "*" } }
          )
          .then(() => {
            fetchCategories(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setShowModal(false);
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit Category" : "Add Category"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Name Arabic</Label>
                <Input
                  name="nameAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameAr || ""}
                  invalid={
                    validation.touched.nameAr && validation.errors.nameAr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameAr && validation.errors.nameAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameAr}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

NewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  category: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchCategories: PropTypes.func,
};

export default NewAndEditModal;
