import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, CardTitle, Button, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EmptyTable from "../../components/Common/EmptyTable";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import axios from "axios";
import Grid from "components/Common/Grid";

const UserTermination = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [userTermination, setUserTermination] = useState([]);
  const [categories, setCategories] = useState([]);
  const [limit, setLimit] = useState(10);

  const [newModal, setNewModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchUsersTermination();
  }, [searchKey]);

  const fetchUsersTermination = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber || 1,
      limit: limit || 10,
      name: searchKey,
    };

    axios
      .post(`${API_URL}admin/agentClient/termination/list`, body)
      .then((response) => {
        setUserTermination(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchUsersTermination(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchUsersTermination(page, newPerPage);
  };

  const userTerminationColumns = [
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.mobile}</h5>
      ),
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.userCurrncy}</h5>
      ),
      sortable: true,
    },

    {
      name: "Details",
      cell: (row) => (
        <Link className="text-success" to={`/agent/employee/details/${row.id}`}>
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Merchants | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Merchants")}
            // breadcrumbItem={props.t("Merchants")}
            title="Terminated Users"
            breadcrumbItem="Terminated Users"
          />

          <Col sm="4">
            <div className="search-box ms-2 mb-2 d-inline-block">
              <div className="position-relative">
                <DebounceInput
                  type="text"
                  style={{
                    borderRadius: `30px`,
                    border: `1px solid gray`,
                    width: `400px`,
                    padding: `10px`,
                  }}
                  debounceTimeout={1000}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search Merchants.."
                />
              </div>
            </div>
          </Col>

          {userTermination?.length !== 0 ? (
            <>
              <Grid
                data={userTermination}
                columns={userTerminationColumns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Pending Merchants</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
UserTermination.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(UserTermination);
