import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Table,
  Col,
  Row,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import Branches from "pages/Branches";
import EmptyTable from "../../components/Common/EmptyTable";
import MerchantCreateProfile from "../PendingMerchants/MerchantCreateProfile/index";

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const PendingMerchantDetails = () => {
  const [singleMerchantData, setSingleMerchantData] = useState({});
  const [modal, setModal] = useState(false);
  const [categories, setCategories] = useState([]);

  const params = useParams();
  const id = params?.id;

  useEffect(() => {
    fetchCategories();
    fetchSingleMerchant();
  }, []);

  const fetchCategories = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/catigory/list`, body)
      .then((response) => {
        const categoriesBeforeFilter = response?.data.data.rows;
        const filteredCategoriesToValueAndLabel = categoriesBeforeFilter?.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setCategories(filteredCategoriesToValueAndLabel);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchSingleMerchant = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      merchantId: id,
    };

    axios
      .post(`${API_URL}admin/merchant/details`, body)
      .then((response) => {
        setSingleMerchantData(response?.data.data.merchantDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const addNew = () => {
    setModal(true);
  };

  return (
    <React.Fragment>
      <MerchantCreateProfile
        show={modal}
        setShowModal={setModal}
        value="pending"
        onCloseClick={() => setModal(false)}
        merchant={singleMerchantData}
        fetchSingleMerchant={fetchSingleMerchant}
        categories={categories}
      />
      <div className="page-content">
        <MetaTags>
          <title>Pending Merchant Details | Swaplater</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            // title={props.t("Categories")}
            // breadcrumbItem={props.t("Categories")}
            title="Pending Merchant"
            breadcrumbItem="Pending Merchant Details"
          />

          <Row>
            <h4 className="text-danger text-center">
              {!singleMerchantData && "There is No Data For This Merchant Yet"}
            </h4>
            <Col sm="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Personal Information</CardTitle>

                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Name :</th>
                          <td>{singleMerchantData?.name}</td>
                        </tr>
                        {/* <tr>
                          <th scope="row">Arabic Name :</th>
                          <td>{singleMerchantData.nameAr}</td>
                        </tr> */}
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{singleMerchantData?.mobile}</td>
                        </tr>
                        <tr>
                          <th scope="row">Balance :</th>
                          <td>{singleMerchantData?.balance}</td>
                        </tr>
                        <tr>
                          <th scope="row">Currency :</th>
                          <td>{singleMerchantData?.userCurrncy}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {singleMerchantData?.MerchantProfile !== null ? (
              <>
                <Col sm="6">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        Merchant Profile Information
                      </CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Tax Registration Number :</th>
                              <td>
                                {
                                  singleMerchantData?.MerchantProfile
                                    ?.taxRegistrationNumber
                                }
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Commission Percentage :</th>
                              <td>
                                {
                                  singleMerchantData?.MerchantProfile
                                    ?.commissionPercentage
                                }
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Point Price :</th>
                              <td>
                                {
                                  singleMerchantData?.MerchantProfile
                                    ?.pointPrice
                                }
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Currency :</th>
                              <td>
                                {singleMerchantData?.MerchantProfile?.currency}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Refund Period :</th>
                              <td>
                                {
                                  singleMerchantData?.MerchantProfile
                                    ?.refundPeriod
                                }
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Refund Rules :</th>
                              <td>
                                {
                                  singleMerchantData?.MerchantProfile
                                    ?.refundRules
                                }
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Created At :</th>
                              <td>
                                {handleValidDate(
                                  singleMerchantData?.MerchantProfile?.createdAt
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Updated At :</th>
                              <td>
                                {handleValidDate(
                                  singleMerchantData?.MerchantProfile?.updatedAt
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {singleMerchantData?.id ? (
                  <Branches merchant={singleMerchantData?.id} />
                ) : (
                  <Card>
                    <CardBody>
                      <EmptyTable />
                    </CardBody>
                  </Card>
                )}
              </>
            ) : (
              <Col sm="6">
                <div className="text-lg-center mt-5">
                  <Button
                    color="primary"
                    className="font-20 btn-block btn btn-primary"
                    onClick={addNew}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create Profile For {singleMerchantData?.name}
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
PendingMerchantDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(PendingMerchantDetails);
