import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { Container, Button, Col, Row, Card, CardBody } from "reactstrap";

const CardContent = (props) => {
  return (
    <React.Fragment>
      <Row>
        {/* Reports Render */}
        {props.reports.map((report, key) => (
          <Col md={props.numbers} key={"_col_" + key}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">{report.title}</p>
                    <h4 className="mb-0">{report.description}</h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-primary">
                      <i
                        className={"bx " + report.iconClass + " font-size-24"}
                      ></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

CardContent.propTypes = {
  reports: PropTypes.any,
  numbers: PropTypes.any,
};

export default CardContent;
