import PropTypes from "prop-types";
import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");
const AddEditBankAccountModal = ({
  bankAccountModal,
  setBankAccountModal,
  bankAccountModalValue,
  onCloseClick,
  bankAccount,
  fetchBankAccount,
}) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      bankName: (bankAccount && bankAccount.bankName) || "",
      accountNumber: (bankAccount && bankAccount.accountNumber) || "",
      address: (bankAccount && bankAccount.address) || "",
      addressAr: (bankAccount && bankAccount.addressAr) || "",
      IBAN: (bankAccount && bankAccount.IBAN) || "",
    },
    validationSchema: Yup.object({
      accountNumber: Yup.string(),
      bankName: Yup.string(),
      address: Yup.string(),
    }),
    onSubmit: (values) => {
      if (bankAccountModalValue === "edit") {
        const body = {
          token: token,
          merchantId: bankAccount.merchantId,
          bankName: values["bankName"],
          address: values["address"],
          addressAr: values["addressAr"],
          IBAN: values["IBAN"],
          accountNumber: values["accountNumber"],
        };

        axios
          .post(`${API_URL}admin/merchant/updateBankAccount`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchBankAccount();
          })
          .catch((err) => {
            console.log(err);
          });
        validation.resetForm();
        setBankAccountModal(false);
      } else {
        const id = bankAccount;
        const body = {
          token: token,
          merchantId: id,
          bankName: values["bankName"],
          address: values["address"],
          addressAr: values["addressAr"],
          IBAN: values["IBAN"],
          accountNumber: values["accountNumber"],
        };

        axios
          .post(`${API_URL}admin/merchant/addBankAccount`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchBankAccount();
          })
          .catch((err) => {
            console.log(err);
          });
        setBankAccountModal(false);
      }
    },
  });

  return (
    <Modal isOpen={bankAccountModal} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">Edit bank account</ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Bank Name</Label>
                <Input
                  name="bankName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.bankName || ""}
                  invalid={
                    validation.touched.bankName && validation.errors.bankName
                      ? true
                      : false
                  }
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">account Number</Label>
                <Input
                  name="accountNumber"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.accountNumber || ""}
                  invalid={
                    validation.touched.accountNumber &&
                    validation.errors.accountNumber
                      ? true
                      : false
                  }
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">address</Label>
                <Input
                  name="address"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.address || ""}
                  invalid={
                    validation.touched.address && validation.errors.address
                      ? true
                      : false
                  }
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">address arabic</Label>
                <Input
                  name="addressAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.addressAr || ""}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">IBAN</Label>
                <Input
                  name="IBAN"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.IBAN || ""}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

AddEditBankAccountModal.propTypes = {
  bankAccountModal: PropTypes.any,
  setBankAccountModal: PropTypes.func,
  bankAccountModalValue: PropTypes.any,
  onCloseClick: PropTypes.func,
  bankAccount: PropTypes.any,
  fetchBankAccount: PropTypes.func,
};

export default AddEditBankAccountModal;
