import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import moment from "moment";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const EmployeeNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  employee,
  branch,
  merchant,
  fetchEmployees,
}) => {
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branchErrorMessage, setBranchErrorMessage] = useState("");

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
      merchantId: merchant,
    };

    axios
      .post(`${API_URL}admin/merchant/branch/list`, body)
      .then((response) => {
        const branchesBeforeFilter = response?.data.data.rows;
        const filteredBranchesToValueAndLabel = branchesBeforeFilter?.map(
          (item) => ({
            value: item.id,
            label: item.branchName,
          })
        );
        setBranches(filteredBranchesToValueAndLabel);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBranchChange = (selectedOption) => {
    setSelectedBranch(selectedOption.value);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fName: (employee && employee.fName) || "",
      mName: (employee && employee.mName) || "",
      lName: (employee && employee.lName) || "",
      rule: (employee && employee.rule) || "",
      mobile: (employee && employee.mobileNum) || "",
    },
    validationSchema: Yup.object({
      fName: Yup.string().required("Please Enter first name"),
      lName: Yup.string().required("Please Enter last name"),
      rule: Yup.string().required("Please Enter employee rule"),
      mobile: Yup.string()
        .required("Please Enter phone number")
        .matches(/^[0-9]+$/, "mobile must be a valid number"),
      password:
        modalValue === "new"
          ? Yup.string().required("Please Enter admin password")
          : ``,
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        const body = {
          token: token,
          id: employee.id,
          fName: values.fName,
          mName: values.mName,
          lName: values.lName,
          rule: values.rule,
          mobile: values.mobile,
          branchId: selectedBranch,
          userId: employee.merchantUser.ownerId,
        };
        axios
          .post(`${API_URL}admin/merchant/employees/update`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchEmployees(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });

        validation.resetForm();
        setShowModal(false);
      } else {
        const body = {
          token: token,
          fName: values["fName"],
          mName: values["mName"],
          lName: values["lName"],
          rule: values["rule"],
          mobile: values["mobile"],
          password: values["password"],
          branchId: selectedBranch,
        };

        if (body.branchId === "") {
          setBranchErrorMessage("You must choose Employee's branch");
          return;
        }

        axios
          .post(`${API_URL}admin/merchant/employees/add`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchEmployees(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setShowModal(false);
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit Employee" : "Add Employee"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">First Name</Label>
                <Input
                  name="fName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.fName || ""}
                  invalid={
                    validation.touched.fName && validation.errors.fName
                      ? true
                      : false
                  }
                />
                {validation.touched.fName && validation.errors.fName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.fName}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Middle Name</Label>
                <Input
                  name="mName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mName || ""}
                  invalid={
                    validation.touched.mName && validation.errors.mName
                      ? true
                      : false
                  }
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Last Name</Label>
                <Input
                  name="lName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lName || ""}
                  invalid={
                    validation.touched.lName && validation.errors.lName
                      ? true
                      : false
                  }
                />
                {validation.touched.lName && validation.errors.lName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lName}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Role</Label>
                <Input
                  name="rule"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.rule || ""}
                  invalid={
                    validation.touched.rule && validation.errors.rule
                      ? true
                      : false
                  }
                />
                {validation.touched.rule && validation.errors.rule ? (
                  <FormFeedback type="invalid">
                    {validation.errors.rule}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Mobile Number</Label>
                <Input
                  name="mobile"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mobile || ""}
                  invalid={
                    validation.touched.mobile && validation.errors.mobile
                      ? true
                      : false
                  }
                />
                {validation.touched.mobile && validation.errors.mobile ? (
                  <FormFeedback type="invalid">
                    {validation.errors.mobile}
                  </FormFeedback>
                ) : null}
              </div>

              {modalValue === "new" ? (
                <div className="mb-3">
                  <Label className="form-label">Password</Label>
                  <Input
                    name="password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
              ) : (
                ``
              )}

              <div className="mb-3">
                <Label className="form-label">Branch</Label>
                <Select
                  options={branches}
                  onChange={handleBranchChange}
                  placeholder="Select Branch"
                />
                {selectedBranch === "" ? (
                  <p
                    style={{
                      color: "#f46a6a",
                      fontSize: "80%",
                      marginTop: "0.25rem",
                    }}
                  >
                    {branchErrorMessage}
                  </p>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

EmployeeNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  employee: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchEmployees: PropTypes.func,
  branch: PropTypes.any,
  merchant: PropTypes.any,
};

export default EmployeeNewAndEditModal;
