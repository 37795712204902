import PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";

import MapContainer from "components/MapContainer";

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const BranchNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  branch,
  merchant,
  fetchBranches,
}) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      branchName: (branch && branch.branchName) || "",
      phone: (branch && branch.phone) || "",
      address: (branch && branch.address) || "",
      addressAr: (branch && branch.addressAr) || "",
      mobile: (branch && branch.mobile) || "",
      lat: (branch && branch.lat) || "",
      long: (branch && branch.long) || "",
    },
    validationSchema: Yup.object({
      branchName: Yup.string()
        .required("Please Enter Branch name")
        .matches(
          /^[_ a-zA-Z]{3,36}$/,
          "Must be text only (min 3 and max 36 charachters), no numbers "
        ),
      phone: Yup.string()
        .required("Please Enter Branch phone")
        .matches(/^[0-9]+$/, "phone must be a valid number"),
      address: Yup.string().required("Please Enter Branch address"),
      addressAr: Yup.string().required("Please Enter Branch Arabic address"),
      lat: Yup.string().required("Please Enter Branch latitude"),
      long: Yup.string().required("Please Enter Branch longitude"),
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        const body = {
          token: token,
          branchId: branch.id,
          branchName: values.branchName,
          phone: values.phone,
          address: values.address,
          addressAr: values.addressAr,
          lat: values.lat,
          long: values.long,
        };

        axios
          .post(`${API_URL}admin/updateBranch`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchBranches(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
        validation.resetForm();
        setShowModal(false);
      } else {
        const body = {
          token: token,
          branchName: values["branchName"],
          phone: values["phone"],
          address: values["address"],
          addressAr: values["addressAr"],
          lat: values["lat"],
          long: values["long"],

          userCurrncy: "QAR",
          password: "test123456",
          merchantId: merchant,
        };
        axios
          .post(`${API_URL}admin/addBranch`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchBranches(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setShowModal(false);
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit branch" : "Add branch"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Branch Name</Label>
                <Input
                  name="branchName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.branchName || ""}
                  invalid={
                    validation.touched.branchName &&
                    validation.errors.branchName
                      ? true
                      : false
                  }
                />
                {validation.touched.branchName &&
                validation.errors.branchName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.branchName}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Phone</Label>
                <Input
                  name="phone"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">English Address</Label>
                <Input
                  name="address"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.address || ""}
                  invalid={
                    validation.touched.address && validation.errors.address
                      ? true
                      : false
                  }
                />
                {validation.touched.address && validation.errors.address ? (
                  <FormFeedback type="invalid">
                    {validation.errors.address}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Arabic Address</Label>
                <Input
                  name="addressAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.addressAr || ""}
                  invalid={
                    validation.touched.addressAr && validation.errors.addressAr
                      ? true
                      : false
                  }
                />
                {validation.touched.addressAr && validation.errors.addressAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.addressAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Maps</Label>

                <MapContainer
                  setValues={validation.setValues}
                  values={validation.values}
                />

                {validation.touched.map && validation.errors.map ? (
                  <FormFeedback type="invalid">
                    {validation.errors.map}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-Branch m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-Branch">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

BranchNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  branch: PropTypes.any,
  merchant: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchBranches: PropTypes.func,
};

//export default GoogleApiWrapper({
//apiKey:"AIzaSyDxsUzIfLpefNwPISG4wYuo_qrrKHuMUKQ"
//})(MapContainer);
export default BranchNewAndEditModal;
