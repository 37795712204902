import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";
import CompanyNewAndEditModal from "./CompanyNewAndEditModal";

import { DebounceInput } from "react-debounce-input";

const API_URL = process.env.REACT_APP_API_URL;

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    fetchCompanies();
  }, [searchKey]);

  const fetchCompanies = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
    };

    axios
      .post(`${API_URL}admin/company/list`, body)
      .then((response) => {
        setCompanies(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchCompanies(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchCompanies(page, newPerPage);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.nameEn}</h5>
      ),
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.addressEn}</h5>
      ),
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.phone}</h5>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <ul className="list-inline font-size-20 contact-links mb-0 text-center cursor-pointer">
          {/* <Link to="#">
            <i
              className="mdi mdi-delete font-size-18 text-danger"
              id="deletetooltip"
              onClick={() => onClickCompanyDelete(row)}
            ></i>
          </Link> */}
          <li className="list-inline-item px-2">
            <Link to="#">
              <i
                className="mdi mdi-pencil font-size-18 text-success"
                id="edittooltip"
                onClick={() => handleEditCompany(row)}
              ></i>
            </Link>
          </li>
        </ul>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Company Details",
      cell: (row) => (
        <Link className="text-success" to={`/company/details/${row.id}`}>
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditCompany = (item) => {
    setModal(true);
    setModalValue("edit");
    setSelectedCompany(item);
  };

  const onClickCompanyDelete = (item) => {
    setSelectedCompany(item);
    setDeleteModal(true);
  };

  const handleDeleteCompany = (item) => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: item.id,
    };
    axios
      .post(`${API_URL}admin/company/delete`, body)
      .then(() => {
        fetchCompanies(1, limit);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCompany}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={selectedCompany}
      />

      <CompanyNewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        selectedCompany={modalValue === "new" ? {} : selectedCompany}
        fetchCompanies={fetchCompanies}
      />

      <div className="page-content">
        <MetaTags>
          <title>Companies | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Categories")}
            // breadcrumbItem={props.t("Categories")}
            title="Companies"
            breadcrumbItem="Companies"
          />

          <Row>
            <Col>
              <div className="text-sm-end mb-2">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={addNew}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New company
                </Button>
              </div>
            </Col>
          </Row>

          {companies?.length !== 0 ? (
            <>
              {/* <Col sm="4">
                <div className="search-box ms-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <DebounceInput
                      type="text"
                      style={{
                        borderRadius: `30px`,
                        border: `1px solid gray`,
                        width: `400px`,
                        padding: `10px`,
                      }}
                      debounceTimeout={1000}
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                      }}
                      placeholder="type true for active and false for inactive"
                    />
                  </div>
                </div>
              </Col> */}
              <Grid
                data={companies}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Companies</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Companies.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Companies);
