import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";
import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const UserNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  user,
  fetchUsers,
}) => {
  const [phone, setPhone] = useState();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (user && user.name) || "",
      mobile: (user && user.mobile) || "",
      balance: (user && user.balance) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter user name"),
      // mobile: Yup.string().required("Please Enter user mobile"),
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        //axios update user here
        const body = {
          token: localStorage.getItem("authUser"),
          id: user.id,
          name: values["name"],
          mobile: values["mobile"] || phone,
          balance: values["balance"],
        };

        axios
          .post(`${API_URL}admin/endUser/update`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchUsers();
          })
          .catch((err) => {
            console.log(err);
          });
        validation.resetForm();
        setShowModal(false);
      } else {
        const body = {
          token: localStorage.getItem("authUser"),
          name: values["name"],
          mobile: phone,
          userCurrncy: "QAR",
        };

        axios
          .post(`${API_URL}admin/endUser/create`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchUsers();
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setShowModal(false);
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit user" : "Add user"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">User Name</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <PhoneInput
                  name="mobile"
                  type="text"
                  country="qa"
                  onChange={(e) => {
                    setPhone(e);
                  }}
                  value={validation.values.mobile || ""}
                  // inputProps={{
                  //   name: "mobile",
                  //   required: true,
                  //   autoFocus: true,
                  // }}
                  // onChange={validation.handleChange}
                  // onBlur={validation.handleBlur}
                  // invalid={
                  //   validation.touched.mobile && validation.errors.mobile
                  //     ? true
                  //     : false
                  // }
                  disabled={modalValue === "edit" ? true : false}
                />
              </div>

              {modalValue === "edit" ? (
                <div className="mb-3">
                  <Label className="form-label">Balance</Label>
                  <Input
                    name="balance"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.balance || ""}
                    invalid={
                      validation.touched.balance && validation.errors.balance
                        ? true
                        : false
                    }
                  />
                </div>
              ) : (
                ``
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

UserNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  user: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchUsers: PropTypes.func,
};

export default UserNewAndEditModal;
