import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
//import Charts
import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import axios from "axios";
// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import ActivityComp from "./ActivityComp";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";

import TopPackages from "./TopPackages";
import TopProducts from "./TopProducts";
import TopOffers from "./TopOffers";

const Dashboard = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [monthlyEarnings, setMonthlyEarnings] = useState([]);
  const [topPackages, setTopPackages] = useState([]);
  const [topOffers, setTopOffers] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [packageActivities, setPackageActivities] = useState([]);
  const [productAndOfferRequestsCount, setProductAndOfferRequestsCount] =
    useState([]);
  const [numberOfOrders, setNumberOfOrders] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [username, setUsername] = useState(0);
  const [numberOfLiveMerchants, setNumberOfLiveMerchants] = useState(0);

  useEffect(() => {
    fetchMyData();
  }, []);

  const fetchMyData = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
    };

    axios
      .post(`${API_URL}admin/me`, body)
      .then((response) => {
        setUsername(response?.data?.data?.name);
        setMonthlyEarnings(
          response?.data?.data?.thisMonthRevenue[0]?.total_amount
        );
        setTopPackages(response?.data?.data?.topSellingPackages);
        setPackageActivities(response?.data?.data?.packageActivities);
        setTopOffers(response?.data?.data?.topSellingOffers);
        setTopProducts(response?.data?.data?.topSellingProducts);
        setNumberOfOrders(response?.data?.data?.numberOfOrders);
        setRevenue(response?.data?.data?.totalRevenue[0].total_amount);
        setNumberOfLiveMerchants(response?.data?.data?.numberOfLiveMerchants);
        setProductAndOfferRequestsCount(response?.data?.data?.ordersCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reports = [
    {
      title: "Orders",
      iconClass: "bx-copy-alt",
      description: `${numberOfOrders}`,
    },
    {
      title: "Revenue",
      iconClass: "bx-archive-in",
      description: `${revenue} QAR`,
    },
    {
      title: "Live Merchants",
      iconClass: "bx-purchase-tag-alt",
      description: `${numberOfLiveMerchants}`,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Swaplater</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp username={username} />
              <MonthlyEarning earnings={monthlyEarnings} />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Orders</h4>
                  </div>
                  <StackedColumnChart orders={productAndOfferRequestsCount} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <TopPackages packages={topPackages} />
            </Col>
            <Col xl="6">
              <ActivityComp packageActivities={packageActivities} />
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <TopOffers offers={topOffers} />
            </Col>
            <Col xl="6">
              <TopProducts products={topProducts} />
            </Col>
          </Row>

          {/* <Row>
            <Col lg="12">
              <LatestTranaction />
            </Col>
          </Row> */}
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div className="input-group rounded bg-light">
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">225 QAR x 1</p>
                      </div>
                    </td>
                    <td>255 QAR</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">145 QAR x 1</p>
                      </div>
                    </td>
                    <td>145 QAR</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>400 QAR</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>400 QAR</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
