import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

const ActivityComp = ({ packageActivities }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Activity</CardTitle>
          <ul className="verti-timeline list-unstyled">
            {packageActivities &&
              packageActivities?.map((item, index) => (
                <div key={item.id} style={{ padding: `0px 0px 40px 30px` }}>
                  {index === 0 ? (
                    <li className="event-list active">
                      <div className="event-timeline-dot">
                        <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
                      </div>
                      <div className="d-flex">
                        <div className="me-3">
                          <h5 className="font-size-14">
                            {item.User.name}
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                          </h5>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            {item.User.name} purchased {item.name} package by{" "}
                            {item.amount} to get {item.points} points
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                      </div>
                      <div className="d-flex">
                        <div className="me-3">
                          <h5 className="font-size-14">
                            {item.User.name}
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                          </h5>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <div>
                              {item.User.name} purchased {item.name} package by{" "}
                              {item.amount} to get {item.points} points
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </div>
              ))}
          </ul>
          <div className="text-center mt-3">
            <Link to="/packages/req" className="btn btn-primary  btn-sm">
              View More <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

ActivityComp.propTypes = {
  packageActivities: PropTypes.any,
};

export default ActivityComp;
