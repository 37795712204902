import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Col, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import moment from "moment";
import axios from "axios";

import Grid from "components/Common/Grid";
import { DebounceInput } from "react-debounce-input";

const UserActivity = () => {
  const [users, setUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const [nameSearchKey, setNameSearchKey] = useState("");
  const [mobileSearchKey, setMobileSearchKey] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchUsers();
  }, [nameSearchKey, mobileSearchKey]);

  const fetchUsers = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      name: nameSearchKey,
      mobile: mobileSearchKey,
    };

    axios
      .post(`${API_URL}admin/allUsers/list`, body)
      .then((response) => {
        setUsers(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchUsers(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchUsers(page, newPerPage);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.mobile}</h5>
      ),
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.rule}</h5>,
      sortable: true,
    },
    {
      name: "Last Login Date",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {row.lastLoginDate !== null
            ? handleValidDate(row.lastLoginDate)
            : "-"}
        </h5>
      ),
    },
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Activity | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Tags")}
            // breadcrumbItem={props.t("Tags")}
            title="User Activity"
            breadcrumbItem="User Activity"
          />

          <Row className="mb-2">
            <Col sm="8" md="4">
              {/* <div className="search-box ms-2 mb-2 d-inline-block"> */}{" "}
              <DebounceInput
                type="text"
                style={{
                  borderRadius: `30px`,
                  border: `1px solid gray`,
                  padding: `10px`,
                  width: `100%`,
                }}
                debounceTimeout={1000}
                onChange={(e) => {
                  setNameSearchKey(e.target.value);
                }}
                placeholder="Search By English Name.."
              />
              {/* </div> */}
            </Col>
            <Col sm="8" md="4">
              {/* <div className="search-box ms-2 mb-2 d-inline-block"> */}{" "}
              <DebounceInput
                type="text"
                style={{
                  borderRadius: `30px`,
                  border: `1px solid gray`,
                  padding: `10px`,
                  width: `100%`,
                }}
                debounceTimeout={1000}
                onChange={(e) => {
                  setMobileSearchKey(e.target.value);
                }}
                placeholder="Search by Mobile..."
              />
              {/* </div> */}
            </Col>
          </Row>

          {users?.length !== 0 ? (
            <>
              <Col sm="4"></Col>
              <Grid
                data={users}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            ``
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
UserActivity.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(UserActivity);
