import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index.js";

import Categories from "../pages/Categories/index.js";
import Users from "../pages/Users/index.js";
import UserDetails from "pages/UserDetails";
import Merchants from "../pages/Merchants/index.js";
import PendingMerchants from "../pages/PendingMerchants/index.js";
import PendingMerchantDetails from "pages/PendingMerchantDetails";
import MerchantDetails from "pages/MerchantDetails";
import Offers from "../pages/Offers/index.js";
import Packages from "../pages/Packages/index.js";
import PackagesRequests from "pages/PackagesRequests";
import TransactionsList from "pages/TransactionsList";
import NotificationsList from "pages/NotificationsList";
import PackageDetails from "../pages/PackageDetails/index";
import PackageRequestDetails from "pages/PackageDetails/PackageRequestDetails";
import BranchDetails from "pages/BranchDetails";
import Requests from "pages/Requests";
import Reports from "pages/Reports";

import Tickets from "pages/Tickets";
import TicketDetails from "pages/TicketDetails";

import Plans from "pages/Plans";
import PlanDetails from "pages/PlanDetails";
import Admins from "pages/Admins/index";
import Tags from "pages/Tags/index";
import Settlements from "pages/Settlements/index";
import SettelmentDetails from "pages/SettelmentDetails";
import RequestDetails from "pages/RequestDetails";
import OfferDetails from "pages/OfferDetails";

import ProductRequests from "pages/ProductRequests";
import ProductsMerchants from "pages/ProductsMerchants";
import Products from "../pages/Products/index.js";
import ProductDetails from "pages/ProductDetails";

import OrdersDetails from "pages/OrdersDetails";
// import UserTicketList from "pages/UserTicketList";

import Companies from "pages/Companies/index";
import CompanyDetails from "pages/CompanyDetails";
import UserActivity from "pages/UserActivity";
import AgentEmployeeDetails from "pages/AgentEmployeeDetails";
import UserTermination from "pages/UserTermination";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/categories", component: Categories },
  { path: "/users", component: Users },
  { path: "/user/details/:id", component: UserDetails },
  { path: "/admins", component: Admins },

  { path: "/merchants", component: Merchants },
  { path: "/merchant/details/:id", component: MerchantDetails },

  { path: "/pendingMerchants", component: PendingMerchants },
  { path: "/pendingMerchants/details/:id", component: PendingMerchantDetails },

  { path: "/branch/details/:branchId/:merchantId", component: BranchDetails },

  { path: "/packages", component: Packages },
  { path: "/packages/req", component: PackagesRequests },
  { path: "/package/details/:id", component: PackageDetails },
  { path: "/transactions/list", component: TransactionsList },
  {
    path: "/package/request/details/:requestId",
    component: PackageRequestDetails,
  },
  { path: "/notifications/list", component: NotificationsList },
  { path: "/requests", component: Requests },
  { path: "/request/details/:id", component: RequestDetails },
  // { path: "/userticket/list", component: UserTicketList },

  { path: "/products", component: ProductsMerchants },
  { path: "/merchant-products/:id", component: Products },
  { path: "/product/details/:id", component: ProductDetails },
  
  { path: "/orders", component: ProductRequests },
  { path: "/order/summary/:id", component: OrdersDetails },

  { path: "/offers", component: Offers },
  { path: "/offer/details/:id", component: OfferDetails },

  { path: "/companies", component: Companies },
  { path: "/company/details/:id", component: CompanyDetails },

  { path: "/tags", component: Tags },
  { path: "/userActivity", component: UserActivity },
  { path: "/userTermination", component: UserTermination },

  { path: "/settlements", component: Settlements },
  { path: "/settlement/details/:id", component: SettelmentDetails },

  { path: "/plans", component: Plans },
  { path: "/plan/details/:id", component: PlanDetails },

  { path: "/agent/employee/details/:id", component: AgentEmployeeDetails },

  { path: "/reports", component: Reports },

  { path: "/tickets", component: Tickets },
  { path: "/ticket/details/:id", component: TicketDetails },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { publicRoutes, authProtectedRoutes };
