import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import EmptyTable from "../../components/Common/EmptyTable";
import { Button, Col, Card, CardBody, CardTitle } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import DeleteModal from "../../components/Common/DeleteModal";
import EmployeeNewAndEditModal from "./EmployeeNewAndEditModal/index";
import Grid from "components/Common/Grid";

const Employees = ({ merchant, branch, merchantBlocked }) => {
  const [employees, setEmployees] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [employee, setEmployee] = useState({});

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("authUser");

  useEffect(() => {
    fetchEmployees();
  }, [searchKey]);

  const fetchEmployees = async (pagenumber, limit) => {
    const body = {
      token: token,
      pageNum: pagenumber,
      limit: limit,
      merchantId: merchant,
      branchId: branch !== undefined ? branch : ``,
      fName: searchKey,
    };

    axios
      .post(`${API_URL}admin/merchant/employees/list`, body)
      .then((response) => {
        setEmployees(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchEmployees(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchEmployees(page, newPerPage);
  };

  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <>
          {!row.image ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {row.merchantUser.fName.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              {row.image !== null ? (
                <img
                  src={row.image}
                  alt=""
                  className="rounded-circle avatar-xs"
                />
              ) : (
                ``
              )}
            </div>
          )}
        </>
      ),
    },
    {
      name: "First Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.merchantUser.fName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.merchantUser.lName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.merchantUser.mobileNum}</h5>
      ),
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.rule}</h5>,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row.merchantUser.isActive === true ? `Active` : `Not Active`}
        </h5>
      ),
      sortable: true,
    },
    // {
    //   name: "Created At",
    //   cell: (row) => (
    //     <h5 className="font-size-12 text-dark">
    //       {handleValidDate(row.createdAt)}
    //     </h5>
    //   ),
    // },
    {
      name: "Updated At",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.merchantUser.updatedAt)}
        </h5>
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <Link className="text-danger" to="#">
          <i
            className="mdi mdi-delete font-size-18"
            id="deletetooltip"
            onClick={() => onClickEmployeeDelete(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "edit",
      cell: (row) => (
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => handleEditEmployee(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditEmployee = (item) => {
    setModal(true);
    setModalValue("edit");
    setEmployee(item);
  };

  const onClickEmployeeDelete = (item) => {
    setEmployee(item);
    setDeleteModal(true);
  };

  const handleDeleteEmployee = (item) => {
    const body = {
      token: token,
      userId: item.merchantUser.ownerId,
    };
    axios
      .post(`${API_URL}admin/merchant/employees/delete`, body)
      .then(() => {
        fetchEmployees(1, 10);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEmployee}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={employee}
      />
      <EmployeeNewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        employee={modalValue === "new" ? {} : employee}
        fetchEmployees={fetchEmployees}
        merchant={merchant}
      />

      <div>
        <MetaTags>
          <title>Employees | Swaplater</title>
        </MetaTags>

        <Col>
          <div className="text-sm-end mb-2">
            <Button
              color="primary"
              className="font-16 btn-block btn btn-primary"
              onClick={addNew}
              disabled= {merchantBlocked ? true : false}
            >
              <i className="mdi mdi-plus-circle-outline me-1" />
              Create New Employee
            </Button>
          </div>
        </Col>

        <Col sm="4">
          <div className="search-box ms-2 mb-2 d-inline-block">
            <div className="position-relative">
              <DebounceInput
                type="text"
                style={{
                  borderRadius: `30px`,
                  border: `1px solid gray`,
                  width: `400px`,
                  padding: `10px`,
                }}
                debounceTimeout={1000}
                onChange={(e) => {
                  setSearchKey(e.target.value);
                }}
                placeholder="Search Employees.."
              />
            </div>
          </div>
        </Col>

        {employees?.length !== 0 ? (
          <>
            <Grid
              data={employees}
              columns={columns}
              totalRows={totalRows}
              limit={limit}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </>
        ) : (
          <Card>
            <CardBody>
              <CardTitle>Employees</CardTitle>
              <EmptyTable />
            </CardBody>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};
Employees.propTypes = {
  t: PropTypes.any,
  merchant: PropTypes.any,
  branch: PropTypes.any,
  merchantBlocked: PropTypes.any,
};

export default withTranslation()(Employees);
