import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Card, CardBody, Col, Row, Container } from "reactstrap";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import axios from "axios";

import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;

const PackageRequestDetails = () => {
  const customStyles = {
    headCells: {
      style: {},
    },
    table: {
      style: {},
    },
  };
  const params = useParams();
  const id = params?.requestId;

  const [packageRequestData, setPackageRequestData] = useState([]);

  useEffect(() => {
    fetchPackageRequestData();
  }, []);

  const fetchPackageRequestData = () => {
    const body = { token: localStorage.getItem("authUser"), id: id };
    axios
      .post(`${API_URL}admin/package/req/details`, body)
      .then((response) => {
        setPackageRequestData(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveRequest = () => {
    const body = { token: localStorage.getItem("authUser"), id: id };
    axios
      .post(`${API_URL}admin/package/req/aprove`, body)
      .then((response) => {
        setPackageRequestData(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Subscribers | Swaplater</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Package Name</p>
                    <h4 className="mb-0">{packageRequestData?.name}</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Merchant</p>
                    <h4 className="mb-0">{packageRequestData?.User?.name}</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <span className="font-size-16 text-dark">
                  Is Request Approved{" "}
                </span>{" "}
                <input
                  className="form-check-input mt-1"
                  type="checkbox"
                  value={packageRequestData?.isAproved}
                  id="flexCheckDefault"
                  defaultChecked={packageRequestData?.isAproved}
                  onClick={approveRequest}
                  disabled={
                    packageRequestData?.isAproved === true ? true : false
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="invoice-title">
                  <div className="mb-4">Request Details</div>
                </div>
                <hr />
                <Row>
                  <Col xs="6">
                    <address>
                      <strong>Package</strong>
                      <br />
                      <>
                        <span>
                          <strong>Name: </strong>
                          {packageRequestData?.name}
                        </span>
                        <br />
                        <span>
                          <strong>Arabic Name: </strong>
                          {packageRequestData?.nameAr}
                        </span>
                        <br />
                        <span>
                          <strong>Amount: </strong>
                          {packageRequestData?.amount}
                        </span>
                        <br />
                        <span>
                          <strong>Points: </strong>
                          {packageRequestData?.points}
                        </span>
                        <br />
                        <span>
                          <strong>Type: </strong>
                          {packageRequestData?.type}
                        </span>
                        <br />
                      </>
                    </address>
                  </Col>

                  <Col xs="6" className="text-end">
                    <address>
                      <strong>Merchant</strong>
                      <br />
                      <>
                        <span>
                          <strong>Name: </strong>
                          {packageRequestData?.User?.name}
                        </span>
                        <br />
                        <span>
                          <strong>Mobile: </strong>{" "}
                          {packageRequestData?.User?.mobile}
                        </span>
                        <br />
                        <span>
                          {" "}
                          <strong>Currency: </strong>
                          {packageRequestData?.User?.userCurrncy}
                        </span>
                        <br />
                        <span>
                          {" "}
                          <strong>Balance: </strong>
                          {packageRequestData?.User?.balance}
                        </span>
                        <br />
                      </>
                    </address>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3 text-center">
                    <address>
                      <strong>Dates</strong>
                      <br />
                      <>
                        <span>
                          <strong>Request creation date: </strong>
                          {handleValidDate(packageRequestData?.createdAt)}
                        </span>
                        <br />
                        <span>
                          <strong>Package Valid to: </strong>
                          {packageRequestData?.validto !== null
                            ? handleValidDate(packageRequestData?.validto)
                            : `not specified`}
                        </span>
                        <br />
                      </>
                    </address>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

PackageRequestDetails.propTypes = {
  merchantUsers: PropTypes.any,
  columns: PropTypes.any,
};

export default PackageRequestDetails;
