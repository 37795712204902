import PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const NewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  plan,
  fetchPlans,
}) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (plan && plan.name) || "",
      monthsDuration: (plan && plan.monthsDuration) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Plan Name"),
      monthsDuration: Yup.string().required(
        "Please Enter Your Months Duration"
      ),
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        const body = {
          token: localStorage.getItem("authUser"),
          id: plan.id,
          name: values.name.toLowerCase(),
          monthsDuration: values.monthsDuration,
        };

        axios
          .post(`${API_URL}admin/plan/update`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchPlans(1, 10);
          });
        validation.resetForm();
        setShowModal(false);
      } else {
        axios
          .post(
            `${API_URL}admin/plan/create`,
            {
              token: localStorage.getItem("authUser"),
              name: values["name"].toLowerCase(),
              monthsDuration: values["monthsDuration"],
            },
            { headers: { "Access-Control-Allow-Origin": "*" } }
          )
          .then(() => {
            fetchPlans(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setShowModal(false);
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit plan" : "Add plan"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Months Duration</Label>
                <Input
                  name="monthsDuration"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.monthsDuration || ""}
                  invalid={
                    validation.touched.monthsDuration &&
                    validation.errors.monthsDuration
                      ? true
                      : false
                  }
                />
                {validation.touched.monthsDuration &&
                validation.errors.monthsDuration ? (
                  <FormFeedback type="invalid">
                    {validation.errors.monthsDuration}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

NewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  plan: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchPlans: PropTypes.func,
};

export default NewAndEditModal;
