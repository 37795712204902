import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import { Container, Card, CardBody, CardTitle, Table } from "reactstrap";
import EmptyTable from "../../components/Common/EmptyTable";
import moment from "moment";
import axios from "axios";
import Agents from "pages/Agents";
import AgentsEmployees from "pages/AgentsEmployees";
import Plans from "pages/Plans";
import CompanyPlans from "pages/CompanyPlans";

const CompanyDetails = () => {
  const [company, setCompany] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchSingleCompany();
  }, []);

  const fetchSingleCompany = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      companyId: id,
    };

    axios
      .post(`${API_URL}admin/company/details`, body)
      .then((response) => {
        setCompany(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const approveCompany = () => {
    const body = {
      token: localStorage.getItem("authUser"),
      companyId: id,
      isApproved: true,
    };
    axios
      .post(`${API_URL}admin/company/approve`, body)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company Details| Swaplater</title>{" "}
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="company" breadcrumbItem="Company Details" />

          <Card>
            <CardBody>
              <CardTitle className="mb-4">{company?.nameEn} Details</CardTitle>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">English Name :</th>
                      <td>{company?.nameEn}</td>
                    </tr>
                    <tr>
                      <th scope="row">Arabic Name :</th>
                      <td>{company?.nameAr}</td>
                    </tr>
                    <tr>
                      <th scope="row">Phone:</th>
                      <td>{company?.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">English Address :</th>
                      <td>{company?.addressEn}</td>
                    </tr>
                    <tr>
                      <th scope="row">Arabic Address :</th>
                      <td>{company?.addressAr}</td>
                    </tr>
                    <tr>
                      <th scope="row">Creation Date :</th>
                      <td>{handleValidDate(company?.createdAt)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Is Active :</th>
                      <td>
                        <span style={{ marginRight: `5px` }}>
                          {company.isActive == false ? "false" : "true"}
                        </span>
                        <input
                          className="form-check-input mt-1"
                          type="checkbox"
                          value={company?.isActive}
                          id="flexCheckDefault"
                          defaultChecked={company?.isActive}
                          onClick={approveCompany}
                          disabled={company?.isActive === true ? true : false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          {company?.id ? (
            <>
              <CompanyPlans companyId={company?.id} />
              <Agents companyId={company?.id} />
              <AgentsEmployees companyId={company?.id} />
            </>
          ) : (
            <Card>
              <CardBody>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
CompanyDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(CompanyDetails);
