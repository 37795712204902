import PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import { post } from "helpers/api_helper";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const AddNotification = ({
  show,
  setShowModalAdd,
  modalAddValue,
  onCloseClick,
}) => {
  const validation = useFormik({
    // enableReinitialize : uses this flag when initial values needs to be changed
    enableReinitialize: true,

    onSubmit: (values) => {
      setShowModalAdd(false);
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">Create Notification</ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            const data = {
              token: localStorage.getItem("token"),
              message: {
                title: e.target.title.value,
                body: e.target.body.value,
              },
              date: e.target.date.value,
            };

            post(`${API_URL}admin/pushNotification`, data)
              .then((response) => {
                console.log("-----".repeat(25));
                console.log(response);
                console.log("-----".repeat(25));
              })
              .catch((err) => {
                console.log(err.response);
              });
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Title</Label>
                <Input name="title" id="title" type="text" />
              </div>
              <div className="mb-3">
                <Label className="form-label">Body</Label>
                <Input name="body" id="body" type="textarea" />
              </div>

              <div className="mb-3">
                <Label className="form-label">Send Date</Label>
                <Input
                  type="datetime-local"
                  id="date"
                  name="date"
                  // onChange={validation.handleChange}
                  // onBlur={validation.handleBlur}
                  // invalid={
                  //   validation.touched.startDate && validation.errors.startDate
                  //     ? true
                  //     : false
                  // }
                  //value={validation.values.startDate || ""}
                ></Input>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

AddNotification.propTypes = {
  modalAddValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  setShowModalAdd: PropTypes.func,
};

export default AddNotification;
