import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import TicketMessageForm from "../Forms/TicketMessageForm";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import CloseTicketModal from "../../components/Common/CloseTicketModal";
import {
  Card,
  CardBody,
  Table,
  Button,
  CardTitle,
  Container,
  Collapse,
} from "reactstrap";


const TicketDetails = () => {
  const [ticket, setTicket] = useState({});
  const [ticketEdited, setTicketEdited] = useState(false);
  const [messages, setMessages] = useState({});
  const [editModal, setEditModal] = useState(false);
  // Collapse Open state
  const [isOpen, setIsOpen] = React.useState(false);


  const API_URL = process.env.REACT_APP_API_URL;


  const params = useParams();

  const id = params.id;

  // useEffect(() => {
  //   fetchSingleticket();
  // };
  useEffect(() => {
    fetchSingleticket();
  }, [ticketEdited]);

  useEffect(() => {
    fetchMessages();
    return () => (fetchMessages)
  }
  , []);

  const fetchSingleticket = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: id,
    };

    axios
      .post(`${API_URL}admin/ticket/details`, body)
      .then((response) => {
        setTicket(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMessages = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      ticketId: id,
    };
    axios
      .post(`${API_URL}admin/ticket/messageHistory/list`, body)
      .then((response) => {
        setMessages(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Edit Ticket
  const onClickTicketEdit = () => {
    setEditModal(true);
  };

  const handleEditTicket = () => {
    const body = {
      token: localStorage.getItem("authUser"),
      ticketId: id,
    };
    axios
      .post(`${API_URL}admin/ticket/complete`, body)
      .then(() => {
        setTicketEdited(true);
      })
      .catch((err) => {
        console.log(err);
      });
    setEditModal(false);
  };

  return (
    <React.Fragment>

      <CloseTicketModal
        show={editModal}
        onSavedClick={handleEditTicket}
        onCloseClick={() => setEditModal(false)}
        itemToBeSaved={ticket}
      />


      <div className="page-content">
        <MetaTags>
          <title>Ticket Details| Swaplater</title>{" "}
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="ticket" breadcrumbItem="ticket Details" />



          <Card>
            <CardBody>
              {!ticket.isComplete ?
                <div style={{ textAlign: "right", paddingBottom: "20px" }}>
                  <Button
                    type="submit"
                    color="danger"
                    onClick={() => onClickTicketEdit()}
                  >
                    Close Ticket
                  </Button>
                </div>
                : <></>

              }
              {ticket?.User?.isBlocked ?
                <h2 style={{ color: 'red', fontWeight: 700 }}>User isBlocked</h2>
                : <></>
              }
              <CardTitle><span style={{fontWeight: 900 }}>Requested By: </span>{ticket?.User?.name.charAt(0).toUpperCase() + ticket?.User?.name.slice(1)} <span style={{ color: "green", fontSize: "11px" }}>({ticket?.User?.rule.toUpperCase()})</span></CardTitle>
              <h5><span style={{fontWeight: 700 }}>Ticket Number: </span>{ticket?.ticketNumber}</h5>
              <div className="table-responsive">
                <Table>
                  <tbody>
                    <tr>
                      <th scope="row">User:</th>
                      <td>{ticket.User?.name}</td>

                    </tr>
                    <tr>
                      <th scope="row">Ticket Type:</th>
                      <td>{ticket.ticketType}</td>
                      <td><b>Ticket ID: </b>{ticket.id}</td>
                    </tr>
                    <tr>
                      <th scope="row">Ticket Body:</th>
                      <td>{ticket.ticketBody}</td>
                    </tr>
                    {/* <tr>
                      <th scope="row">Status:</th>
                      <td>{ticket.status}</td>
                    </tr> */}
                    <tr>
                      <th scope="row">Completed:</th>
                      <td>{ticket.isComplete ? <span style={{ fontSize: "21px" }}>&nbsp; &#9989;</span> : <span>&nbsp; &nbsp; &#10060;</span>}</td>
                    </tr>
                    <tr>
                      <th scope="row">Closed:</th>
                      <td>{ticket.isClosed ? <span style={{ fontSize: "21px" }}>&nbsp; &#9989;</span> : <span>&nbsp; &nbsp; &#10060;</span>}</td>
                    </tr>
                    <tr>
                      <th scope="row">Created At:</th>
                      <td>
                        {
                          new Date(`${ticket.createdAt}`).toLocaleDateString(
                            'en-us',
                            {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                            }
                          )
                        }
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Latest Update:</th>
                      <td>{
                        new Date(`${ticket.updatedAt}`).toLocaleDateString(
                          'en-us',
                          {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          }
                        )
                      }</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>

            {
              ticket.Request === null && ticket.ProductRequest === null ?
                <>

                  {/* <h1>General</h1> */}
                </>
                :
                <>

                  {ticket.Request !== null &&
                    <CardBody>
                      <CardTitle className="mb-4">Offer Details</CardTitle>
                      {/* <p className="text-muted mb-4">{ticket?.description}</p> */}
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">


                          <tbody>
                            <tr>
                              <th scope="row">Code:</th>
                              <td>{ticket?.Request?.code}</td>
                            </tr>
                            <tr>
                              <th scope="row">Amount Points:</th>
                              <td>{ticket?.Request?.amountPoints}</td>
                            </tr>
                            <tr>
                              <th scope="row">Amount Price:</th>
                              <td>{ticket?.Request?.amountPrice} $</td>
                            </tr>
                            <tr>
                              <th scope="row">Amount Points:</th>
                              <td>{ticket?.Request?.amountPoints}</td>
                            </tr>
                            <tr>
                              <th scope="row">Title:</th>
                              <td>{ticket?.Request?.offerDetail?.title}</td>
                            </tr>
                            <tr>
                              <th scope="row">Description:</th>
                              <td>{ticket?.Request?.offerDetail?.description}</td>
                            </tr>
                            <tr>
                              <th scope="row">Active:</th>

                              <td>{ticket.Request?.offerDetail?.isActive ? <span style={{ fontSize: "21px" }}>&#9989;</span> : <span>&#10060;</span>}</td>
                            </tr>
                            <tr>
                              <th scope="row">Available Number Of Offers:</th>
                              <td>{ticket?.Request?.offerDetail?.availableNumberOfOffers}</td>
                            </tr>

                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  }

                  {ticket.ProductRequest !== null &&
                    <CardBody>
                      <CardTitle className="mb-4">Product Details</CardTitle>
                      {/* <p className="text-muted mb-4">{ticket?.description}</p> */}
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">


                          <tbody>
                            <tr>
                              <th scope="row">Name:</th>
                              <td>{ticket?.ProductRequest?.Product?.name}</td>
                            </tr>
                            <tr>
                              <th scope="row">Description:</th>
                              <td>{ticket?.ProductRequest?.Product?.description}</td>
                            </tr>
                            <tr>
                              <th scope="row">Amount Points:</th>
                              <td>{ticket?.ProductRequest?.amountPoints}</td>
                            </tr>
                            <tr>
                              <th scope="row">Price:</th>
                              <td>{ticket?.ProductRequest?.Product?.price} $</td>
                            </tr>
                            <tr>
                              <th scope="row">Type:</th>
                              <td>{ticket?.ProductRequest?.type}</td>
                            </tr>
                            <tr>
                              <th scope="row">Payment Type:</th>
                              <td>{ticket?.ProductRequest?.paymentType}</td>
                            </tr>
                            <tr>
                              <th scope="row">Status:</th>
                              <td>{ticket?.ProductRequest?.status}</td>
                            </tr>
                            <tr>
                              <th scope="row">Delivery Status:</th>
                              <td>{ticket?.ProductRequest?.deliveryStatus}</td>
                            </tr>

                            {/* <tr>
                        <th scope="row"> :</th>
                        <td>
                          {ticket?.deliveryAvailability === false ? "No" : "Yes"}
                        </td>
                      </tr> */}
                            {/* <tr>
                        <th scope="row">Upload Image:</th>
                        <td>
                          <div>
                            <Link className="text-black" to="#">
                              <i
                                className="mdi mdi-cloud-upload font-size-24"
                                id="edittooltip"
                                onClick={() => fileInputRef.current.click()}
                              ></i>
                            </Link>
                            <input
                              type="file"
                              onChange={handleUploadImage}
                              id="image"
                              style={{
                                border: "none",
                                width: `100%`,
                              }}
                              name="image"
                              ref={fileInputRef}
                              hidden
                            />
                          </div>
                        </td>
                      </tr> */}
                            {/* <tr>
                        <th scope="row">Image :</th>
                        <td>
                          {" "}
                          <img
                            src={
                              ticket?.image !== null
                                ? `${IMAGEURL}${ticket?.image}`
                                : features
                            }
                            alt=""
                            style={{ height: `100px`, objectFit: `contain` }}
                            className="img-fluid d-block"
                          />
                        </td>
                      </tr> */}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  }
                </>


            }


          </Card>


          <Card>
            <CardBody>
              <CardTitle>All Messages</CardTitle>

              <div style={{
                width: "100%", padding: 50,
              }}>
                {/* <h4>ReactJS Reactstrap Collapse Component</h4> */}
                <Button style={{ width: "100%" }} color="primary" onClick={() => {
                  setIsOpen(!isOpen)
                }}>Check Messages </Button>
                <Collapse isOpen={isOpen}>

                  <div className="table-responsive" style={{ paddingTop: 40 }}>
                    <Table>
                      <tbody>
                        <tr>
                          <th scope="row">{ticket?.User?.name.charAt(0).toUpperCase() + ticket?.User?.name.slice(1)}:</th>
                          <td>{ticket?.ticketBody}</td>
                        </tr>
                        {messages?.ticketsHistoryMessage?.rows?.slice(0).reverse().map((item) => {
                          return <tr key={item.id}>
                            <th scope="row" style={(item.adminId === null) ? { color: "blue" } : { color: "green" }} >{item.adminId === null ? "(User)" + item.user.name : "(Swaplater)" + item.admin.name.charAt(0).toUpperCase() + item.admin.name.slice(1)}:</th>
                            <td style={(item.adminId === null) ? { color: "blue" } : { color: "green" }}>{item.messageBody} </td>

                          </tr>
                        })}
                      </tbody>
                    </Table>

                    {
                      ticket?.isClosed || ticket?.isComplete ?
                        <></> :
                        <TicketMessageForm />
                    }
                  </div>
                </Collapse>
              </div >







            </CardBody>
          </Card>

          <Link
            className="nav-link arrow-none"
            style={{ fontSize: "25px", color: "green", textAlign: "right", marginBottom: "50px" }}
            to="/tickets">
            <i className="bx bx-dice-3 me-2"></i>
            All Tickets
          </Link>
        </Container>
      </div>
    </React.Fragment>
  );
};
TicketDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(TicketDetails);
