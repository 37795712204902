import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  Table,
} from "reactstrap";
import MetaTags from "react-meta-tags";

import moment from "moment";
import Employees from "pages/Employees";
// import Offers from "pages/Offers";
import EmptyTable from "../../components/Common/EmptyTable";
import { useParams } from "react-router-dom";
import MapContainer from "components/MapContainer";

const BranchDetails = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("authUser");

  const params = useParams();
  const branchId = params?.branchId;
  const merchantId = params?.merchantId;

  const [branch, setBranch] = useState([]);
  const [singleMerchantData, setSingleMerchantData] = useState({});

  useEffect(() => {
    fetchSingleMerchant();
    fetchSingleBranch();
  }, []);

  const fetchSingleMerchant = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      merchantId: merchantId,
    };

    axios
      .post(`${API_URL}admin/merchant/details`, body)
      .then((response) => {
        setSingleMerchantData(response?.data.data.merchantDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchSingleBranch = () => {
    const body = {
      token: token,
      branchId: branchId,
    };
    axios
      .post(`${API_URL}admin/merchant/branch/details`, body)
      .then((response) => {
        setBranch(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Branch Details | Swaplater</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Branch Name</p>
                    <h4 className="mb-0">{branch?.branchName}</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Merchant Name</p>
                    <h4 className="mb-0">{singleMerchantData.name}</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Col sm="6">
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Branch Information</CardTitle>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Name :</th>
                      <td>{branch?.branchName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Arabic Name :</th>
                      <td>{branch?.branchNameAr}</td>
                    </tr>
                    <tr>
                      <th scope="row">Phone :</th>
                      <td>{branch?.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">Address :</th>
                      <td>{branch?.address}</td>
                    </tr>
                    <tr>
                      <th scope="row">Arabic Address:</th>
                      <td>{branch?.addressAr}</td>
                    </tr>
                    <tr>
                      <th scope="row">Long:</th>
                      <td>{branch?.long || ""}</td>
                    </tr>
                    <tr>
                      <th scope="row">Latitude:</th>
                      <td>{branch?.lat || ""}</td>
                    </tr>
                    <tr>
                      <th scope="row">Map</th>
                      <td><MapContainer width={620} height={200} lat={branch.lat} moveMarker={false} long={branch?.long}/></td>
                    </tr>

                    <tr>
                      <th scope="row">Created At :</th>
                      <td>{handleValidDate(branch?.createdAt)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Updated At :</th>
                      <td>{handleValidDate(branch?.updatedAt)}</td>
                    </tr>

                    <div className="mt-4">
                      <h5 className="font-size-14">
                        <i className="bx bx-comment-minus me-1 text-primary" />{" "}
                        Is Active :{" "}
                        {branch?.isActive == false ? "false" : "true"}
                      </h5>
                    </div>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/*   <h5
          className="text-truncate font-size-20"
          style={{ color: "black", textTransform: "capitalize" }}
        >
          Offers
        </h5>

        <Col lg="12">
          <Card>
            <CardBody>
              {branch.offers !== [] || null ? (
                // <Offers />
                ``
              ) : (
                <Card>
                  <CardBody>
                    <CardTitle>Offers</CardTitle>
                    <EmptyTable />
                  </CardBody>
                </Card>
              )}
            </CardBody>
          </Card>
        </Col> */}

        <h5
          className="text-truncate font-size-20"
          style={{ color: "black", textTransform: "capitalize" }}
        >
          Employees
        </h5>

        <Col lg="12">
          <Card>
            <CardBody>
              {branch !== undefined || branch !== null ? (
                <>
                  <Employees merchant={merchantId} branch={branchId} />
                </>
              ) : (
                <Card>
                  <CardBody>
                    <CardTitle>Branches</CardTitle>
                    <EmptyTable />
                  </CardBody>
                </Card>
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
};

BranchDetails.propTypes = {
  columns: PropTypes.any,
};

export default BranchDetails;
