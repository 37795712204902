import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import EmptyTable from "../../components/Common/EmptyTable";


import Grid from "components/Common/Grid";

const ProductsMerchants = () => {
  const [merchants, setMerchants] = useState([]);
  const [merchantOptions, setMerchantOptions] = useState([]);
  const [merchantTotalRows, setMerchantTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchKey, setSearchKey] = useState("");


  const API_URL = process.env.REACT_APP_API_URL;

  
  useEffect(() => {
      fetchMerchants({ pagenumber: 1, limit });
      // fetchMerchantOptions();

  }, [searchKey]);

  const fetchMerchants = async ({ pagenumber, limit }) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber || 1,
      limit: limit || 10,
    };

    axios
      .post(`${API_URL}admin/merchant/list`, body)
      .then((response) => {
        setMerchants(response?.data.data.data.rows);
        setMerchantTotalRows(response?.data.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const fetchMerchantOptions = async () => {
  //   const body = {
  //     token: localStorage.getItem("authUser"),
  //     limit: 1000,
  //   };

  //   axios
  //     .post(`${API_URL}admin/merchant/list`, body)
  //     .then((response) => {
  //       const merchantsBeforeFilter = response?.data.data.data.rows;
  //       const filteredMerchantsToValueAndLabel = merchantsBeforeFilter?.map(
  //         (item) => ({
  //           value: item.id,
  //           label: item.name,
  //         })
  //       );
  //       setMerchantOptions(filteredMerchantsToValueAndLabel);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };


  const merchantColumns = [
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name} {row.isBlocked? <span>&#128683;</span> : ""}</h5>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.mobile}</h5>
      ),
      sortable: true,
    },

    {
      name: "Balance",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.balance}</h5>
      ),
      sortable: true,
    },
    {
      name: "Show Products",
      cell: (row) => (
        <Link
        className="text-success"
        to={`/merchant-products/${row.id}`}
    >
        <Button>Show Products</Button>
    </Link>      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  const handleMerchantPageChange = (page) => {
    fetchMerchants({ pagenumber: page, limit });
  };

  const handleMerchantPerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchMerchants({
      pagenumber: page,
      limit: newPerPage,
    });
  };



  return (
    <React.Fragment>
      <MetaTags>
        <title>Products Merchants | Swaplater</title>
      </MetaTags>

   
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="products Merchants"
              breadcrumbItem="Products Merchants List"
            />
        
                {merchants?.length !== 0 ? (
                  <Grid
                    data={merchants}
                    columns={merchantColumns}
                    totalRows={merchantTotalRows}
                    limit={limit}
                    handlePageChange={handleMerchantPageChange}
                    handlePerRowsChange={handleMerchantPerRowsChange}
                  />
                ) : (
                  ``
                )}
             
            
          </Container>
        </div>
     
    </React.Fragment>
  );
};

ProductsMerchants.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(ProductsMerchants);
