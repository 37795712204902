import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";

const ProductDetails = () => {
  const [product, setProduct] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const params = useParams();
  const fileInputRef = useRef();

  const id = params.id;

  useEffect(() => {
    fetchSingleProduct();
  }, []);

  const fetchSingleProduct = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: id,
    };

    axios
      .post(`${API_URL}admin/product/details`, body)
      .then((response) => {
        setProduct(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDisableProduct = (e) => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: id,
      disable: `${e.target.checked}`,
    };
    axios.post(`${API_URL}admin/product/disable`, body).then(() => {
      fetchSingleProduct();
    });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleUploadImage = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("image", e.target.files[0]);
    formData.append("token", localStorage.getItem("authUser"));
    formData.append("productId", product?.id);

    axios
      .post(`${API_URL}admin/product/upload/image`, formData)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product Details| Swaplater</title>{" "}
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Product" breadcrumbItem="Product Details" />
          <Link
            className="text-success"
            to={{ pathname: `/merchant/details/${product?.merchant?.id}` }}
          >
            <Button style={{ margin: 20 }}>
              {product?.merchant?.name} Merchant Details
            </Button>
          </Link>

          <Card>
            <CardBody>
              <CardTitle>{product?.merchant?.name}</CardTitle>

              <div className="table-responsive">
                <Table>
                  <tbody>
                    <tr>
                      <th scope="row">Merchant Name :</th>
                      <td>{product?.merchant?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Mobile :</th>
                      <td>{product?.merchant?.mobile}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <CardTitle className="mb-4">{product?.name} Details</CardTitle>
              <p className="text-muted mb-4">{product?.description}</p>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Name :</th>
                      <td>{product?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Name Arabic :</th>
                      <td>{product?.nameAr}</td>
                    </tr>
                    <tr>
                      <th scope="row">Description :</th>
                      <td>{product?.description}</td>
                    </tr>
                    <tr>
                      <th scope="row">Description Arabic :</th>
                      <td>{product?.descriptionAr}</td>
                    </tr>
                    <tr>
                      <th scope="row">Points :</th>
                      <td>{product?.points}</td>
                    </tr>
                    <tr>
                      <th scope="row">Price :</th>
                      <td>{product?.price}</td>
                    </tr>
                    <tr>
                      <th scope="row">Stock Amount :</th>
                      <td>{product?.stockAmount}</td>
                    </tr>
                    <tr>
                      <th scope="row">Created At :</th>
                      <td>{handleValidDate(product?.createdAt)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Updated At :</th>
                      <td>{handleValidDate(product?.updatedAt)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Is available for delivery? :</th>
                      <td>
                        {product?.deliveryAvailability === false ? "No" : "Yes"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Upload Image:</th>
                      <td>
                        <div>
                          <Link className="text-black" to="#">
                            <i
                              className="mdi mdi-cloud-upload font-size-24"
                              id="edittooltip"
                              onClick={() => fileInputRef.current.click()}
                            ></i>
                          </Link>
                          <input
                            type="file"
                            onChange={handleUploadImage}
                            id="image"
                            style={{
                              border: "none",
                              width: `100%`,
                            }}
                            name="image"
                            ref={fileInputRef}
                            hidden
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Image :</th>
                      <td>
                        {" "}
                        <img
                          src={
                            product?.image !== null
                              ? `${IMAGEURL}${product?.image}`
                              : features
                          }
                          alt=""
                          style={{ height: `100px`, objectFit: `contain` }}
                          className="img-fluid d-block"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Disabled </th>
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          // value={offer?.isDisabled}
                          id="flexCheckDefault"
                          defaultChecked={product?.isDisabled}
                          onClick={handleDisableProduct}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <Link
            className="nav-link arrow-none"
            style={{
              fontSize: "25px",
              color: "green",
              textAlign: "right",
              marginBottom: "50px",
            }}
            to={{ pathname: `/merchant-products/${product?.merchantId}` }}
          >
            {/* <i className="bx bx-dice-3 me-2"></i> */}
            <Button>All {product?.merchant?.name} Products</Button>
          </Link>
        </Container>
      </div>
    </React.Fragment>
  );
};
ProductDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(ProductDetails);
