import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Button, Col, Card, CardBody, CardTitle } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { DebounceInput } from "react-debounce-input";
import EmptyTable from "../../components/Common/EmptyTable";
import DeleteModal from "../../components/Common/DeleteModal";
import BranchNewAndEditModal from "./BranchNewAndEditModal/index";
import Grid from "components/Common/Grid";

const Branches = ({ merchant, merchantBlocked }) => {
  const [branches, setBranches] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [branch, setBranch] = useState({});

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchBranches();
  }, [searchKey]);

  const fetchBranches = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber || 1,
      limit: limit || 10,
      merchantId: merchant,
      branchName: searchKey,
    };

    axios
      .post(`${API_URL}admin/merchant/branch/list`, body)
      .then((response) => {
        setBranches(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchBranches(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchBranches(page, newPerPage);
  };

  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <>
          {!row.image ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {row.branchName.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              {row.image !== null ? (
                <img
                  src={row.image}
                  alt=""
                  className="rounded-circle avatar-xs"
                />
              ) : (
                ``
              )}
            </div>
          )}
        </>
      ),
    },
    {
      name: "Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.branchName}</h5>
      ),
      sortable: true,
    },
    // {
    //   name: "Name Arabic",
    //   selector: (row) => (
    //     <h5 className="font-size-12 text-dark">{row.branchNameAr}</h5>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Address",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.address}</h5>
      ),
      sortable: true,
    },
    // {
    //   name: "Address Arabic",
    //   selector: (row) => (
    //     <h5 className="font-size-12 text-dark">{row.addressAr}</h5>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Created At",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.createdAt)}
        </h5>
      ),
    },
    {
      name: "Updated At",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.updatedAt)}
        </h5>
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <Link className="text-danger" to="#">
          <i
            className="mdi mdi-delete font-size-18"
            id="deletetooltip"
            onClick={() => onClickBranchDelete(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "edit",
      cell: (row) => (
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => handleEditBranch(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: "Branch Details",
      cell: (row) => (
        <Link
          className="text-success"
          to={`/branch/details/${row.id}/${row.merchantId}`}
        >
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditBranch = (item) => {
    setModal(true);
    setModalValue("edit");
    setBranch(item);
  };

  const onClickBranchDelete = (item) => {
    setBranch(item);
    setDeleteModal(true);
  };

  const handleDeleteBranch = (item) => {
    const body = {
      token: localStorage.getItem("authUser"),
      branchId: item.id,
    };
    axios
      .post(`${API_URL}admin/merchant/branch/delete`, body)
      .then(() => {
        fetchBranches(1, 10);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBranch}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={branch}
      />

      {merchant !== undefined ? (
        <BranchNewAndEditModal
          show={modal}
          setShowModal={setModal}
          modalValue={modalValue}
          onCloseClick={() => setModal(false)}
          branch={modalValue === "new" ? {} : branch}
          merchant={merchant}
          fetchBranches={fetchBranches}
        />
      ) : (
        ``
      )}

      <div>
        <MetaTags>
          <title>Branches | Swaplater</title>
        </MetaTags>

        <Col>
          <div className="text-sm-end mb-2">
            <Button
              color="primary"
              className="font-16 btn-block btn btn-primary"
              onClick={addNew}
              disabled= {merchantBlocked ? true : false}
            >
              <i className="mdi mdi-plus-circle-outline me-1" />
              Create New Branch
            </Button>
       
          </div>
        </Col>

        <Col sm="4">
          <div className="search-box ms-2 mb-2 d-inline-block">
            <div className="position-relative">
              <DebounceInput
                type="text"
                style={{
                  borderRadius: `30px`,
                  border: `1px solid gray`,
                  width: `400px`,
                  padding: `10px`,
                }}
                debounceTimeout={1000}
                onChange={(e) => {
                  setSearchKey(e.target.value);
                }}
                placeholder="Search Branches.."
              />
            </div>
          </div>
        </Col>

        {branches?.length !== 0 ? (
          <>
            <Grid
              data={branches}
              columns={columns}
              totalRows={totalRows}
              limit={limit}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </>
        ) : (
          <Card>
            <CardBody>
              <CardTitle>Branches</CardTitle>
              <EmptyTable />
            </CardBody>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};
Branches.propTypes = {
  t: PropTypes.any,
  merchant: PropTypes.any,
  merchantBlocked: PropTypes.any,
};

export default withTranslation()(Branches);
