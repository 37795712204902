import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import { Container, Button, Col, Card, CardTitle, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import EmptyTable from "../../components/Common/EmptyTable";
import { DebounceInput } from "react-debounce-input";

const Settlements = () => {
  const [Settlements, setSettlements] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchKey, setSearchKey] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchSettlements();
  }, [searchKey]);

  const fetchSettlements = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      name: searchKey,
    };

    axios
      .post(`${API_URL}admin/merchant/settlement/list`, body)
      .then((response) => {
        setSettlements(response?.data.data);
        // setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchSettlements(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchSettlements(page, newPerPage);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    {
      name: "mobile",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.mobile}</h5>
      ),
      sortable: true,
    },
    {
      name: "Balance Get",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.balanceGet}</h5>
      ),
      sortable: true,
    },
    {
      name: "Details",
      cell: (row) => (
        <Link className="text-success" to={`/settlement/details/${row.id}`}>
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settlements | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Settlements")}
            // breadcrumbItem={props.t("Settlements")}
            title="Settlements"
            breadcrumbItem="Settlements"
          />

          {/* <div className="search-box ms-2 mb-2 d-inline-block">
            <div className="position-relative">
              <DebounceInput
                type="text"
                style={{
                  borderRadius: `30px`,
                  border: `1px solid gray`,
                  width: `400px`,
                  padding: `10px`,
                }}
                debounceTimeout={1000}
                onChange={(e) => {
                  setSearchKey(e.target.value);
                }}
                placeholder="Search English Name.."
              />
            </div>
          </div> */}

          {Settlements?.length !== 0 ? (
            <>
              <Col sm="4"></Col>
              <Grid
                data={Settlements}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Settlements</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Settlements.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Settlements);
