import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import "./index.css";

const Navbar = (props) => {
  const [dashboard, setdashboard] = useState(false);
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [ecommerce, setecommerce] = useState(false);
  const [crypto, setcrypto] = useState(false);
  const [project, setproject] = useState(false);
  const [task, settask] = useState(false);
  const [contact, setcontact] = useState(false);
  const [blog, setBlog] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);
  const [invoice, setinvoice] = useState(false);
  const [auth, setauth] = useState(false);
  const [utility, setutility] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu overflow-auto"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link arrow-none"
                    to="/dashboard"
                    style={{
                      padding: `0 !important`,
                    }}
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Dashboard")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/packages">
                    <i className="bx bx-bulb me-2"></i>
                    {props.t("Packages")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/packages/req">
                    <i className="bx bx-bulb me-2"></i>
                    Packages Requests
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/transactions/list">
                    <i className="bx bx-bulb me-2"></i>
                    Transactions List
                  </Link>
                </li>

                {/* <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/userticket/list">
                    <i className="bx bx-bulb me-2"></i>
                    User Ticket
                  </Link>
                </li> */}

                <li className="nav-item">
                  <Link
                    className="nav-link arrow-none"
                    to="/notifications/list"
                  >
                    <i className="bx bx-bulb me-2"></i>
                    Notifications List
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/merchants">
                    <i className="bx bx-coin-stack me-2"></i>
                    {props.t("Merchants")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/pendingMerchants">
                    <i className="bx bx-user-circle me-2"></i>
                    Pending Merchants
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/categories">
                    <i className="bx bx-line-chart me-2"></i>
                    {props.t("Categories")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/tags">
                    <i className="bx bx-line-chart me-2"></i>
                    Tags
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/products">
                    <i className="bx bx-dice-3 me-2"></i>
                    Products
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/orders">
                    <i className="bx bx-dice-3 me-2"></i>
                    Orders
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/admins">
                    <i className="bx bx-user-circle me-2"></i>
                    {props.t("Admins")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/users">
                    <i className="bx bx-cool me-2"></i>
                    {props.t("Users")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/requests">
                    <i className="bx bx-credit-card me-2"></i>
                    {props.t("Requests")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/offers">
                    <i className="bx bx-dice-3 me-2"></i>
                    {props.t("Offers")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/companies">
                    <i className="bx bx-dice-3 me-2"></i>
                    Companies
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/userActivity">
                    <i className="bx bx-dice-3 me-2"></i>
                    User Activity
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/userTermination">
                    <i className="bx bx-dice-3 me-2"></i>
                    User Termination
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/settlements">
                    <i className="bx bx-dice-3 me-2"></i>
                    Settlements
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/plans">
                    <i className="bx bx-dice-3 me-2"></i>
                    instalment Plans
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/reports">
                    <i className="bx bx-dice-3 me-2"></i>
                    {/* {props.t("Reports")} */}
                    Reports
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/tickets">
                    <i className="bx bx-dice-3 me-2"></i>
                    Tickets
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
