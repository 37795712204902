import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { CSVLink } from "react-csv";
import {
  Container,
  Button,
  Col,
  Table,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { useParams, Redirect } from "react-router-dom";
import CardContent from "../../components/Card";
import EmptyTable from "../../components/Common/EmptyTable";

import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import "./index.css";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const SettelmentDetails = () => {
  const [bankAccount, setBankAccount] = useState({});

  const [initialSettlementList, setInitialSettlementList] = useState([]);
  const [pendingSettlementList, setPendingSettlementList] = useState([]);
  const [declinedSettlementList, setDeclinedSettlementList] = useState([]);
  const [approvedSettlementList, setApprovedSettlementList] = useState([]);

  const [initialIds, setInitialIds] = useState([]);
  const [pendingIds, setPendingIds] = useState([]);
  const [declinedIds, setDeclinedIds] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [settlementDetails, setSettlementDetails] = useState([]);
  const params = useParams();
  const id = params?.id;

  const columns = [
    {
      name: "Merchant Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.merchant?.name}</h5>
      ),
      sortable: true,
    },
    {
      name: "User Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.user?.name}</h5>
      ),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.amount}</h5>
      ),
      sortable: true,
    },
    {
      name: "Is Settled",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.isSettled === false ? "false" : "true"}
        </h5>
      ),
      sortable: true,
    },
  ];
  const columns2 = [
    {
      name: "Transaction Amount",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.transactionAmount}</h5>
      ),
      sortable: true,
    },
    {
      name: "Transaction Amount After Fees",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.transactionAmountAfterFees}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Transaction Profit",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.transactionProfit}</h5>
      ),
      sortable: true,
    },
  ];

  const transactionHeaders = [
    { label: "Merchant Name", key: "merchant.name" },
    { label: "Merchant Mobile", key: "merchant.mobile" },
    { label: "User Name", key: "user.name" },
    { label: "User Mobile", key: "user.mobile" },
    { label: "Amount", key: "amount" },
    { label: "is Setteled", key: "isSettled" },
    { label: "Status", key: "status" },
  ];
  const transactionHeaders2 = [
    { label: "Transaction Amount", key: "transactionAmount" },
    {
      label: "transaction Amount After Fees",
      key: "transactionAmountAfterFees",
    },
    { label: "Transaction Profit", key: "transactionProfit" },
  ];

  useEffect(() => {
    fetchSingleMerchantSettlment();
  }, [id]);

  const fetchSingleMerchantSettlment = async () => {
    const body = { token: token, merchantId: id };

    axios
      .post(`${API_URL}admin/merchant/settlement/details`, body)
      .then((response) => {
        setSettlementDetails(response?.data?.data);
        console.log(response);
        setBankAccount(response?.data?.data?.bankAccount);

        setInitialSettlementList(response?.data?.data?.initialSettlementList);
        const initialSettlementIds = JSON.stringify(
          response?.data.data.initialSettlementList?.map((item) => {
            return item.id;
          })
        );
        setInitialIds(initialSettlementIds);
        setPendingSettlementList(
          response?.data?.data?.pendingSettlementList?.rows
        );
        // const pendingSettlementIds = JSON.stringify(
        //   response?.data.data.pendingSettlementList?.map((item) => {
        //     return item.id;
        //   })
        // );
        // setPendingIds(pendingSettlementIds);

        setDeclinedSettlementList(
          response?.data?.data?.declinedSettlementList?.rows
        );
        // const declinedSettlementIds = JSON.stringify(
        //   response?.data.data.declinedSettlementList.map((item) => {
        //     return item.id;
        //   })
        // );
        // setDeclinedIds(declinedSettlementIds);

        setApprovedSettlementList(
          response?.data?.data?.approvedSettlementList?.rows
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const initiateSettelment = () => {
    const body = {
      token: localStorage.getItem("authUser"),
      ids: initialIds,
      currentStatus: "initial",
      transactionAmount: settlementDetails?.initialTransactionTotalAmountInCash,
      transactionAmountAfterFees:
        settlementDetails?.initialTransactionTotalAmountAfterDeductingFees,
    };

    axios
      .post(`${API_URL}admin/merchant/settlement/pending`, body)
      .then(() => {
        fetchSingleMerchantSettlment();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertDeclinedToPendingSettelment = () => {
    // let sum = (a: any[]) => a.reduce((x: any, y: any) => x + y);
    // let transactionTotalAmount = sum(
    //   declinedSettlementList.map((x: any) => Number(x.amount))
    // );
    // Math.ceil(transactionTotalAmount / 5);

    // let declinedTransactionTotalAmountAfterDeductingFees = Math.ceil(
    //   (transactionTotalAmount *
    //     ((100 - settlementDetails?.merchantCommissionPercentage) / 100)) /
    //     5
    // );

    const body = {
      token: localStorage.getItem("authUser"),
      id: declinedSettlementList[0]?.id,
      currentStatus: "declined",
      transactionAmount: settlementDetails?.initialTransactionTotalAmountInCash,
      transactionAmountAfterFees:
        settlementDetails?.initialTransactionTotalAmountAfterDeductingFees,
    };

    axios
      .post(`${API_URL}admin/merchant/settlement/pending`, body)
      .then(() => {
        fetchSingleMerchantSettlment();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const declineSettelment = () => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: pendingSettlementList[0]?.id,
      transactionAmount: settlementDetails?.initialTransactionTotalAmountInCash,
      transactionAmountAfterFees:
        settlementDetails?.initialTransactionTotalAmountAfterDeductingFees,
    };

    axios
      .post(`${API_URL}admin/merchant/settlement/decline`, body)
      .then(() => {
        fetchSingleMerchantSettlment();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveSettelment = () => {
    // let sum = (a: any[]) => a.reduce((x: any, y: any) => x + y);
    // let transactionTotalAmount = sum(
    //   pendingSettlementList?.map((x: any) => Number(x.amount))
    // );

    const body = {
      token: localStorage.getItem("authUser"),
      id: pendingSettlementList[0]?.id,
      transactionAmount: pendingSettlementList[0]?.transactionAmount,
    };

    axios
      .post(`${API_URL}admin/merchant/settlement/approve`, body)
      .then(() => {
        fetchSingleMerchantSettlment(); // setRedirect(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="page-content">
      <MetaTags>
        <title>Settelment Details | Swaplater</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="Settelment" breadcrumbItem="Settelment Details" />

        {bankAccount && Object.keys(bankAccount).length !== 0 ? (
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Bank Account Details</CardTitle>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Bank Name :</th>
                      <td>{bankAccount?.bankName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Account Number :</th>
                      <td>{bankAccount?.accountNumber}</td>
                    </tr>
                    <tr>
                      <th scope="row">Bank Address :</th>
                      <td>{bankAccount?.address}</td>
                    </tr>
                    <tr>
                      <th scope="row">Bank Arabic Address :</th>
                      <td>{bankAccount?.addressAr || `No arabic address`}</td>
                    </tr>
                    <tr>
                      <th scope="row">Bank IBAN :</th>
                      <td>{bankAccount?.IBAN || `no IBAN available`}</td>
                    </tr>
                    <tr>
                      <th scope="row">Created At :</th>
                      <td>{handleValidDate(bankAccount?.createdAt)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Updated At :</th>
                      <td>{handleValidDate(bankAccount?.updatedAt)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        ) : (
          ``
        )}

        <Card>
          <CardBody>
            <CardTitle>Settelment List</CardTitle>

            <Tabs
              defaultActiveKey="initial"
              id="justify-tab-example"
              className="mb-3 mt-5"
              variant="pills"
              justify
            >
              <Tab eventKey="initial" title="Initial">
                {initialSettlementList?.length !== 0 ? (
                  <>
                    <Grid data={initialSettlementList} columns={columns} />
                    {pendingSettlementList?.length === 0 &&
                    declinedSettlementList?.length === 0 ? (
                      <CSVLink
                        data={initialSettlementList}
                        headers={transactionHeaders}
                        filename={`${initialSettlementList[0]?.merchant?.name} Initial Transactions`}
                      >
                        <Col>
                          <div className="text-sm-center mb-2">
                            <Button
                              color="secondary"
                              className="font-16 btn-block btn btn-secondary"
                              onClick={initiateSettelment}
                            >
                              Initiate Transaction
                            </Button>
                          </div>
                        </Col>
                      </CSVLink>
                    ) : (
                      <Col>
                        <div className="text-sm-center mb-2">
                          <Button
                            color="secondary"
                            className="font-16 btn-block btn btn-secondary disabled"
                          >
                            Please settle all the Pending and Declined
                            Transactions First
                          </Button>
                        </div>
                      </Col>
                    )}
                    <p>
                      Total Amount :{" "}
                      {settlementDetails?.initialTransactionTotalAmountInCash}
                    </p>
                    <p>
                      Total Amount After Fees :{" "}
                      {
                        settlementDetails?.initialTransactionTotalAmountAfterDeductingFees
                      }
                    </p>
                  </>
                ) : (
                  <EmptyTable />
                )}
              </Tab>

              <Tab eventKey="pending" title="Pending">
                {pendingSettlementList?.length !== 0 ? (
                  <>
                    <Grid data={pendingSettlementList} columns={columns2} />
                    <CSVLink
                      data={pendingSettlementList}
                      headers={transactionHeaders2}
                      filename={`Pending Transactions`}
                    >
                      <Col>
                        <div className="text-sm-center mb-2 flex">
                          <Button
                            color="success"
                            className="font-16 btn-block btn btn-success mx-3"
                            onClick={approveSettelment}
                            style={{ width: `200px` }}
                          >
                            Settle Transaction
                          </Button>
                          <Button
                            color="danger"
                            className="font-16 btn-block btn btn-danger mx-3"
                            onClick={declineSettelment}
                            style={{ width: `200px` }}
                          >
                            Decline Transaction
                          </Button>
                        </div>
                      </Col>
                    </CSVLink>
                  </>
                ) : (
                  <EmptyTable />
                )}
              </Tab>

              <Tab eventKey="done" title="Done">
                {approvedSettlementList?.length !== 0 ? (
                  <>
                    <Grid data={approvedSettlementList} columns={columns2} />
                    <CSVLink
                      data={approvedSettlementList}
                      headers={transactionHeaders2}
                      filename={` Approved Transactions`}
                    >
                      <Col>
                        <div className="text-sm-center mb-2">
                          <Button
                            color="success"
                            className="font-16 btn-block btn btn-success"
                          >
                            Download Approved Transactions
                          </Button>
                        </div>
                      </Col>
                    </CSVLink>
                  </>
                ) : (
                  <EmptyTable />
                )}
              </Tab>

              <Tab eventKey="declined" title="Declined">
                {declinedSettlementList?.length !== 0 ? (
                  <>
                    <Grid data={declinedSettlementList} columns={columns2} />
                    {pendingSettlementList?.length === 0 ? (
                      <CSVLink
                        data={declinedSettlementList}
                        headers={transactionHeaders2}
                        filename={` Declined Transactions`}
                      >
                        <Col>
                          <div className="text-sm-center mb-2">
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                              onClick={convertDeclinedToPendingSettelment}
                            >
                              Move to Pending Transaction
                            </Button>
                          </div>
                        </Col>
                      </CSVLink>
                    ) : (
                      <Col>
                        <div className="text-sm-center mb-2">
                          <Button
                            color="secondary"
                            className="font-16 btn-block btn btn-secondary disabled"
                          >
                            Please settle all the Pending Transactions First
                          </Button>
                        </div>
                      </Col>
                    )}
                  </>
                ) : (
                  <EmptyTable />
                )}
              </Tab>
            </Tabs>

            {/* <Card>
              <CardBody>
                <CardTitle>
                  Settle all transactions for{" "}
                  {SettlementsList[0]?.merchant?.name}
                </CardTitle>
                <p>
                  1- You will settle all the transactions available for this
                  merchant
                </p>
                <p>
                  2- An excel file with all the trasactions will be downloaded
                </p>
                  <Col>
                    <div className="text-sm-center mb-2">
                      <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={approveSettelment}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Settle all transactions
                      </Button>
                    </div>
                  </Col>
              </CardBody>
            </Card> */}
          </CardBody>
        </Card>
      </Container>
      {redirect && <Redirect to={`/settlements`} />}
    </div>
  );
};
SettelmentDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(SettelmentDetails);
