import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";
import AgentNewAndEditModal from "./AgentNewAndEditModal";

import { DebounceInput } from "react-debounce-input";

const API_URL = process.env.REACT_APP_API_URL;

const Agents = ({ companyId }) => {
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    fetchAgents();
  }, [searchKey]);

  const fetchAgents = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      companyId: companyId,
    };

    axios
      .post(`${API_URL}admin/agentAdmin/list`, body)
      .then((response) => {
        setAgents(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchAgents(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchAgents(page, newPerPage);
  };

  const columns = [
    {
      name: "First Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.Agent?.fName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Middle Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.Agent?.mName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.Agent?.lName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.Agent?.mobileNum}</h5>
      ),
      sortable: true,
    },
    // {
    //   name: "Agent Details",
    //   cell: (row) => (
    //     <Link className="text-success" to={`/agent/details/${row.id}`}>
    //       <Button>Details</Button>
    //     </Link>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  // const handleEditAgent = (item) => {
  //   setModal(true);
  //   setModalValue("edit");
  //   setSelectedAgent(item);
  // };

  // const onClickAgentDelete = (item) => {
  //   setSelectedAgent(item);
  //   setDeleteModal(true);
  // };

  // const handleDeleteAgent = (item) => {
  //   const body = {
  //     token: localStorage.getItem("authUser"),
  //     id: item.id,
  //   };
  //   axios
  //     .post(`${API_URL}admin/agent/delete`, body)
  //     .then(() => {
  //       fetchAgents(1, limit);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   setDeleteModal(false);
  // };

  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAgent}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={selectedAgent}
      /> */}

      <AgentNewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        selectedAgent={modalValue === "new" ? {} : selectedAgent}
        fetchAgents={fetchAgents}
        companyId={companyId}
      />

      <div>
        <MetaTags>
          <title>Agents | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Categories")}
            // breadcrumbItem={props.t("Categories")}
            title="Agents"
            breadcrumbItem="Agents"
          />

          <Row>
            <Col>
              <div className="text-sm-end mb-2">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={addNew}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New agent
                </Button>
              </div>
            </Col>
          </Row>

          {agents?.length !== 0 ? (
            <>
              <Col sm="4">
                {/* <div className="search-box ms-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <DebounceInput
                      type="text"
                      style={{
                        borderRadius: `30px`,
                        border: `1px solid gray`,
                        width: `400px`,
                        padding: `10px`,
                      }}
                      debounceTimeout={1000}
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                      }}
                      placeholder="Search"
                    />
                  </div>
                </div> */}
              </Col>
              <Grid
                data={agents}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Agents</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Agents.propTypes = {
  t: PropTypes.any,
  companyId: PropTypes.any,
};

export default withTranslation()(Agents);
