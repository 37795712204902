import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Button, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import { DebounceInput } from "react-debounce-input";
import TransactionsDetails from "pages/TransactionsDetails";

const TransactionsList = () => {
  const [requests, setRequests] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [selectedObject, setSelectedObject] = useState({});
  const API_URL = process.env.REACT_APP_API_URL;

  const Details = (obj) => {
    setModal(true);
    setModalValue("new");
    setSelectedObject(obj);
  };

  useEffect(() => {
    fetchTransactions();
  }, [searchKey]);

  const fetchTransactions = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      search: searchKey
    };
    axios
      .post(`${API_URL}admin/user/transaction/list`, body)
      .then((response) => {
        setRequests(response?.data.data);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handlePageChange = (page) => {
    fetchRequests(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchRequests(page, newPerPage);
  };

  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <>
          {!row.image ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {row.paymentType.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              {row.image !== null ? (
                <img
                  src={row.image}
                  alt=""
                  className="rounded-circle avatar-xs"
                />
              ) : (
                ``
              )}
            </div>
          )}
        </>
      ),
    },
    {
      name: "Card Number",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.cardNumber}</h5>
      ),
      sortable: true,
    },
    {
      name: "Points",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.points} Points</h5>
      ),
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.price} QAR</h5>
      ),
      sortable: true,
    },
    {
      name: "Card Name",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {(row?.nameOnCard)}
        </h5>
      ),
    },
    {
      name: "Details",
      cell: (row) => (
        <Button
          color="primary"
          className="font-16 btn-block btn btn-primary"
          onClick={() => { Details(row) }}>
          Details
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <React.Fragment>
      <TransactionsDetails
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        details={selectedObject}
      />

      <div className="page-content">
        <MetaTags>
          <title>transactions | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            title="transactions"
            breadcrumbItem="transactions"
          />

          <Col sm="4">
            <div className="search-box ms-2 mb-2 d-inline-block">
              <div className="position-relative">
                <DebounceInput
                  type="text"
                  style={{
                    borderRadius: `30px`,
                    border: `1px solid gray`,
                    width: `400px`,
                    padding: `10px`,
                  }}
                  debounceTimeout={1000}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search Transactions"
                />
              </div>
            </div>
          </Col>

          {requests?.length !== 0 ? (
            <>
              <Col sm="4"></Col>
              <Grid
                data={requests}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <h6>There is no such transaction</h6>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
TransactionsList.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(TransactionsList);
