import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
} from "reactstrap";
import moment from "moment";
import axios from "axios";

const AgentEmployeeDetails = () => {
  const [agentEmployee, setAgentEmployee] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;

  const params = useParams();
  const id = params.id;

  useEffect(() => {
    fetchSingleAgentEmployee();
  }, []);

  const fetchSingleAgentEmployee = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      userId: id,
    };

    axios
      .post(`${API_URL}admin/agentUser/details`, body)
      .then((response) => {
        setAgentEmployee(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const approveAgentEmployee = () => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: agentEmployee?.id,
    };
    axios
      .post(`${API_URL}admin/agentClient/approve`, body)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const approveTermination = () => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: agentEmployee?.id,
    };
    axios
      .post(`${API_URL}admin/agentClient/termination/approve`, body)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const PayOffline = () => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: agentEmployee?.id,
      paymentType: "offline",
    };
    console.log(body);
    axios
      .post(`${API_URL}admin/agentClient/settlement/approve`, body)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Agent Employee Details| Swaplater</title>{" "}
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Agent Employee"
            breadcrumbItem="Agent Employee Details"
          />

          <Card>
            <CardBody>
              <CardTitle className="mb-4">
                {agentEmployee?.AgentEmployees?.name} Details
              </CardTitle>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Name :</th>
                      <td>{agentEmployee?.AgentEmployees?.name}</td>
                    </tr>{" "}
                    <tr>
                      <th scope="row">Mobile:</th>
                      <td>{agentEmployee?.AgentEmployees?.mobile}</td>
                    </tr>
                    <tr>
                      <th scope="row">Experience :</th>
                      <td>{agentEmployee?.experience}</td>
                    </tr>
                    <tr>
                      <th scope="row">Salary :</th>
                      <td>{agentEmployee?.salary}</td>
                    </tr>
                    <tr>
                      <th scope="row">Installment Balance:</th>
                      <td>{agentEmployee?.installmentPointsBalance}</td>
                    </tr>
                    <tr>
                      <th scope="row">Limit :</th>
                      <td>{agentEmployee?.limit}</td>
                    </tr>
                    <tr>
                      <th scope="row">Employment Date :</th>
                      <td>{handleValidDate(agentEmployee?.startingWorkAt)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Is Approved :</th>
                      <td>
                        <span style={{ marginRight: `5px` }}>
                          {agentEmployee?.isApproved == false
                            ? "false"
                            : "true"}
                        </span>
                        <input
                          className="form-check-input mt-1"
                          type="checkbox"
                          value={agentEmployee?.isApproved}
                          id="flexCheckDefault"
                          defaultChecked={agentEmployee?.isApproved}
                          onClick={approveAgentEmployee}
                          disabled={
                            agentEmployee?.isApproved === true ? true : false
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Approve Termination :</th>
                      <td>
                        <span style={{ marginRight: `5px` }}>
                          {agentEmployee?.isTerminationApproved == false
                            ? "false"
                            : "true"}
                        </span>
                        <input
                          className="form-check-input mt-1"
                          type="checkbox"
                          value={agentEmployee?.isTerminationApproved}
                          id="flexCheckDefault"
                          defaultChecked={agentEmployee?.isTerminationApproved}
                          onClick={approveTermination}
                          disabled={
                            agentEmployee?.isTerminationApproved === true
                              ? true
                              : false
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Button
                  disabled={
                    agentEmployee.balanceSettleRequest === true &&
                    agentEmployee.isSettlementConfirmed === false
                      ? false
                      : true
                  }
                  onClick={PayOffline}
                  className="text-center"
                >
                  Complete Payment Offline
                </Button>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
AgentEmployeeDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(AgentEmployeeDetails);
