import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Container, Button, Col, Row, Card, CardBody, CardTitle } from "reactstrap";


const EmptyTable = () => {
    return (
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-caret-down-circle h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>No Result found</h4>
                        <p className="mb-5">
                          Please try again later, if you found any issue please contact our support team
                          <span className="font-weight-semibold">
                            support@eshailx.com
                          </span>
                        </p>

                      </div>
                    </div>
                  </div>
               
    )}
  
  export default EmptyTable
             