import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";
import ProductsNewAndEditModal from "../Products/ProductsNewAndEditModal";

import { DebounceInput } from "react-debounce-input";
import { propTypes } from "react-bootstrap/esm/Image";

const API_URL = process.env.REACT_APP_API_URL;

const MerchantProducts = ({merchant, merchantBlocked}) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setselectedProduct] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [merchantOptions, setMerchantOptions] = useState([]);

  useEffect(() => {
    fetchProducts();
    fetchMerchants();
  }, [searchKey]);

  const fetchProducts = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      name: searchKey,
      merchantId: merchant,
    };

    axios
      .post(`${API_URL}admin/product/list`, body)
      .then((response) => {
        setProducts(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMerchants = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/merchant/list`, body)
      .then((response) => {
        const merchantsBeforeFilter = response?.data.data.data.rows;
        const filteredMerchantsToValueAndLabel = merchantsBeforeFilter?.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setMerchantOptions(filteredMerchantsToValueAndLabel);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchProducts(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchProducts(page, newPerPage);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    {
      name: "Merchant",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.merchant.name}</h5>
      ),
      sortable: true,
    },
    {
      name: "Stock Amount",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.stockAmount}</h5>
      ),
      sortable: true,
    },
    {
      name: "Points",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.points}</h5>
      ),
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.price}</h5>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <ul className="list-inline font-size-20 contact-links mb-0 text-center cursor-pointer">
          <Link to="#">
            <i
              className="mdi mdi-delete font-size-18 text-danger"
              id="deletetooltip"
              onClick={() => onClickProductDelete(row)}
            ></i>
          </Link>
          <li className="list-inline-item px-2">
            <Link to="#">
              <i
                className="mdi mdi-pencil font-size-18 text-success"
                id="edittooltip"
                onClick={() => handleEditProduct(row)}
              ></i>
            </Link>
          </li>
        </ul>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Product Details",
      cell: (row) => (
        <Link className="text-success" to={`/product/details/${row.id}`}>
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditProduct = (item) => {
    setModal(true);
    setModalValue("edit");
    setselectedProduct(item);
  };

  const onClickProductDelete = (item) => {
    setselectedProduct(item);
    setDeleteModal(true);
  };

  const handleDeleteProduct = (item) => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: item.id,
    };
    axios
      .post(`${API_URL}admin/product/delete`, body)
      .then(() => {
        fetchProducts(1, limit);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={selectedProduct}
      />

      <ProductsNewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        selectedProduct={modalValue === "new" ? {} : selectedProduct}
        fetchProducts={fetchProducts}
        merchantOptions={merchantOptions}
      />

          <Row>
            <Col>
              <div className="text-sm-end mb-2">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={addNew}
                  disabled= {merchantBlocked ? true : false}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New Product
                </Button>
              </div>
            </Col>
          </Row>

          <Col sm="4">
            <div className="search-box ms-2 mb-2 d-inline-block">
              <div className="position-relative">
                <DebounceInput
                  type="text"
                  style={{
                    borderRadius: `30px`,
                    border: `1px solid gray`,
                    width: `400px`,
                    padding: `10px`,
                  }}
                  debounceTimeout={1000}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search English Name.."
                />
              </div>
            </div>
          </Col>

          {products?.length !== 0 ? (
            <>
              <Grid
                data={products}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Products</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
    </React.Fragment>
  );
};
MerchantProducts.propTypes = {
  t: PropTypes.any,
  merchant: propTypes.any,
  merchantBlocked: propTypes.any 
};

export default withTranslation()(MerchantProducts);
