import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

const TopTagsChart = ({ tags }) => {
  const tagArray = tags.map((item) => item.name);
  const tags_count = tags.map((item) => item.tags_count);

  const series = [
    {
      name: "Tags Count",
      data: tags_count,
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#34c38f"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: tagArray,
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height="350" />
  );
};

TopTagsChart.propTypes = {
  tags: PropTypes.any,
};

export default TopTagsChart;
