import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import { Container, Card, CardBody, CardTitle, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import { map } from "lodash";

const OfferDetails = () => {
  const [offer, setOffer] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const params = useParams();
  const fileInputRef = useRef();

  const id = params.id;

  useEffect(() => {
    fetchSingleOffer();
  }, []);

  const fetchSingleOffer = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      offerId: id,
    };

    axios
      .post(`${API_URL}admin/offer/details`, body)
      .then((response) => {
        setOffer(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const handleDisableOffer = (e) => {
    const body = {
      token: localStorage.getItem("authUser"),
      offerId: id,
      disable: `${e.target.checked}`,
    };
    axios.post(`${API_URL}admin/merchant/offer/disable`, body).then(() => {
      fetchSingleOffer();
    });
  };
  const handleUploadImages = async (offerId, e) => {
    e.preventDefault();

    if (e.target.files.length > 5) {
      setImagesModal(true);
    } else {
      const formData = new FormData();

      if (e.target.files[0]) {
        formData.append("image1", e.target.files[0]);
      }
      if (e.target.files[1]) {
        formData.append("image2", e.target.files[1]);
      }
      if (e.target.files[2]) {
        formData.append("image3", e.target.files[2]);
      }
      if (e.target.files[3]) {
        formData.append("image4", e.target.files[3]);
      }
      if (e.target.files[4]) {
        formData.append("image5", e.target.files[4]);
      }

      formData.append("token", localStorage.getItem("authUser"));
      formData.append("offerId", offerId);

      axios
        .post(`${API_URL}admin/merchant/offer/upload/images`, formData)
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  console.log(offer?.isDisabled);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Offer Details| Swaplater</title>{" "}
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Offer" breadcrumbItem="Offer Details" />

          <Card>
            <CardBody>
              <CardTitle className="mb-4">{offer?.title} Details</CardTitle>
              <p className="text-muted mb-4">{offer?.description}</p>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Title :</th>
                      <td>{offer?.title}</td>
                    </tr>
                    <tr>
                      <th scope="row">Title Arabic :</th>
                      <td>{offer?.titleAr}</td>
                    </tr>
                    <tr>
                      <th scope="row">Description :</th>
                      <td>{offer?.description}</td>
                    </tr>
                    <tr>
                      <th scope="row">Description Arabic :</th>
                      <td>{offer?.descriptionAr}</td>
                    </tr>
                    <tr>
                      <th scope="row">Points :</th>
                      <td>{offer?.points}</td>
                    </tr>
                    <tr>
                      <th scope="row">Price :</th>
                      <td>{offer?.price}</td>
                    </tr>
                    <tr>
                      <th scope="row">Start Date :</th>
                      <td>{handleValidDate(offer?.startDate)}</td>
                    </tr>
                    <tr>
                      <th scope="row">End Date :</th>
                      <td>{handleValidDate(offer?.endDate)}</td>
                    </tr>
                    <tr>
                      <th scope="row">has Images :</th>
                      <td>
                        {" "}
                        {offer?.images?.length === 0 ||
                        offer?.images === null ? (
                          <h5 className="font-size-12 text-dark">No Images</h5>
                        ) : (
                          <div className="avatar-group">
                            {map(offer.images, (member, index) =>
                              member !== "Null" ? (
                                <div className="avatar-group-item" key={index}>
                                  <Link
                                    to="#"
                                    className="team-member d-inline-block"
                                    id="member1"
                                  >
                                    <img
                                      src={`${IMAGEURL}${offer.images[index]}`}
                                      className="rounded-circle avatar-xs"
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              ) : (
                                ``
                              )
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Upload Images:</th>
                      <td>
                        {" "}
                        <div>
                          <Link className="text-black" to="#">
                            <i
                              className="mdi mdi-cloud-upload font-size-24"
                              id="edittooltip"
                              onClick={() => fileInputRef.current.click()}
                            ></i>
                          </Link>
                          <input
                            type="file"
                            onChange={(e) => handleUploadImages(offer.id, e)}
                            id="images"
                            style={{
                              border: "none",
                              width: `100%`,
                            }}
                            name="images"
                            multiple
                            ref={fileInputRef}
                            hidden
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Disabled </th>
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          // value={offer?.isDisabled}
                          id="flexCheckDefault"
                          defaultChecked={offer?.isDisabled}
                          onClick={handleDisableOffer}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
OfferDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(OfferDetails);
