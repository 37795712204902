import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import CardContent from "../../components/Card";
import EmptyTable from "../../components/Common/EmptyTable";
import features from "../../assets/images/crypto/features-img/img-1.png";

import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const PackageDetails = () => {
  const [singlePackageData, setSinglePackageData] = useState({});
  const [requestsData, setRequestsData] = useState([]);

  const params = useParams();
  const id = params?.id;

  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <>
          {!row.image ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {row.merchantId.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              {row.image !== null ? (
                <img
                  src={row.image}
                  alt=""
                  className="rounded-circle avatar-xs"
                />
              ) : (
                ``
              )}
            </div>
          )}
        </>
      ),
    },
    {
      name: "Package Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{singlePackageData?.name}</h5>
      ),
      sortable: true,
    },
    {
      name: "Merchant Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.User.name}</h5>
      ),
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.createdAt)}
        </h5>
      ),
    },
    {
      name: "Updated At",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.updatedAt)}
        </h5>
      ),
    },

    {
      name: "Request Details",
      cell: (row) => (
        <Link
          className="text-success"
          to={`/package/request/details/${row.id}`}
        >
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    fetchSinglePackage();
  }, []);

  const fetchSinglePackage = async () => {
    const body = { token: token, id: id };
    axios
      .post(`${API_URL}admin/package/details`, body)
      .then((response) => {
        setSinglePackageData(response?.data.data);
        setRequestsData(response?.data.data.PackageRequests);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const approvePackage = () => {
    const body = { token: localStorage.getItem("authUser"), id: id };
    axios
      .post(`${API_URL}admin/package/aprove`, body)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Package Details | Swaplater</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          // title={props.t("Categories")}
          // breadcrumbItem={props.t("Categories")}
          title="Packages"
          breadcrumbItem="Package Details"
        />

        <Row>
          <Col lg="4">
            <Card>
              <CardBody>
                <Row
                  className="task-dates"
                  style={{
                    textAlign: "left",
                  }}
                >
                  <Col sm="12">
                    <div className="mt-4">
                      <h5 className="font-size-14">
                        <i className="bx bx-calendar me-1 text-primary" />
                        Date of Creation
                      </h5>
                      <p className="text-muted mb-0">
                        {handleValidDate(singlePackageData.createdAt)}
                      </p>
                    </div>
                  </Col>

                  <Col sm="12">
                    <div className="mt-4">
                      <h5 className="font-size-14">
                        <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                        Valid To
                      </h5>
                      <p className="text-muted mb-0">
                        {handleValidDate(singlePackageData.validto)}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Col className="mt-4">
                  {" "}
                  <span className="font-size-16 text-dark">
                    Is Package Approved{" "}
                  </span>{" "}
                  <input
                    className="form-check-input mt-1"
                    type="checkbox"
                    value={singlePackageData?.isAproved}
                    id="flexCheckDefault"
                    defaultChecked={singlePackageData?.isAproved}
                    onClick={approvePackage}
                    disabled={
                      singlePackageData?.isAproved === true ? true : false
                    }
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8">
            <Card>
              <div>
                <Row>
                  <Col lg="9" sm="8">
                    <div className="p-4">
                      <h5 className="text-primary">
                        {" "}
                        {singlePackageData.name}
                      </h5>
                      <p>{singlePackageData.description}</p>

                      <div className="text-muted">
                        <p className="mb-1">
                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                          Name Arabic: {singlePackageData?.nameAr}
                        </p>
                        <p className="mb-1">
                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                          Description: {singlePackageData?.description}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                          Arabic Description: {singlePackageData?.descriptionAr}
                        </p>
                        <p className="mb-1">
                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                          Amount: {singlePackageData?.amount}
                        </p>
                        <p className="mb-1">
                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                          Point: {singlePackageData?.points}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                          Type: {singlePackageData?.type}
                        </p>
                        <p className="mb-1">
                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                          Is Approved:{" "}
                          {singlePackageData?.isAproved === true
                            ? `Approved`
                            : `Pending Approval`}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                          Is Approved:{" "}
                          {singlePackageData?.isAproved === true
                            ? `Approved`
                            : `Not Approved`}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="3" sm="4" className="align-self-center">
                    <div>
                      <img
                        src={features}
                        alt=""
                        className="img-fluid d-block"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <CardTitle>Package Requests</CardTitle>
            {requestsData?.length !== 0 ? (
              <Grid data={requestsData} columns={columns} />
            ) : (
              <EmptyTable />
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
PackageDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(PackageDetails);
