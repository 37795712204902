import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import Select from "react-select";
import { map } from "lodash";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  Row,
  ModalBody,
} from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import EmptyTable from "../../components/Common/EmptyTable";

import DeleteModal from "../../components/Common/DeleteModal";
import OffersNewAndEditModal from "./OffersNewAndEditModal";
import Grid from "components/Common/Grid";

const Offers = ({ merchant, merchantBlocked }) => {
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [showOffers, setShowOffers] = useState(false);

  const [merchantOptions, setMerchantOptions] = useState([]);

  const [offers, setOffers] = useState([]);
  const [offer, setOffer] = useState({});

  const [existedTags, setExistedTags] = useState({});

  const [offerTotalRows, setOfferTotalRows] = useState(0);
  const [merchantTotalRows, setMerchantTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [imagesModal, setImagesModal] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const fileInputRef = useRef();

  useEffect(() => {
    if (merchant !== undefined) {
      fetchOffers({ merchantId: merchant, pageNumber: 1, limit });
      fetchMerchants({ pagenumber: 1, limit });
      fetchMerchantOptions();
    } else {
      fetchMerchants({ pagenumber: 1, limit });
      fetchMerchantOptions();
    }
  }, [searchKey]);

  const fetchMerchants = async ({ pagenumber, limit }) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber || 1,
      limit: limit || 10,
    };

    axios
      .post(`${API_URL}admin/merchant/list`, body)
      .then((response) => {
        setMerchants(response?.data.data.data.rows);
        setMerchantTotalRows(response?.data.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMerchantOptions = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/merchant/list`, body)
      .then((response) => {
        const merchantsBeforeFilter = response?.data.data.data.rows;
        const filteredMerchantsToValueAndLabel = merchantsBeforeFilter?.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setMerchantOptions(filteredMerchantsToValueAndLabel);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchOffers = async ({ merchantId, pagenumber, limit }) => {
    const body = {
      token: localStorage.getItem("authUser"),
      merchantId: merchantId,
      pageNum: pagenumber || 1,
      limit: limit || 10,
    };

    axios
      .post(`${API_URL}admin/merchant/offer/list`, body)
      .then((response) => {
        setOffers(response?.data.data.rows);
        setOfferTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const merchantColumns = [
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.mobile}</h5>
      ),
      sortable: true,
    },

    {
      name: "Balance",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.balance}</h5>
      ),
      sortable: true,
    },
    {
      name: "Show Offers",
      cell: (row) => (
        <Button onClick={() => handleShowOffers(row.id)}>show Offers</Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  const handleMerchantPageChange = (page) => {
    fetchMerchants({ pagenumber: page, limit });
  };

  const handleMerchantPerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchMerchants({
      pagenumber: page,
      limit: newPerPage,
    });
  };

  const handleOfferPageChange = (page) => {
    fetchOffers({
      merchantId:
        selectedMerchant &&
        Object.keys(selectedMerchant).length === 0 &&
        Object.getPrototypeOf(selectedMerchant) === Object.prototype
          ? merchant
          : selectedMerchant,
      pagenumber: page,
      limit,
    });
  };

  const handleOfferPerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchOffers({
      merchantId: selectedMerchant,
      pagenumber: page,
      limit: newPerPage,
    });
  };

  const columns = [
    {
      name: "Merchant Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark text-truncate">
          {/* should be merchant name */}
          {row?.merchant?.name}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.title}</h5>
      ),
      sortable: true,
    },
    {
      name: "Points",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.points}</h5>
      ),
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.price}</h5>
      ),
      sortable: true,
    },
    {
      name: "Start Date",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row?.startDate)}
        </h5>
      ),
    },
    {
      name: "End Date",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row?.endDate)}
        </h5>
      ),
    },
    {
      name: "Disabled",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.isDisabled ? "True" : "False"}
        </h5>
      ),
    },
    {
      name: "edit",
      cell: (row) => (
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => handleEditOffer(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <Link className="text-danger" to="#">
          <i
            className="mdi mdi-delete font-size-18"
            id="deletetooltip"
            onClick={() => onClickOfferDelete(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Offer Details",
      cell: (row) => (
        <Link className="text-success" to={`/offer/details/${row.id}`}>
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditOffer = (item) => {
    setModal(true);
    setModalValue("edit");
    setOffer(item);

    let alreadyExistedTags = item.Tags.filter(
      (tag) =>
        Object.keys(tag.OffersTags).length !== 0 &&
        tag.OffersTags.offerId === item.id
    ).map((tag) => ({
      label: tag.name,
      value: tag.id,
    }));
    setExistedTags(alreadyExistedTags);
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const onClickOfferDelete = (item) => {
    setOffer(item);
    setDeleteModal(true);
  };

  const handleDeleteOffer = (item) => {
    const body = {
      token: localStorage.getItem("authUser"),
      offerId: item.id,
    };
    axios
      .post(`${API_URL}admin/merchant/offer/delete`, body)
      .then(() => {
        fetchOffers({
          merchantId: item.merchant.id,
          pageNumber: 1,
          limit: 10,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    setDeleteModal(false);
  };

  const handleShowOffers = (item) => {
    setSelectedMerchant(item);
    setShowOffers(true);
    fetchOffers({ merchantId: item, pageNumber: 1, limit });
  };

  const handleResetPage = () => {
    setShowOffers(false);
    fetchMerchants({ pagenumber: 1, limit });
  };

  return (
    <React.Fragment>
      <Modal isOpen={imagesModal} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i
                  className="mdi mdi-alert-circle-outline"
                  style={{ fontSize: "9em", color: "orange" }}
                />
                <h2>The maximum number of images is 5</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mt-3">
                <button
                  type="button"
                  className="btn btn-danger btn-lg ms-2"
                  onClick={() => setImagesModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOffer}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={offer}
      />

      <OffersNewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        selectedOffer={modalValue === "new" ? {} : offer}
        merchantOptions={merchantOptions}
        fetchOffers={fetchOffers}
        existedTags={existedTags}
      />

      <MetaTags>
        <title>offers | Swaplater</title>
      </MetaTags>

      {merchant !== undefined ? (
        offers?.length !== 0 ? (
          <>
            <Col>
              <div className="text-sm-end mb-2">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={addNew}
                  disabled={merchantBlocked ? true : false}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New Offer
                </Button>
              </div>
            </Col>
            <Grid
              data={offers}
              columns={columns}
              totalRows={offerTotalRows}
              limit={limit}
              handlePageChange={handleOfferPageChange}
              handlePerRowsChange={handleOfferPerRowsChange}
            />
          </>
        ) : (
          <>
            <Col>
              <div className="text-sm-end mb-2">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={addNew}
                  disabled={merchantBlocked ? true : false}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New Offer
                </Button>
              </div>
            </Col>
            <Card>
              <CardBody>
                <CardTitle>Offers</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          </>
        )
      ) : (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              // title={props.t("offers")}
              // breadcrumbItem={props.t("offers")}
              title="Offers"
              breadcrumbItem="Offers list"
            />

            <Col>
              <div className="text-sm-end mb-2">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={addNew}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New Offer
                </Button>
              </div>
            </Col>

            {showOffers ? (
              <>
                <Button
                  onClick={handleResetPage}
                  className="mb-4"
                  color="danger"
                >
                  Choose another Merchant
                </Button>

                {/* <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <DebounceInput
                            type="text"
                            style={{
                              borderRadius: `30px`,
                              border: `1px solid gray`,
                              width: `400px`,
                              padding: `10px`,
                            }}
                            debounceTimeout={1000}
                            onChange={(e) => {
                              setSearchKey(e.target.value);
                            }}
                            placeholder="Search Offers.."
                          />
                        </div>
                      </div>
                    </Col> */}

                {offers?.length !== 0 ? (
                  <>
                    <Grid
                      data={offers}
                      columns={columns}
                      totalRows={offerTotalRows}
                      limit={limit}
                      handlePageChange={handleOfferPageChange}
                      handlePerRowsChange={handleOfferPerRowsChange}
                    />
                  </>
                ) : (
                  <Card>
                    <CardBody>
                      <CardTitle>Offers</CardTitle>
                      <EmptyTable />
                    </CardBody>
                  </Card>
                )}
              </>
            ) : (
              <>
                {" "}
                {merchants?.length !== 0 ? (
                  <Grid
                    data={merchants}
                    columns={merchantColumns}
                    totalRows={merchantTotalRows}
                    limit={limit}
                    handlePageChange={handleMerchantPageChange}
                    handlePerRowsChange={handleMerchantPerRowsChange}
                  />
                ) : (
                  ``
                )}
              </>
            )}
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

Offers.propTypes = {
  t: PropTypes.any,
  merchant: PropTypes.any,
  merchantBlocked: PropTypes.any,
};

export default withTranslation()(Offers);
