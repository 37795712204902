import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Table,
  Col,
  Button,
  Row,
  Modal,
  ModalBody,
  Label,
  ModalHeader,
} from "reactstrap";
import EmptyTable from "../../components/Common/EmptyTable";
import moment from "moment";
import axios from "axios";
import PlanCompanies from "pages/PlanCompanies";

const PlanDetails = () => {
  const [plan, setPlan] = useState({});
  const [modal, setModal] = useState(false);

  const [companyOptions, setCompanyOptions] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchSinglePlan();
    fetchCompanies();
  }, []);

  const fetchSinglePlan = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: id,
    };

    axios
      .post(`${API_URL}admin/plan/details`, body)
      .then((response) => {
        setPlan(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCompanies = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/company/list`, body)
      .then((response) => {
        const companiesBeforeFilter = response?.data.data.rows;
        const filteredCompaniesToValueAndLabel = companiesBeforeFilter?.map(
          (item) => ({
            value: item.id,
            label: item.nameEn,
          })
        );
        setCompanyOptions(filteredCompaniesToValueAndLabel);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const assignPlanToCompanies = () => {
    const body = {
      token: localStorage.getItem("authUser"),
      planId: id,
      companyIds: JSON.stringify(selectedCompanies),
    };
    axios
      .post(`${API_URL}admin/plan/assignCompany`, body)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCompanyChange = (selectedOption) => {
    // const ids = selectedOption.map((item) => {
    //   return item.value;
    // });
    setSelectedCompanies(selectedOption.value);
    // setSelectedCompanies(ids);
  };

  return (
    <React.Fragment>
      <Modal isOpen={modal} centered={true}>
        <ModalHeader tag="h4">Assign Companies to {plan.name} plan</ModalHeader>
        <ModalBody className="py-3 px-5">
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Companies</Label>
                <Select
                  options={companyOptions}
                  onChange={handleCompanyChange}
                  placeholder="Select Companies"
                  // isMulti
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={() => setModal(false)}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-success save-user"
                  onClick={assignPlanToCompanies}
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <MetaTags>
          <title>Plan Details| Swaplater</title>{" "}
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Plan" breadcrumbItem="Plan Details" />

          <Card>
            <CardBody>
              <CardTitle className="mb-4">{plan?.name} Details</CardTitle>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Name :</th>
                      <td>{plan?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Months Duration :</th>
                      <td>{plan?.monthsDuration}</td>
                    </tr>
                    <tr>
                      <th scope="row">Creation Date :</th>
                      <td>{handleValidDate(plan?.createdAt)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          {plan?.id ? (
            <>
              <Col>
                <div className="text-sm-end mb-2">
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={() => setModal(true)}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Assign Companies to this Plan
                  </Button>
                </div>
              </Col>
              <PlanCompanies planId={plan?.id} />
            </>
          ) : (
            <Card>
              <CardBody>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
PlanDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(PlanDetails);
