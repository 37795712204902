import PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const CompanyNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  selectedCompany,
  fetchCompanies,
}) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nameEn: (selectedCompany && selectedCompany.nameEn) || "",
      nameAr: (selectedCompany && selectedCompany.nameAr) || "",
      addressEn: (selectedCompany && selectedCompany.addressEn) || "",
      addressAr: (selectedCompany && selectedCompany.addressAr) || "",
      phone: (selectedCompany && selectedCompany.phone) || "",
    },
    validationSchema: Yup.object({
      nameEn: Yup.string().required("Please enter company english name"),
      nameAr: Yup.string().required("Please Enter company arabic name"),
      addressEn: Yup.string().required("address English is required"),
      addressAr: Yup.string().required("address arabic is required"),
      phone: Yup.string()
        .required("phone is required")
        .matches(/^[0-9]+$/, "phone must be a valid number"),
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        const body = {
          token: localStorage.getItem("authUser"),
          companyId: selectedCompany.id,
          nameEn: values.nameEn,
          nameAr: values.nameAr,
          addressEn: values.addressEn,
          addressAr: values.addressAr,
          phone: values.phone,
        };

        axios
          .post(`${API_URL}admin/company/update`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchCompanies(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
        validation.resetForm();
        setShowModal(false);
      } else {
        const body = {
          token: localStorage.getItem("authUser"),
          nameEn: values["nameEn"],
          nameAr: values["nameAr"],
          addressEn: values["addressEn"],
          addressAr: values["addressAr"],
          phone: values["phone"],
        };

        axios
          .post(`${API_URL}admin/company/create`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchCompanies(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setShowModal(false);
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit company" : "Add company"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">English Name</Label>
                <Input
                  name="nameEn"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameEn || ""}
                  invalid={
                    validation.touched.nameEn && validation.errors.nameEn
                      ? true
                      : false
                  }
                />
                {validation.touched.nameEn && validation.errors.nameEn ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameEn}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Arabic Name</Label>
                <Input
                  name="nameAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameAr || ""}
                  invalid={
                    validation.touched.nameAr && validation.errors.nameAr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameAr && validation.errors.nameAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">English Address</Label>
                <Input
                  name="addressEn"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.addressEn || ""}
                  invalid={
                    validation.touched.addressEn && validation.errors.addressEn
                      ? true
                      : false
                  }
                />
                {validation.touched.addressEn && validation.errors.addressEn ? (
                  <FormFeedback type="invalid">
                    {validation.errors.addressEn}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Arabic address</Label>
                <Input
                  name="addressAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.addressAr || ""}
                  invalid={
                    validation.touched.addressAr && validation.errors.addressAr
                      ? true
                      : false
                  }
                />
                {validation.touched.addressAr && validation.errors.addressAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.addressAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Phone</Label>
                <Input
                  name="phone"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

CompanyNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  selectedCompany: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchCompanies: PropTypes.func,
};

export default CompanyNewAndEditModal;
