import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Button, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import { DebounceInput } from "react-debounce-input";
import DeleteModal from "../../components/Common/DeleteModal";
import AdminNewAndEditModal from "./AdminNewAndEditModal/index";
import Grid from "components/Common/Grid";

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [admin, setAdmin] = useState({});

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchAdmins();
  }, [searchKey]);

  const fetchAdmins = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit || 10,
      name: searchKey,
    };

    axios
      .post(`${API_URL}admin/list`, body)
      .then((response) => {
        setAdmins(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchAdmins(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchAdmins(page, newPerPage);
  };

  const adminActiveToggle = (item) => {
    const body = {
      token: localStorage.getItem("authUser"),
      adminId: item.id,
      active: item.isAproved === true ? `false` : `true`,
    };

    axios
      .post(`${API_URL}admin/active`, body)
      .then(() => {
        fetchAdmins(1, 10);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle">
            {row.name.charAt(0)}
          </span>
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.mobile}</h5>
      ),
      sortable: true,
    },
    {
      name: "is Active",
      cell: (row) => (
        <>
          <input
            className="form-check-input mt-1"
            type="checkbox"
            value={row?.isAproved}
            id="flexCheckDefault"
            defaultChecked={row?.isAproved === false ? false : true}
            onClick={() => adminActiveToggle(row)}
          />
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <Link className="text-danger" to="#">
          <i
            className="mdi mdi-delete font-size-18"
            id="deletetooltip"
            onClick={() => onClickAdminDelete(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "edit",
      cell: (row) => (
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => handleEditAdmin(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditAdmin = (item) => {
    setModal(true);
    setModalValue("edit");
    setAdmin(item);
  };

  const onClickAdminDelete = (item) => {
    setAdmin(item);
    setDeleteModal(true);
  };

  const handleDeleteUser = (item) => {
    const body = {
      token: localStorage.getItem("authUser"),
      adminId: item.id,
    };
    axios
      .post(`${API_URL}admin/delete`, body)
      .then(() => {
        fetchAdmins(1, 10);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={admin}
      />

      <AdminNewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        admin={modalValue === "new" ? {} : admin}
        fetchAdmins={fetchAdmins}
      />

      <div className="page-content">
        <MetaTags>
          <title>Admins | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Admins")}
            // breadcrumbItem={props.t("Admins")}
            title="Admins"
            breadcrumbItem="Admins"
          />

          <Col>
            <div className="text-sm-end mb-2">
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={addNew}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Admin
              </Button>
            </div>
          </Col>

          <Col sm="4">
            <div className="search-box ms-2 mb-2 d-inline-block">
              <div className="position-relative">
                <DebounceInput
                  type="text"
                  style={{
                    borderRadius: `30px`,
                    border: `1px solid gray`,
                    width: `400px`,
                    padding: `10px`,
                  }}
                  debounceTimeout={1000}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search Admins.."
                />
              </div>
            </div>
          </Col>

          {admins?.length !== 0 ? (
            <>
              <Grid
                data={admins}
                columns={columns}
                totalRows={totalRows}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            ``
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

Admins.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Admins);
