import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EmptyTable from "../../components/Common/EmptyTable";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { DebounceInput } from "react-debounce-input";
import CardContent from "../../components/Card";
import DeleteModal from "../../components/Common/DeleteModal";
import MerchantNewModal from "./MerchantNewModal";
import Grid from "components/Common/Grid";

const Merchants = () => {
  const [merchants, setMerchants] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [merchant, setMerchant] = useState({});

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [branchesCount, setBranchesCount] = useState(0);
  const [offersCount, setOffersCount] = useState(0);
  const [employeesCount, setEmployeesCount] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  const reports = [
    {
      title: "Merchants",
      iconClass: "bx-copy-alt",
      description: `${totalRows}`,
    },
    {
      title: "Branches",
      iconClass: "bx-archive-in",
      description: `${branchesCount}`,
    },
    {
      title: "Offers",
      iconClass: "bx-archive-in",
      description: `${offersCount}`,
    },
    {
      title: "Employees",
      iconClass: "bx-archive-in",
      description: `${employeesCount}`,
    },
  ];

  useEffect(() => {
    fetchMerchants();
  }, [searchKey]);

  const fetchMerchants = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      name: searchKey,
    };

    axios
      .post(`${API_URL}admin/merchant/list`, body)
      .then((response) => {
        setMerchants(response?.data.data.data.rows);
        setTotalRows(response?.data.data.data.count);
        setBranchesCount(response?.data.data.branchesCount);
        setOffersCount(response?.data.data.offersCount);
        setEmployeesCount(response?.data.data.employeesCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchMerchants(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchMerchants(page, newPerPage);
  };

  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <>
          {!row.image ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {row.name.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              {row.image !== null ? (
                <img
                  src={row.image}
                  alt=""
                  className="rounded-circle avatar-xs"
                />
              ) : (
                ``
              )}
            </div>
          )}
        </>
      ),
    },
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">
        {row.name} {row.isBlocked? <span>&#128683;</span> : ""}
      </h5>,
      sortable: true,
    },

    {
      name: "Mobile",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.mobile}</h5>
      ),
      sortable: true,
    },
    // {
    //   name: "Currency",
    //   selector: (row) => (
    //     <h5 className="font-size-12 text-dark">{row.userCurrncy}</h5>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Balance",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.balance}</h5>
      ),
      sortable: true,
    },
    // {
    //   name: "ID",
    //   selector: (row) => (
    //     <h5 className="font-size-12 text-dark">{row.id}</h5>
    //     ),
    //   sortable: true,
    // },
    {
      name: "Delete",
      cell: (row) => (
        <Link className="text-danger" to="#">
          <i
            className="mdi mdi-delete font-size-18"
            id="deletetooltip"
            onClick={() => onClickMerchantDelete(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    // {
    //   name: "edit",
    //   cell: (row) => (
    //     <Link className="text-success" to="#">
    //       <i
    //         className="mdi mdi-pencil font-size-18"
    //         id="edittooltip"
    //         onClick={() => handleEditMerchant(row)}
    //       ></i>
    //     </Link>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
    {
      name: "Merchant Details",
      cell: (row) => (
        <Link className="text-success" to={`/merchant/details/${row.id}`}>
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleEditMerchant = (item) => {
    setModal(true);
    setModalValue("edit");
    setMerchant(item);
  };

  const onClickMerchantDelete = (item) => {
    setMerchant(item);
    setDeleteModal(true);
  };

  const handleDeleteUser = (item) => {
    const body = {
      token: localStorage.getItem("authUser"),
      merchantId: item.id,
    };
    axios
      .post(`${API_URL}admin/merchant/delete`, body)
      .then(() => {
        fetchMerchants(1, 10);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={merchant}
      />

      <div className="page-content">
        <MetaTags>
          <title>Merchants | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Merchants")}
            // breadcrumbItem={props.t("Merchants")}
            title="Merchants"
            breadcrumbItem="Merchants"
          />

          <Row>
            <Col lg="12">
              <CardContent reports={reports} numbers={3} />
            </Col>
          </Row>

          <Col sm="4">
            <div className="search-box ms-2 mb-2 d-inline-block">
              <div className="position-relative">
                <DebounceInput
                  type="text"
                  style={{
                    borderRadius: `30px`,
                    border: `1px solid gray`,
                    width: `400px`,
                    padding: `10px`,
                  }}
                  debounceTimeout={1000}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search English Name.."
                />
              </div>
            </div>
          </Col>

          {merchants?.length !== 0 ? (
            <>
              <Grid
                data={merchants}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Merchants</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Merchants.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Merchants);
