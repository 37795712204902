import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Button, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { DebounceInput } from "react-debounce-input";
import EmptyTable from "../../components/Common/EmptyTable";
// import { userService } from "services/user.service";
import DeleteModal from "../../components/Common/DeleteModal";
import UserNewAndEditModal from "./UserNewAndEditModal";
import Grid from "../../components/Common/Grid";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [user, setUser] = useState({});

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchUsers();
  }, [searchKey]);

  const fetchUsers = async (pagenumber, limit) => {
    const body = {
      token: localStorage.getItem("authUser"),
      pageNum: pagenumber,
      limit: limit,
      name: searchKey,
    };
    axios
      .post(`${API_URL}admin/users/list`, body)
      .then((response) => {
        setUsers(response?.data.data.rows);
        setTotalRows(response?.data.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchUsers(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchUsers(page, newPerPage);
  };

  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <>
          {!row.image ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {row.name.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              {row.image !== null ? (
                <img
                  src={row.image}
                  alt=""
                  className="rounded-circle avatar-xs"
                />
              ) : (
                ``
              )}
            </div>
          )}
        </>
      ),
    },
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.rule}</h5>,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.mobile}</h5>
      ),
      sortable: true,
    },
    {
      name: "Balance",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.balance}</h5>
      ),
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.createdAt)}
        </h5>
      ),
    },
    {
      name: "Updated At",
      cell: (row) => (
        <h5 className="font-size-12 text-dark">
          {handleValidDate(row.updatedAt)}
        </h5>
      ),
    },
    {
      name: "edit",
      cell: (row) => (
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => handleEditUser(row)}
          ></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "User Details",
      cell: (row) => (
        <Link className="text-success" to={`/user/details/${row.id}`}>
          <Button>Details</Button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditUser = (item) => {
    setModal(true);
    setModalValue("edit");
    setUser(item);
  };

  const onClickUserDelete = (item) => {
    setUser(item);
    setDeleteModal(true);
  };

  const handleDeleteUser = (item) => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: item.id,
    };
    axios
      .post(`${API_URL}admin/catigory/delete`, body)
      .then(() => {
        fetchUsers(1, 10);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={user}
      />

      <UserNewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        user={modalValue === "new" ? {} : user}
        fetchUsers={fetchUsers}
      />

      <div className="page-content">
        <MetaTags>
          <title>Users | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Users")}
            // breadcrumbItem={props.t("Users")}
            title="Users"
            breadcrumbItem="Users"
          />

          <Col>
            <div className="text-sm-end mb-2">
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={addNew}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>

          <Col sm="4">
            <div className="search-box ms-2 mb-2 d-inline-block">
              <div className="position-relative">
                <DebounceInput
                  type="text"
                  style={{
                    borderRadius: `30px`,
                    border: `1px solid gray`,
                    width: `400px`,
                    padding: `10px`,
                  }}
                  debounceTimeout={1000}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search Name English.."
                />
              </div>
            </div>
          </Col>

          {users?.length !== 0 ? (
            <>
              <Grid
                data={users}
                columns={columns}
                totalRows={totalRows}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Users.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Users);
