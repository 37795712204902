

import GoogleMapReact from "google-map-react";
import { useState, React } from "react";
import  PropTypes  from "prop-types";

export default function MapContainer({values, setValues, moveMarker, width, height, lat , long}) {


  const defaultProps = {
    center: {
      lat: lat || 25,
      lng: long || 51
    },
    zoom: 7
  };

  const changeMarkerPosition = (e) => {
    console.log("e ", e);

    setValues({
      ...values,
      lat: e.lat,
      long: e.lng
    })

  };


  //
  return (

    <div
      className="App"
      style={{
        

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <div style={{ height: height || 400, width: width || 500 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDxsUzIfLpefNwPISG4wYuo_qrrKHuMUKQ" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onClick={(moveMarker !== false) && changeMarkerPosition}
          draggable={true}
        >
          <Marker lat={values?.lat || defaultProps.center.lat} lng={values?.long || defaultProps.center.lng} />
        </GoogleMapReact>
      </div>
      <div style={{ marginTop: 16 }}>
      </div>
    </div>
  );
}

//dsads
const Marker = () => {
  return (
    <div className="SuperAwesomePin">
      <img
        height="25"
        width="25"
        src="https://img.icons8.com/color/48/000000/marker--v1.png"
      />
    </div>
  );
};

//


MapContainer.propTypes = {
  values: PropTypes.any,
  setValues: PropTypes.any,
  moveMarker: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  lat: PropTypes.number,
  long: PropTypes.number,
};