import PropTypes from "prop-types";
import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
} from "reactstrap";
import Select from "react-select";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const RequestEditModal = ({
  show,
  setShowModal,
  onCloseClick,
  request,
  fetchRequests,
}) => {
  const [selectedStatus, setSelectedStatus] = useState("");

  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "refund", label: "Refund" },
  ];

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: (request && request.status) || "",
    },
    validationSchema: Yup.object({}),
    onSubmit: () => {
      const body = {
        token: token,
        id: request.id,
        status: selectedStatus,
      };
      axios
        .post(`${API_URL}admin/req/update`, body, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then(() => {
          fetchRequests();
        })
        .catch((err) => {
          console.log(err);
        });
      validation.resetForm();
      setShowModal(false);
    },
  });

  const handleStatus = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">Edit request</ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Status</Label>
                <Select
                  options={statusOptions}
                  onChange={handleStatus}
                  placeholder="Select"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

RequestEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  request: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchRequests: PropTypes.func,
};

export default RequestEditModal;
