import PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const PackagesNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  selectedPackage,
  fetchPackages,
}) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (selectedPackage && selectedPackage.name) || "",
      nameAr: (selectedPackage && selectedPackage.nameAr) || "",
      description: (selectedPackage && selectedPackage.description) || "",
      descriptionAr: (selectedPackage && selectedPackage.descriptionAr) || "",
      points: (selectedPackage && selectedPackage.points) || "",
      amount: (selectedPackage && selectedPackage.amount) || "",
      type: (selectedPackage && selectedPackage.type) || "",
      validto:
        (selectedPackage &&
          modalValue === "edit" &&
          moment(selectedPackage.validto).format("YYYY-MM-DD")) ||
        "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter package name"),
      nameAr: Yup.string().required("Please Enter package arabic name"),
      description: Yup.string()
        .required("description is required")
        .matches(/^.{30,300}$/, "description must be more than 30 charachters"),
      descriptionAr: Yup.string()
        .required("description is required")
        .matches(
          /^.{30,300}$/,
          "description Arabic must be more than 30 charachters"
        ),
      points: Yup.string().required("Please enter package points"),
      amount: Yup.string().required("Please enter package amount"),
      type: Yup.string().required("Please select Your Type"),
      validto: Yup.date().required("validto should be a valid Date"),
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        const body = {
          token: localStorage.getItem("authUser"),
          packageId: selectedPackage.id,
          name: values.name,
          nameAr: values.nameAr,
          description: values.description,
          descriptionAr: values.descriptionAr,
          points: values.points,
          amount: values.amount,
          type: values.type,
          validto: values.validto,
        };

        axios
          .post(`${API_URL}admin/package/update`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchPackages(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
        validation.resetForm();
        setShowModal(false);
      } else {
        const body = {
          token: localStorage.getItem("authUser"),
          name: values["name"],
          nameAr: values["nameAr"],
          description: values["description"],
          descriptionAr: values["descriptionAr"],
          points: values["points"],
          amount: values["amount"],
          type: values["type"],
          validto: values["validto"],
        };

        axios
          .post(`${API_URL}admin/package/create`, body, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then(() => {
            fetchPackages(1, 10);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setShowModal(false);
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit Package" : "Add Package"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Name Arabic</Label>
                <Input
                  name="nameAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameAr || ""}
                  invalid={
                    validation.touched.nameAr && validation.errors.nameAr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameAr && validation.errors.nameAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                  name="description"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Description Arabic</Label>
                <Input
                  name="descriptionAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.descriptionAr || ""}
                  invalid={
                    validation.touched.descriptionAr &&
                    validation.errors.descriptionAr
                      ? true
                      : false
                  }
                />
                {validation.touched.descriptionAr &&
                validation.errors.descriptionAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.descriptionAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Amount</Label>
                <Input
                  name="amount"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.amount || ""}
                  invalid={
                    validation.touched.amount && validation.errors.amount
                      ? true
                      : false
                  }
                />
                {validation.touched.amount && validation.errors.amount ? (
                  <FormFeedback type="invalid">
                    {validation.errors.amount}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Points</Label>
                <Input
                  name="points"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.points || ""}
                  invalid={
                    validation.touched.points && validation.errors.points
                      ? true
                      : false
                  }
                />
                {validation.touched.points && validation.errors.points ? (
                  <FormFeedback type="invalid">
                    {validation.errors.points}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Type</Label>
                <Input
                  name="type"
                  type="select"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.type || ""}
                >
                  <option></option>
                  <option>pay</option>
                </Input>
                {validation.touched.type && validation.errors.type ? (
                  <FormFeedback type="invalid">
                    {validation.errors.type}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Valid to</Label>
                <Input
                  name="validto"
                  type="date"
                  format="YYYY/MM/DD"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.validto && validation.errors.validto
                      ? true
                      : false
                  }
                  value={validation.values.validto || ""}
                ></Input>
                {validation.touched.validto && validation.errors.validto ? (
                  <FormFeedback type="invalid">
                    {validation.errors.validto}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

PackagesNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  selectedPackage: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchPackages: PropTypes.func,
};

export default PackagesNewAndEditModal;
