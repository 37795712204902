import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
    Container,
    Card,
    CardBody,
    CardTitle,
    Button,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink, CardText, Row
} from "reactstrap";
import classnames from 'classnames';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EmptyTable from "../../components/Common/EmptyTable";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import axios from "axios";
// import TicketCreateProfile from "./TicketCreateProfile/index";
// import TicketNewModa from "pages/Tickets/TicketNewModal";
import Grid from "components/Common/Grid";
import CloseTicketModal from "../../components/Common/CloseTicketModal";


const Tickets = () => {
    const [totalRows, setTotalRows] = useState(0);
    const [totalRowsOpen, setTotalRowsOpen] = useState(0);
    const [totalRowsClosed, setTotalRowsClosed] = useState(0);
    const [totalRowsCompleted, setTotalRowsCompleted] = useState(0);
    const [Tickets, setTickets] = useState([]);
    const [OpenedTickets, setOpenedTickets] = useState([]);
    const [ClosedTickets, setClosedTickets] = useState([]);
    const [CompletedTickets, setCompletedTickets] = useState([]);
    const [selectedTicket, setselectedTicket] = useState([]);
    const [limit, setLimit] = useState(10);
    const [editModal, setEditModal] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [searchKeyTicketNumber, setSearchKeyTicketNumber] = useState("");




    const API_URL = process.env.REACT_APP_API_URL;


    // State for current active Tab
    const [currentActiveTab, setCurrentActiveTab] = useState('1');

    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }


    useEffect(() => {
        fetchTickets();
    }, [searchKey, searchKeyTicketNumber]);


    const fetchTickets = async (pagenumber, limit) => {
        const body = {
            token: localStorage.getItem("authUser"),
            pageNum: pagenumber || 1,
            limit: limit || 10,
            userSearch: searchKey,
            ticketNumber: searchKeyTicketNumber,
        };
        const bodyOpen = {
            token: localStorage.getItem("authUser"),
            pageNum: pagenumber || 1,
            limit: limit || 10,
            filter: "open",
            userSearch: searchKey,
            ticketNumber: searchKeyTicketNumber,
        };
        const bodyClosed = {
            token: localStorage.getItem("authUser"),
            pageNum: pagenumber || 1,
            limit: limit || 10,
            filter: "closed",
            userSearch: searchKey,
            ticketNumber: searchKeyTicketNumber,
        };
        const bodyCompleted = {
            token: localStorage.getItem("authUser"),
            pageNum: pagenumber || 1,
            limit: limit || 10,
            filter: "completed",
            userSearch: searchKey,
            ticketNumber: searchKeyTicketNumber,
        };

        axios
            .post(`${API_URL}admin/ticket/list`, body)
            .then((response) => {
                setTickets(response?.data.data.rows);

                // setOpenedTickets(response?.data.data.rows.filter(function(item){
                //     return item.isClosed === false && item.isComplete === false;         
                // }));

                setTotalRows(response?.data.data.count);
          
            })
            .catch((err) => {
                console.log(err);
            });

            axios            
            .post(`${API_URL}admin/ticket/list`, bodyOpen)
            .then((response) => {
                setOpenedTickets(response?.data.data.rows);
                setTotalRowsOpen(response?.data.data.count);
            })
            .catch((err) => {
                console.log(err);
            });

            axios            
            .post(`${API_URL}admin/ticket/list`, bodyClosed)
            .then((response) => {
                setClosedTickets(response?.data.data.rows);
                setTotalRowsClosed(response?.data.data.count);
            })
            .catch((err) => {
                console.log(err);
            });

            axios            
            .post(`${API_URL}admin/ticket/list`, bodyCompleted)
            .then((response) => {
                setCompletedTickets(response?.data.data.rows);
                setTotalRowsCompleted(response?.data.data.count);
            })
            .catch((err) => {
                console.log(err);
            });

    };



    const handlePageChange = (page) => {
        fetchTickets(page, limit);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLimit(newPerPage);
        fetchTickets(page, newPerPage);
    };


    const TicketsColumns = [
        {
            name: "Name",
            selector: (row) => <h5 className="font-size-12 text-dark">{row.User.name}</h5>,
            sortable: true,
        },
        {
            name: "Portal Type",
            selector: (row) => (
                <h5 className="font-size-12 text-dark">{row.portalType}</h5>
            ),
            sortable: true,
        },
        {
            name: "Ticket Type",
            selector: (row) => (
                <h5 className="font-size-12 text-dark">{row.ticketType}</h5>
            ),
            sortable: true,
        },
        // {
        //     name: "Status",
        //     selector: (row) => (
        //         <h5 className="font-size-12 text-dark">{row.status}</h5>
        //     ),
        //     sortable: true,
        // },


        {
            name: "Created At",
            selector: (row) => (
                <h5 className="font-size-12 text-dark">{
                    // new Date(row.createdAt).toLocaleDateString()
                    // new Date(row.createdAt).toLocaleString()
                    new Date(`${row.createdAt}`).toLocaleDateString(
                        'en-us',
                        {
                           year: 'numeric',
                           month: 'short',
                           day: 'numeric',
                        }
                     )
                }</h5>

            ),
            sortable: true,
        },

        {
            name: "Closed",

            selector: (row) => (
                <div>
                    <h5 className="font-size-12 text-dark">
                        {row.isClosed ? <span style={{ fontSize: "21px" }}>&nbsp;&#9989;</span> : <span>&nbsp; &nbsp; &#10060;</span>}
                    </h5>

                </div>

            ),
            sortable: true,
        },

        {
            name: "Completed",

            selector: (row) => (
                <div>
                    <h5 className="font-size-12 text-dark">
                        {row.isComplete ? <span style={{ fontSize: "21px" }}>&nbsp;&#9989;</span> : <span>&nbsp; &nbsp; &#10060;</span>}
                    </h5>

                </div>

            ),
            sortable: true,
        },


        {
            name: "Details",
            cell: (row) => (
                <Link
                    className="text-success"
                    to={`/ticket/details/${row.id}`}
                >
                    <Button>Details</Button>
                </Link>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },


    ];

    // Edit Tickets

    const onClickTicketEdit = (item) => {
        setselectedTicket(item);
        setEditModal(true);
    };

    const handleEditTicket = (item) => {
        const body = {
            token: localStorage.getItem("authUser"),
            id: item.id,
        };
        axios
            .post(`${API_URL}admin/ticket/close`, body)
            .then(() => {
                fetchtickets(1, limit);
            })
            .catch((err) => {
                console.log(err);
            });
        setEditModal(false);
    };




    if (!Tickets) return (
        <div className="page-content">
            <MetaTags>
                <title>Tickets | Swaplater</title>
            </MetaTags>

            <Container fluid>
                <Breadcrumbs

                    title="Tickets"
                    breadcrumbItem="Users Tickets"
                />
                <Card>
                    <CardBody>
                        <CardTitle><h1>No Tickets In The List</h1></CardTitle>

                        {/* <EmptyTable /> */}
                    </CardBody>
                </Card>

            </Container>
        </div>
    )


    return (
        <React.Fragment>

            <CloseTicketModal
                show={editModal}
                onSavedClick={handleEditTicket}
                onCloseClick={() => setEditModal(false)}
                itemToBeSaved={selectedTicket}
            />


            <div className="page-content">
                <MetaTags>
                    <title>Tickets | Swaplater</title>
                </MetaTags>

                <Container fluid>
                    <Breadcrumbs

                        title="Tickets"
                        breadcrumbItem="Users Tickets"
                    />
                    {/* <Col>
                        <div className="text-sm-end mb-2">
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={addNew}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create New Ticket
                            </Button>
                        </div>
                    </Col> */}
          <Col sm="4">
            <div className="search-box ms-2 mb-2 d-inline-block">
              <div className="position-relative">
                <DebounceInput
                  type="text"
                  style={{
                    borderRadius: `30px`,
                    border: `1px solid gray`,
                    width: `400px`,
                    padding: `10px`,
                  }}
                  debounceTimeout={1000}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                    setSearchKeyTicketNumber(e.target.value);
                  }}
                  placeholder="Search Name.."
                />
              </div>
            </div>
          </Col>

                    <div style={{
                        display: 'block', width: "100%", padding: 0
                    }}>

                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '1'
                                    })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    All Tickets
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '2'
                                    })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    Closed Tickets
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '3'
                                    })}
                                    onClick={() => { toggle('3'); }}
                                >
                                    Completed Tickets
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '4'
                                    })}
                                    onClick={() => { toggle('4'); }}
                                >
                                    Open Tickets
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={currentActiveTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">


                                    <Grid

                                    data={Tickets}
                                    // dataTickets={Tickets}
                                    columns={TicketsColumns}
                                    totalRows={totalRows}
                                    limit={limit}
                                    handlePageChange={handlePageChange}
                                    handlePerRowsChange={handlePerRowsChange}
                                    />




                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                    <Grid

                                    data={ClosedTickets}
                                    columns={TicketsColumns}
                                    totalRows={totalRowsClosed}
                                    limit={limit}
                                    handlePageChange={handlePageChange}
                                    handlePerRowsChange={handlePerRowsChange}
                                    />

                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row>
                                    <Col sm="12">
                                    <Grid

                                        data={CompletedTickets}
                                        columns={TicketsColumns}
                                        totalRows={totalRowsCompleted}
                                        limit={limit}
                                        handlePageChange={handlePageChange}
                                        handlePerRowsChange={handlePerRowsChange}
                                        />

                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="4">
                                <Row>
                                    <Col sm="12">

                                        <Grid

                                        data={OpenedTickets}
                                        // dataTickets={Tickets}
                                        columns={TicketsColumns}
                                        totalRows={totalRowsOpen}
                                        limit={limit}
                                        handlePageChange={handlePageChange}
                                        handlePerRowsChange={handlePerRowsChange}
                                        />

                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div >








                </Container>
            </div>
        </React.Fragment>
    );

};
Tickets.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(Tickets);
