import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Card, CardBody, Col, Row, Container, Table } from "reactstrap";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const API_URL = process.env.REACT_APP_API_URL;

const RequestDetails = () => {
  const params = useParams();
  const id = params?.id;

  const [singleRequest, setSingleRequest] = useState([]);

  useEffect(() => {
    fetchSingleRequest();
  }, []);

  const fetchSingleRequest = () => {
    const body = { token: localStorage.getItem("authUser"), reqId: id };
    axios
      .post(`${API_URL}admin/req/details`, body)
      .then((response) => {
        setSingleRequest(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Request Details | Swaplater</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="Request" breadcrumbItem="Request Detail" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="invoice-title">
                  <h4 className="float-end font-size-16">
                    Code # {singleRequest?.code}
                  </h4>
                  <div className="mb-4">Request Details</div>
                </div>
                <hr />
                <Row>
                  <Col xs="6">
                    <address>
                      <strong>Merchant</strong>
                      <br />
                      <>
                        <span>
                          <strong>Name: </strong>
                          {singleRequest?.merchant?.name}
                        </span>
                        <br />
                        <span>
                          <strong>Mobile: </strong>{" "}
                          {singleRequest?.merchant?.mobile}
                        </span>
                        <br />
                        <span>
                          {" "}
                          <strong>Currency: </strong>
                          {singleRequest?.merchant?.userCurrncy}
                        </span>
                        <br />
                        <span>
                          {" "}
                          <strong>Balance: </strong>
                          {singleRequest?.merchant?.balance}
                        </span>
                        <br />
                      </>
                    </address>
                  </Col>
                  <Col xs="6" className="text-end">
                    <address>
                      <strong>Branch</strong>
                      <br />
                      <>
                        <span>
                          <strong>Branch Name: </strong>
                          {singleRequest?.Branch?.branchName}
                        </span>
                        <br />
                        <span>
                          <strong>Mobile: </strong>
                          {singleRequest?.Branch?.phone}
                        </span>
                        <br />
                        <span>
                          <strong>Address: </strong>
                          {singleRequest?.Branch?.address}
                        </span>
                        <br />
                      </>
                    </address>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" className="mt-3">
                    <address>
                      <strong>Offer</strong>
                      <br />
                      <>
                        <span>
                          <strong>Price: </strong>
                          {singleRequest?.offerDetail?.price}
                        </span>
                        <br />
                        <span>
                          <strong>Points: </strong>{" "}
                          {singleRequest?.offerDetail?.points}
                        </span>
                        <br />
                        <span>
                          <strong>Start Date: </strong>
                          {handleValidDate(
                            singleRequest?.offerDetail?.startDate
                          )}
                        </span>
                        <br />
                        <span>
                          {" "}
                          <strong>End Date: </strong>
                          {handleValidDate(singleRequest?.offerDetail?.endDate)}
                        </span>
                        <br />
                      </>
                    </address>
                  </Col>
                  <Col xs="6" className="mt-3 text-end">
                    <address>
                      <strong>Dates</strong>
                      <br />
                      <>
                        <span>
                          <strong>Created At: </strong>
                          {handleValidDate(singleRequest?.createdAt)}
                        </span>
                        <br />
                        <span>
                          <strong>Valid to: </strong>

                          {handleValidDate(singleRequest?.validTo)}
                        </span>
                        <br />
                      </>
                    </address>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

RequestDetails.propTypes = {
  merchantUsers: PropTypes.any,
  columns: PropTypes.any,
};

export default RequestDetails;
