import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";

const TopProducts = ({ products }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Top Selling Product</CardTitle>
          {products &&
            products?.map((item, index) => (
              <div key={item.productId}>
                {index === 0 ? (
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="bx bx-map-pin text-primary display-4" />
                    </div>
                    <h3>{item?.Product.merchant?.name}</h3>
                    <h3>{item?.products_count}</h3>
                    <h6 className="mb-4">{item?.Product?.name}</h6>
                  </div>
                ) : (
                  <div className="table-responsive mt-2">
                    <table className="table align-middle table-nowrap">
                      <tbody>
                        <tr>
                          <td>
                            <h5 className="mb-0">
                              {item?.Product.merchant?.name}
                            </h5>
                          </td>
                          <td style={{ width: "30%" }}>
                            <p className="mb-0">{item?.Product?.name}</p>
                          </td>
                          <td style={{ width: "25%" }}>
                            <h5 className="mb-0">{item?.products_count}</h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ))}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

TopProducts.propTypes = {
  products: PropTypes.any,
};

export default TopProducts;
