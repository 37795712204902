import PropTypes from "prop-types";
import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");
Yup.setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: "field_invalid",
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    default: "must not be string",
    min: ({ min }) => ({ key: "field_too_short", values: { min } }),
    max: ({ max }) => ({ key: "field_too_big", values: { max } }),
  },
});

const MerchantCreateProfile = ({
  show,
  setShowModal,
  onCloseClick,
  merchant,
  fetchPendingMerchants,
  fetchSingleMerchant,
  value,
  categories,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pointPrice: "",
      taxRegistrationNumber: "",
      commissionPercentage: "",
      refundPeriod: "",
      refundRules: "",
      categoryId: "",
      hotLine: "",
      refundPeriod: "",
      refundRules: "",
    },
    validationSchema: Yup.object({
      pointPrice: Yup.string().required("Please Enter merchant point price"),
      hotLine: Yup.string()
        .required("Please Enter merchant hotline")
        .matches(/^[1-9]{1}[0-9]{4}$/, "it's not correct format"),

      refundRules: Yup.string().required("Please Enter merchant refund rules"),
      refundPeriod: Yup.string().required(
        "Please Enter merchant refund period"
      ),
      taxRegistrationNumber: Yup.string().required(
        "Please Enter tax registration number"
      ),
      refundPeriod: Yup.string()
        .required("Please Enter valid Period")
        .matches(
          /^[100]{0}%$|^[1-9]{1,2}$/,
          "Please Enter valid Number min 1 and max 15,format Example: 4 or 14"
        ),
      refundRules: Yup.string()
        .required("Please Enter valid refund rules.")
        .matches(
          /^\s*([^\s]\s*){0,2500}$/,
          "Please Enter valid refund ruls min 1, and max 2500 letters."
        ),
      commissionPercentage: Yup.string()
        .required("Please Enter valid perecentage")
        .matches(
          /^[100]{0}%$|^[1-9]{1,2}[.][0-9]{0,2}$/,
          "Please Enter valid perecentage,format Example: 4.5 or 25.0"
        ),
    }),
    onSubmit: (values) => {
      const body = {
        token: localStorage.getItem("authUser"),
        merchantId: merchant.id,
        pointPrice: values["pointPrice"],
        taxRegistrationNumber: values["taxRegistrationNumber"],
        commissionPercentage: `${values["commissionPercentage"]}%`,
        hotLine: values["hotLine"],
        refundRules: values["refundRules"],
        refundPeriod: values["refundPeriod"],
        categoryId: selectedCategory,
        currency: "QAR",
      };
      axios
        .post(`${API_URL}admin/merchant/create/merchantProfile`, body, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then(() => {
          if (value === "pending") {
            fetchSingleMerchant();
          } else {
            fetchPendingMerchants();
          }
        })
        .catch((err) => {
          console.log(err);
        });

      setShowModal(false);
    },
  });

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption.value);
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">Create Profile</ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Point Price</Label>
                <Input
                  name="pointPrice"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.pointPrice || ""}
                  invalid={
                    validation.touched.pointPrice &&
                    validation.errors.pointPrice
                      ? true
                      : false
                  }
                />
                {validation.touched.pointPrice &&
                validation.errors.pointPrice ? (
                  <FormFeedback type="invalid">
                    {validation.errors.pointPrice}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Tax registration number</Label>
                <Input
                  name="taxRegistrationNumber"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.taxRegistrationNumber || ""}
                  invalid={
                    validation.touched.taxRegistrationNumber &&
                    validation.errors.taxRegistrationNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.taxRegistrationNumber &&
                validation.errors.taxRegistrationNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.taxRegistrationNumber}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Commission percentage</Label>
                <Input
                  name="commissionPercentage"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.commissionPercentage || ""}
                  invalid={
                    validation.touched.commissionPercentage &&
                    validation.errors.commissionPercentage
                      ? true
                      : false
                  }
                />
                {validation.touched.commissionPercentage &&
                validation.errors.commissionPercentage ? (
                  <FormFeedback type="invalid">
                    {validation.errors.commissionPercentage}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">HotLine</Label>
                <Input
                  name="hotLine"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.hotLine || ""}
                  invalid={
                    validation.touched.hotLine && validation.errors.hotLine
                      ? true
                      : false
                  }
                />
                {validation.touched.hotLine && validation.errors.hotLine ? (
                  <FormFeedback type="invalid">
                    {validation.errors.hotLine}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Refund Period</Label>
                <Input
                  name="refundPeriod"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.refundPeriod || ""}
                  invalid={
                    validation.touched.refundPeriod &&
                    validation.errors.refundPeriod
                      ? true
                      : false
                  }
                />
                {validation.touched.refundPeriod &&
                validation.errors.refundPeriod ? (
                  <FormFeedback type="invalid">
                    {validation.errors.refundPeriod}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Refund Rules</Label>
                <textarea
                  className="d-block w-100"
                  rows={5}
                  name="refundRules"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.refundRules || ""}
                  invalid={
                    validation.touched.refundRules &&
                    validation.errors.refundRules
                      ? true
                      : false
                  }
                />
                {validation.touched.refundRules &&
                validation.errors.refundRules ? (
                  <FormFeedback type="invalid">
                    {validation.errors.refundRules}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Refund Period</Label>
                <Input
                  name="refundPeriod"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.refundPeriod || ""}
                  invalid={
                    validation.touched.refundPeriod &&
                    validation.errors.refundPeriod
                      ? true
                      : false
                  }
                />
                {validation.touched.refundPeriod &&
                validation.errors.refundPeriod ? (
                  <FormFeedback type="invalid">
                    {validation.errors.refundPeriod}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Refund Rules</Label>
                <Input
                  name="refundRules"
                  type="textarea"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.refundRules || ""}
                  invalid={
                    validation.touched.refundRules &&
                    validation.errors.refundRules
                      ? true
                      : false
                  }
                />
                {validation.touched.refundRules &&
                validation.errors.refundRules ? (
                  <FormFeedback type="invalid">
                    {validation.errors.refundRules}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Category</Label>
                <Select
                  options={categories}
                  onChange={handleCategoryChange}
                  placeholder="Select"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

MerchantCreateProfile.propTypes = {
  onCloseClick: PropTypes.func,
  merchant: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchPendingMerchants: PropTypes.func,
  fetchSingleMerchant: PropTypes.func,
  value: PropTypes.string,
  categories: PropTypes.any,
};

export default MerchantCreateProfile;
