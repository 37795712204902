import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import moment from "moment";
import axios from "axios";
import Branches from "pages/Branches";
import Offers from "pages/Offers";
import Employees from "pages/Employees";
import CardContent from "../../components/Card";
import EmptyTable from "../../components/Common/EmptyTable";
import features from "../../assets/images/crypto/features-img/img-1.png";
import StackedColumnChartForMerchantDetails from "./StackedColumnChartForMerchantDetails";
import MerchantEditModal from "./MerchantEditModal";
import AddEditBankAccountModal from "./AddEditBankAccountModal";
import MerchantProducts from "./MerchantProducts";

const API_URL = process.env.REACT_APP_API_URL;
const IMAGEURL = process.env.REACT_APP_IMAGEURL;

const MerchantDetails = () => {
  const [singleMerchantData, setSingleMerchantData] = useState({});
  const [merchantBankAccountData, setMerchantBankAccountData] = useState({});

  const [offersCount, setOffersCount] = useState([]);
  const [employeesCount, setEmployeesCount] = useState([]);
  const [branchesCount, setBranchesCount] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [numberOfRequests, setNumberOfRequests] = useState([]);
  const [bankAccountModal, setBankAccountModal] = useState(false);
  const [bankAccountModalValue, setBankAccountModalValue] = useState("");

  const params = useParams();
  const id = params?.id;

  useEffect(() => {
    fetchSingleMerchant();
    fetchRequestOrdersForBranches();
    fetchCategories();
    fetchBankAccount();
  }, []);

  const reports = [
    {
      title: "Employees",
      iconClass: "bx-copy-alt",
      description: employeesCount || 0,
    },
    {
      title: "Branches",
      iconClass: "bx-archive-in",
      description: branchesCount || 0,
    },
    {
      title: "Offers",
      iconClass: "bx-archive-in",
      description: offersCount || 0,
    },
  ];

  const fetchSingleMerchant = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      merchantId: id,
    };

    axios
      .post(`${API_URL}admin/merchant/details`, body)
      .then((response) => {
        setSingleMerchantData(response?.data.data.merchantDetails);
        setOffersCount(response?.data.data.OffersCount);
        setEmployeesCount(response?.data.data.EmployeesCount);
        setBranchesCount(response?.data.data.BranchesCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchRequestOrdersForBranches = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      merchantId: id,
    };
    axios
      .post(`${API_URL}admin/chart/orders/branches`, body)
      .then((response) => {
        const data = response?.data.data;
        const newData = data
          .sort(function (a, b) {
            return parseFloat(b.countRequests) - parseFloat(a.countRequests);
          })
          .slice(0, 5)
          .map((item) => ({
            id: item.id,
            branchName: item.branchName,
            countRequests: item.countRequests,
          }));
        setNumberOfRequests(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchBankAccount = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      merchantId: id,
    };

    axios
      .post(`${API_URL}admin/merchant/bankAccountDetails`, body)
      .then((response) => {
        setMerchantBankAccountData(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCategories = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      limit: 1000,
    };

    axios
      .post(`${API_URL}admin/catigory/list`, body)
      .then((response) => {
        const categoriesBeforeFilter = response?.data.data.rows;
        const filteredCategoriesToValueAndLabel = categoriesBeforeFilter?.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setCategories(filteredCategoriesToValueAndLabel);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveRequest = () => {
    const body = {
      token: localStorage.getItem("authUser"),
      profileId: singleMerchantData?.MerchantProfile.id,
    };
    axios
      .post(`${API_URL}admin/merchant/approve/merchantProfile`, body)
      .then((response) => {
        // should return data
        // setSingleMerchantData(response?.data.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleEditMerchant = () => {
    setModal(true);
    setModalValue("edit");
  };

  const handleAddBankAccount = () => {
    setBankAccountModal(true);
    setBankAccountModalValue("new");
  };

  const handleUpdateBankAccount = () => {
    setBankAccountModal(true);
    setBankAccountModalValue("edit");
  };
  const handleBlockMerchant = () => {
    axios
      .post(`${API_URL}admin/merchant/block`, {
        token: localStorage.getItem("authUser"),
        merchantId: id,
        block: "true",
      })
      .then(() => {
        fetchSingleMerchant();
      });
  };
  const handleUnBlockMerchant = () => {
    axios
      .post(`${API_URL}admin/merchant/block`, {
        token: localStorage.getItem("authUser"),
        merchantId: id,
        block: "false",
      })
      .then(() => {
        fetchSingleMerchant();
      });
  };

  const handleUploadLogo = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("logo", e.target.files[0]);
    formData.append("token", localStorage.getItem("authUser"));
    formData.append("merchantId", singleMerchantData.id);

    axios
      .post(`${API_URL}admin/merchant/upload/logo`, formData)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUploadCover = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("cover", e.target.files[0]);
    formData.append("token", localStorage.getItem("authUser"));
    formData.append("merchantId", singleMerchantData.id);

    axios
      .post(`${API_URL}admin/merchant/upload/cover`, formData)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <MerchantEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        merchant={singleMerchantData}
        fetchSingleMerchant={fetchSingleMerchant}
        categories={categories}
      />

      <AddEditBankAccountModal
        bankAccountModal={bankAccountModal}
        setBankAccountModal={setBankAccountModal}
        bankAccountModalValue={bankAccountModalValue}
        onCloseClick={() => setBankAccountModal(false)}
        bankAccount={
          bankAccountModalValue === "edit"
            ? merchantBankAccountData
            : singleMerchantData?.id
        }
        fetchBankAccount={fetchBankAccount}
      />
      <div className="page-content">
        <MetaTags>
          <title>Merchant Details | Swaplater</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Merchant" breadcrumbItem="Merchant Details" />
          <Row>
            <h4 className="text-danger text-center">
              {!singleMerchantData && "There is No Data For This Merchant Yet"}
            </h4>
            <Col lg="4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Top branches to sell offers
                  </CardTitle>
                  <div className="text-center">
                    <StackedColumnChartForMerchantDetails
                      ordersPerBranch={numberOfRequests}
                    />
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row
                    className="task-dates"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Col sm="6" xs="6">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-comment-minus me-1 text-primary" />
                          Is Approved :{" "}
                          {singleMerchantData?.isAproved == false
                            ? "false"
                            : "true"}
                        </h5>
                      </div>
                    </Col>

                    <Col sm="6" xs="6">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-comment-minus me-1 text-primary" />{" "}
                          Is Life :{" "}
                          {singleMerchantData?.isLive == false
                            ? "false"
                            : "true"}
                        </h5>
                      </div>
                    </Col>
                    <Col sm="6" xs="6">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-comment-minus me-1 text-primary" />{" "}
                          Is Blocked :{" "}
                          {singleMerchantData?.isBlocked == false
                            ? "false"
                            : "true"}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Row
                    className="task-dates"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Col sm="12">
                      <h5 className="font-size-14 mt-1">
                        {singleMerchantData?.isAproved === true
                          ? `Merchant Approved `
                          : `Approve Merchant : `}
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={singleMerchantData?.isAproved}
                          id="flexCheckDefault"
                          defaultChecked={singleMerchantData?.isAproved}
                          onClick={approveRequest}
                          disabled={
                            singleMerchantData?.isAproved === true
                              ? true
                              : false
                          }
                        />
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Upload/Change Cover Photo
                    </CardTitle>
                    <div className="mb-3">
                      <div className="mb-1">
                        <input
                          type="file"
                          onChange={handleUploadCover}
                          id="cover"
                          style={{
                            border: "none",
                            boxShadow:
                              " inset 0px 1px 0px rgba(122, 122, 122, 0.15)",
                            marginLeft: `10px`,
                          }}
                          placeholder="upload cover"
                          name="cover"
                          className="w-full bg-white rounded-3xl transition-colors duration-200 ease-in-out"
                        />
                        {/* </Button> */}
                      </div>
                      <img
                        src={
                          singleMerchantData?.cover !== null
                            ? `${IMAGEURL}${singleMerchantData?.cover}`
                            : features
                        }
                        alt=""
                        style={{ height: `200px`, objectFit: `contain` }}
                        className="img-fluid d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Col>

            <Col lg="8">
              <CardContent reports={reports} numbers={4} />

              <Row>
                <Col>
                  <div className="text-sm-end mb-2">
                    <span
                      className="text-primary"
                      style={{ fontWeight: `bold`, fontSize: `15px` }}
                    >
                      upload logo {">"}
                    </span>
                    <input
                      type="file"
                      onChange={handleUploadLogo}
                      id="image"
                      style={{
                        border: "none",
                        boxShadow:
                          " inset 0px 1px 0px rgba(122, 122, 122, 0.15)",
                        marginLeft: `10px`,
                      }}
                      placeholder="upload logo"
                      name="image"
                      className="w-full bg-white rounded-3xl transition-colors duration-200 ease-in-out"
                    />
                    {/* </Button> */}
                  </div>
                </Col>
                <Col>
                  <div className="text-sm-end mb-2">
                    <Button
                      color="primary"
                      className="font-16 btn-block btn btn-primary"
                      onClick={handleEditMerchant}
                      disabled={singleMerchantData?.isBlocked ? true : false}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      update Merchant
                    </Button>
                  </div>
                </Col>
              </Row>
              <Card>
                <div>
                  <Row>
                    <Col lg="9" sm="8">
                      <div className="p-4">
                        <div style={{ textAlign: "right" }}>
                          {singleMerchantData?.isBlocked ? (
                            <Button
                              color="warning"
                              className="font-16 btn-block btn btn-primary"
                              onClick={handleUnBlockMerchant}
                              //  disabled= {singleMerchantData.isBlocked? true : false} // waiting for unblock API to change this
                            >
                              UnBlock
                            </Button>
                          ) : (
                            <Button
                              color="danger"
                              className="font-16 btn-block btn btn-primary"
                              onClick={handleBlockMerchant}
                              // disabled= {singleMerchantData.isBlocked? true : false} // waiting for unblock API to change this
                            >
                              Block
                            </Button>
                          )}
                        </div>

                        <h3 className="text-primary">
                          {" "}
                          {singleMerchantData?.name}{" "}
                          {singleMerchantData?.isBlocked ? (
                            <span>&#128683;</span>
                          ) : (
                            ""
                          )}
                        </h3>

                        <div className="text-muted">
                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            mobile: {singleMerchantData?.mobile}
                          </p>
                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            currency: {singleMerchantData?.userCurrncy}
                          </p>
                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            Hotline:
                            {singleMerchantData?.MerchantProfile?.hotLine}
                          </p>
                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            Points balance: {singleMerchantData?.balance} Point
                          </p>
                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            Balance Get: {singleMerchantData?.balanceGet}
                          </p>
                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            Tax Registration Number :{" "}
                            {
                              singleMerchantData?.MerchantProfile
                                ?.taxRegistrationNumber
                            }
                          </p>
                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            Commission Percentage:{" "}
                            {
                              singleMerchantData?.MerchantProfile
                                ?.commissionPercentage
                            }
                            %
                          </p>

                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            refund Period:{" "}
                            {singleMerchantData?.MerchantProfile?.refundPeriod}
                          </p>

                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            Commission Rules:{" "}
                            {singleMerchantData?.MerchantProfile?.refundRules}
                          </p>

                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            Point Price:{" "}
                            {singleMerchantData?.MerchantProfile?.pointPrice}
                          </p>
                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            Created At:{" "}
                            {handleValidDate(
                              singleMerchantData?.MerchantProfile?.createdAt
                            )}
                          </p>
                          <p className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                            Updated At:{" "}
                            {handleValidDate(
                              singleMerchantData?.MerchantProfile?.updatedAt
                            )}
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col lg="3" sm="4" className="align-self-center">
                      <div>
                        <img
                          src={
                            singleMerchantData?.MerchantProfile?.logo !== null
                              ? `${IMAGEURL}${singleMerchantData?.MerchantProfile?.logo}`
                              : features
                          }
                          alt=""
                          className=" d-block"
                          style={{ height: `100px` }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>

              {Object.keys(merchantBankAccountData).length !== 0 ? (
                <>
                  <Col>
                    <div className="text-sm-end mb-2">
                      <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={handleUpdateBankAccount}
                        disabled={singleMerchantData?.isBlocked ? true : false}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Update Bank Account
                      </Button>
                    </div>
                  </Col>
                  <Card>
                    <div>
                      <Row>
                        <Col>
                          <div className="p-4">
                            <h5 className="text-primary">
                              {" "}
                              Bank Account Information
                            </h5>

                            <div className="text-muted">
                              <p className="mb-1">
                                <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                Bank Name:{" "}
                                {merchantBankAccountData?.bankName ||
                                  `Not available`}
                              </p>
                              <p className="mb-1">
                                <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                Account Number:{" "}
                                {merchantBankAccountData?.accountNumber ||
                                  `Not available`}
                              </p>
                              <p className="mb-1">
                                <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                Address:{" "}
                                {merchantBankAccountData?.address ||
                                  `Not available`}
                              </p>

                              {merchantBankAccountData?.addressAr ? (
                                <p className="mb-1">
                                  <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                  Arabic Address:{" "}
                                  {merchantBankAccountData?.addressAr}
                                </p>
                              ) : (
                                ``
                              )}

                              {merchantBankAccountData?.IBAN ? (
                                <p className="mb-1">
                                  <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                  IBAN: {merchantBankAccountData?.IBAN}
                                </p>
                              ) : (
                                ``
                              )}

                              <p className="mb-1">
                                <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                Created At:{" "}
                                {handleValidDate(
                                  merchantBankAccountData?.createdAt
                                )}
                              </p>
                              <p className="mb-1">
                                <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                Updated At:{" "}
                                {handleValidDate(
                                  merchantBankAccountData?.updatedAt
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </>
              ) : (
                <>
                  <Col>
                    <div className="text-sm-end mb-2">
                      <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={handleAddBankAccount}
                        disabled={singleMerchantData?.isBlocked ? true : false}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Add Bank Account to {singleMerchantData?.name}
                      </Button>
                    </div>
                  </Col>
                  <Card>
                    <div className="p-4">
                      <h5 className="text-primary">
                        {" "}
                        Bank Account Information
                      </h5>
                    </div>
                  </Card>
                </>
              )}
            </Col>
          </Row>
          <h3>Branches</h3>
          {singleMerchantData?.id ? (
            <Branches
              merchant={singleMerchantData?.id}
              merchantBlocked={singleMerchantData?.isBlocked}
            />
          ) : (
            <Card>
              <CardBody>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
          <h3>Offers</h3>
          {singleMerchantData?.id ? (
            <Offers
              merchant={singleMerchantData?.id}
              merchantBlocked={singleMerchantData?.isBlocked}
            />
          ) : (
            <Card>
              <CardBody>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
          <h3>Products</h3>
          {singleMerchantData?.id ? (
            <MerchantProducts
              merchant={singleMerchantData?.id}
              merchantBlocked={singleMerchantData?.isBlocked}
            />
          ) : (
            <Card>
              <CardBody>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
          <h3>Employees</h3>
          {singleMerchantData?.id ? (
            <Employees
              merchant={singleMerchantData?.id}
              merchantBlocked={singleMerchantData?.isBlocked}
            />
          ) : (
            <Card>
              <CardBody>
                <EmptyTable />
              </CardBody>
            </Card>
          )}

          {/*   <h5 className="">Merchant Transactions</h5>{" "}
          {singleMerchantData?.transactions ? (
            // <Grid
            //   data={singleMerchantData?.transactions}
            //   columns={columns}
            //   totalRows={totalRows}
            //   limit={limit}
            //   handlePageChange={handlePageChange}
            //   handlePerRowsChange={handlePerRowsChange}
            // />
            ``
          ) : (
            <Card>
              <CardBody>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
          <h5>Expired Packages</h5>{" "}
          {singleMerchantData?.expiredPackages ? (
            // <Grid
            //   data={singleMerchantData?.expiredPackages}
            //   columns={columns}
            //   totalRows={totalRows}
            //   limit={limit}
            //   handlePageChange={handlePageChange}
            //   handlePerRowsChange={handlePerRowsChange}
            // />
            ``
          ) : (
            <Card>
              <CardBody>
                <EmptyTable />
              </CardBody>
            </Card>
          )} */}
        </Container>
      </div>
    </React.Fragment>
  );
};
MerchantDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(MerchantDetails);
