import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import { Container, Card, CardBody, CardTitle, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";

const OrdersDetails = () => {
  const [product, setProduct] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const params = useParams();
  const fileInputRef = useRef();

  const id = params.id;

  useEffect(() => {
    fetchSingleProduct();
  }, []);

  const fetchSingleProduct = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
      id: id,
    };

    axios
      .post(`${API_URL}admin/product/req/details`, body)
      .then((response) => {
        setProduct(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleUploadImage = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("image", e.target.files[0]);
    formData.append("token", localStorage.getItem("authUser"));
    formData.append("productId", product?.id);

    axios
      .post(`${API_URL}admin/product/upload/image`, formData)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Details| Swaplater</title>{" "}
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Order" breadcrumbItem="Order Details" />

          <Card>
            <CardBody>
              {/* <CardTitle>{product?.merchant?.name}</CardTitle> */}
              <div className="table-responsive">
                <Table>
                  <tbody>
                    <tr>
                      <th scope="row">Branch Name :</th>
                      <td>{product?.Branch?.branchName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Branch Mobile :</th>
                      <td>{product?.Branch?.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">Product Name :</th>
                      <td>{product?.Product?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Product Price :</th>
                      <td>{product?.Product?.price}</td>
                    </tr>
                    <tr>
                      <th scope="row">Product Description :</th>
                      <td>{product?.Product?.description}</td>
                    </tr>
                    <tr>
                      <th scope="row">User Name :</th>
                      <td>{product?.User?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">User Mobile :</th>
                      <td>{product?.User?.mobile}</td>
                    </tr>
                    <tr>
                      <th scope="row">AmountPoints  :</th>
                      <td>{product?.amountPoints}</td>
                    </tr>
                    <tr>
                      <th scope="row">AmountPrice :</th>
                      <td>{product?.amountPrice}</td>
                    </tr><tr>
                      <th scope="row">CreatedAt :</th>
                      <td>{product?.createdAt}</td>
                    </tr><tr>
                      <th scope="row">Status :</th>
                      <td>{product?.status}</td>
                    </tr><tr>
                      <th scope="row">Type :</th>
                      <td>{product?.type}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
OrdersDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(OrdersDetails);
