import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");
const MerchantNewModal = ({
  show,
  setShowModal,
  onCloseClick,
  fetchMerchants,
}) => {
  const [phone, setPhone] = useState();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {},
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter merchant name"),
      // mobile: Yup.string().required("Please Enter merchant mobile"),
    }),
    onSubmit: (values) => {
      const body = {
        token: localStorage.getItem("authUser"),
        name: values["name"],
        mobile: phone,
        userCurrncy: "QAR",
      };
      console.log(`new`, body);
      axios
        .post(`${API_URL}admin/merchant/create`, body, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then(() => {
          fetchMerchants(1, 10);
        })
        .catch((err) => {
          console.log(err);
        });

      setShowModal(false);
    },
  });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader tag="h4">
        {/* {modalValue === "edit" ? "Edit merchant" : "Add merchant"} */}
        Add Merchant
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Merchant Name</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <PhoneInput
                  name="mobile"
                  type="text"
                  country="qa"
                  onChange={(e) => {
                    setPhone(e);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={onCloseClick}
                  className="btn btn-danger save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

MerchantNewModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchMerchants: PropTypes.func,
};

export default MerchantNewModal;
