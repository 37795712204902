import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Container, Card, Table, CardBody, CardTitle } from "reactstrap";
import axios from "axios";
import moment from "moment";

const UserProfile = () => {
  const [admin, setAdmin] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchSingleAdmin();
  }, []);

  const fetchSingleAdmin = async () => {
    const body = {
      token: localStorage.getItem("authUser"),
    };

    axios
      .post(`${API_URL}admin/details`, body)
      .then((response) => {
        setAdmin(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Admin Profile | Swaplater</title>{" "}
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Admin" breadcrumbItem="Profile" />

          <Card>
            <CardBody>
              <CardTitle className="mb-4">{admin?.name} Details</CardTitle>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Name :</th>
                      <td>{admin?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Mobile :</th>
                      <td>{admin?.mobile}</td>
                    </tr>
                    <tr>
                      <th scope="row">Role :</th>
                      <td>{admin?.rule}</td>
                    </tr>
                    <tr>
                      <th scope="row">Is Approved :</th>
                      <td>{admin?.isAproved == false ? "false" : "true"}</td>
                    </tr>
                    <tr>
                      <th scope="row">Is Live :</th>
                      <td>{admin?.isLive == false ? "false" : "true"}</td>
                    </tr>

                    <tr>
                      <th scope="row">Created At :</th>
                      <td>{handleValidDate(admin?.createdAt)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
UserProfile.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(UserProfile);
