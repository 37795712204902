import PropTypes from "prop-types";
import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";

const CloseTicketModal = ({
  show,
  onSavedClick,
  onCloseClick,
  itemToBeSaved,
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "red" }}
              />
              <h2>Confirm Closing Ticket?</h2>
              {/* <h4>{"You won't be able to revert this!"}</h4> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={() => onSavedClick(itemToBeSaved)}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

CloseTicketModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSavedClick: PropTypes.func,
  show: PropTypes.any,
  itemToBeSaved: PropTypes.any,
};

export default CloseTicketModal;
